/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExclusionList } from './map-layer-handler-exclusions';

export type ManagedLayer = {
  id: string;
  visible: boolean;
  displayName: string;
  opacity?: boolean; // Whether or not the layer's opacity is editable
};

export interface MapLayerRowData {
  layerName: string;
  displayName: string;
}

interface InitialState {
  layers: ManagedLayer[];
  managedLayer: string[];
  data: MapLayerRowData[];
  layerHandlerOpen: boolean;
}

export const INITIAL_MAP_LAYER_MANAGER_STATE: InitialState = {
  layers: [],
  managedLayer: [],
  data: [],
  layerHandlerOpen: false,
};

const mapLayerHandlerSlice = createSlice({
  name: 'mapLayerHandler',
  initialState: INITIAL_MAP_LAYER_MANAGER_STATE,
  reducers: {
    setLayers(state, action) {
      state.layers = action.payload;
    },
    // setManagedLayer: (state, action: PayloadAction<string[]>) => {
    //   state.managedLayer = [...state.managedLayer, ...action.payload];
    // },
    updateManagedLayerOrder: (state, action: PayloadAction<string[]>) => {
      state.managedLayer = action.payload;
    },
    setData: (state, action: PayloadAction<MapLayerRowData[]>) => {
      const newLayers = action.payload.filter(
        (layer) => !state.data.some((l) => l.layerName === layer.layerName)
      );

      const existingLayers = state.data.filter(
        (layer) =>
          action.payload.some((l) => l.layerName === layer.layerName) ||
          ExclusionList.includes(layer.layerName)
      );

      state.data = [...newLayers, ...existingLayers];
    },
    setDataOrder: (state, action: PayloadAction<MapLayerRowData[]>) => {
      state.data = action.payload;
    },
    updateDisplayName: (state, action: PayloadAction<MapLayerRowData>) => {
      state.data = state.data.map((item) =>
        item.layerName === action.payload.layerName
          ? { ...item, displayName: action.payload.displayName }
          : item
      );
    },
    setLayerHandlerOpen: (state, action: PayloadAction<boolean>) => {
      state.layerHandlerOpen = action.payload;
    },
  },
});

export const {
  setLayers,
  updateManagedLayerOrder,
  setData,
  setDataOrder,
  updateDisplayName,
  setLayerHandlerOpen,
} = mapLayerHandlerSlice.actions;

export default mapLayerHandlerSlice.reducer;
