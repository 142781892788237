/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CityFilters {
  open: boolean;
  search: string;
}

interface CitiesPanel {
  filters: CityFilters;
}

export const INITIAL_CITIES_PANEL_STATE: CitiesPanel = {
  filters: {
    open: false,
    search: '',
  },
};

const CitiesPanelSlice = createSlice({
  name: 'citiesPanel',
  initialState: INITIAL_CITIES_PANEL_STATE,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<CityFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const { setFilters } = CitiesPanelSlice.actions;

export default CitiesPanelSlice.reducer;
