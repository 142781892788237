import buffer from '@turf/buffer';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';
import { AlertingRoute, Route } from '../../../models/routes/routes.model';
import store from '../../../store';
import {
  ColourScheme,
  Tenant,
  colourSchemes,
  getFromTheme,
} from '../../../theme';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { convertKmToNauticalMiles } from '../../../utils/measurement-helpers';
import MapHelpers from '../../map.utils';
import addLineLayer from '../layer-utils/add-line-layer';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerPaint from '../map-layer-paint';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';

export const updateRadiusAura = (
  geoJson: GeoJSON.Feature<GeoJSON.LineString>
) => {
  const featureSourceName = `${geoJson.id}-radius-aura-source`;

  const bufferedGeoJson = buffer(
    geoJson,
    convertKmToNauticalMiles(geoJson.properties?.radius) || 25,
    {
      units: 'nauticalmiles',
    }
  );

  GeoHelper.setMapboxGeoJSONSourceData(featureSourceName, bufferedGeoJson);
};

const addRouteLayer = (
  layer: string,
  route: Route,
  layerGroupOverride?: MapGroupLayer
) => {
  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    const data = route.route_data;
    data.properties = {
      ...data.properties,
      route_id: route.route_id,
      route_name: route.route_name,
      radius: route.radius,
    };
    data.geometry.coordinates = data.geometry.coordinates.map((coordPair) =>
      coordPair.map((coord) => Number(coord))
    );

    if (!MapHelpers.getSource(layer)) {
      addSimpleGeoJsonSource(layer);
      addLineLayer(
        layer,
        MapLayerPaint.DRAWING_LINE_DEFAULT_PAINT,
        undefined,
        layerGroupOverride ?? MapGroupLayer.ROUTES
      );
    }

    const radiusBuffer = buffer(
      data,
      convertKmToNauticalMiles(data.properties.radius),
      {
        units: 'nauticalmiles',
      }
    );

    const radiusLayer = `${layer}_radius`;

    GeoHelper.setMapboxGeoJSONSourceData(radiusLayer, radiusBuffer);

    if (!MapHelpers.getLayer(radiusLayer)) {
      MapLayerManager.AddLayerinGroups(
        {
          id: radiusLayer,
          type: 'fill',
          source: radiusLayer,
          paint: {
            'fill-color': 'rgba(0, 0, 255, 0)',
            'fill-outline-color': 'blue',
          },
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
        },
        layerGroupOverride
          ? [MapGroupLayer.ROUTES, layerGroupOverride]
          : [MapGroupLayer.ROUTES]
      );
    }

    const labelLayer = `${layer}_labels`;

    GeoHelper.setMapboxGeoJSONSourceData(labelLayer, {
      type: 'FeatureCollection',
      features: data.geometry.coordinates.map((coordinate, index) => ({
        type: 'Feature',
        properties: {
          indexPoint: index + 1,
        },
        geometry: {
          type: 'Point',
          coordinates: coordinate,
        },
      })),
    });

    if (!MapHelpers.getLayer(labelLayer)) {
      MapLayerManager.AddLayerinGroups(
        {
          id: labelLayer,
          type: 'symbol',
          source: labelLayer,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
            'text-field': ['concat', ['to-string', ['get', 'indexPoint']]],
            'text-size': 12,
            'text-offset': [0, 2],
          },
          paint: {
            'text-color': '#FFFFFF',
          },
        },
        layerGroupOverride
          ? [MapGroupLayer.ROUTES, layerGroupOverride]
          : [MapGroupLayer.ROUTES]
      );
    }

    GeoHelper.setMapboxGeoJSONSourceData(layer, data);

    MapHelpers.moveLayerIfExists(layer, MapLayer.CURRENTLY_ALERTING_VESSELS);

    resolve(MapHelpers);
  });
};

export async function addAlertingRouteLayer(routes: AlertingRoute[]) {
  const layer = MapLayer.CURRENTLY_ALERTING_ROUTES;
  const radiusLayer = `${layer}_radius`;
  const radiusBufferFeatureCollection: FeatureCollection<
    Geometry,
    GeoJsonProperties
  > = {
    type: 'FeatureCollection',
    features: routes.map((route) => {
      const radiusBuffer = buffer(
        route.route_data,
        convertKmToNauticalMiles(route.radius),
        {
          units: 'nauticalmiles',
        }
      );

      return radiusBuffer;
    }),
  };

  if (!MapHelpers.getSource(radiusLayer)) {
    MapHelpers.addSource(radiusLayer, {
      type: 'geojson',
      data: radiusBufferFeatureCollection,
    });
  } else {
    (MapHelpers.getSource(radiusLayer) as GeoJSONSource).setData(
      radiusBufferFeatureCollection
    );
  }

  if (!MapHelpers.getLayer(radiusLayer)) {
    MapLayerManager.AddLayerinGroups(
      {
        id: radiusLayer,
        type: 'fill',
        source: radiusLayer,
        paint: MapLayerPaint.ALERTING_REGION_PAINT,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
        },
      },
      [MapGroupLayer.ALERTS]
    );
  }

  const centerLineFeatureCollection: FeatureCollection<
    Geometry,
    GeoJsonProperties
  > = {
    type: 'FeatureCollection',
    features: routes.map((route) => {
      const data = route.route_data;
      return data;
    }),
  };

  if (!MapHelpers.getSource(layer)) {
    addSimpleGeoJsonSource(layer);
    addLineLayer(
      layer,
      MapLayerPaint.DRAWING_LINE_DEFAULT_PAINT,
      undefined,
      MapGroupLayer.ALERTS
    );
  }
  (MapHelpers.getSource(layer) as GeoJSONSource)?.setData(
    centerLineFeatureCollection
  );
  // set layer as visible
  MapHelpers.setLayerVisibility(layer, true);
}

export const addDrawingPointLabels = (
  geoJson: GeoJSON.Feature<GeoJSON.LineString> | null
) => {
  if (!geoJson) {
    return;
  }
  const drawingLabelLayer = `${geoJson.properties?.route_id}_drawing_labels`;

  GeoHelper.setMapboxGeoJSONSourceData(drawingLabelLayer, {
    type: 'FeatureCollection',
    features: geoJson.geometry.coordinates.map((coordinate, index) => ({
      type: 'Feature',
      properties: {
        indexPoint: index + 1,
      },
      geometry: {
        type: 'Point',
        coordinates: coordinate,
      },
    })),
  });

  if (!MapHelpers.getLayer(drawingLabelLayer)) {
    MapLayerManager.AddLayerinGroups(
      {
        id: drawingLabelLayer,
        type: 'symbol',
        source: drawingLabelLayer,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
          'text-field': ['concat', ['to-string', ['get', 'indexPoint']]],
          'text-size': 12,
          'text-offset': [0, 2],
        },
        paint: {
          'text-color': '#FFFFFF',
        },
      },
      [MapGroupLayer.ROUTES]
    );
  }
};

export const removeDrawingPointLabels = (
  geoJson: GeoJSON.Feature<GeoJSON.LineString> | null
) => {
  if (!geoJson) {
    return;
  }
  const featureSourceName = `${geoJson.properties?.route_id}_drawing_labels`;
  if (MapHelpers.getLayer(featureSourceName)) {
    MapHelpers.removeLayer(featureSourceName);
  }
  if (MapHelpers.getSource(featureSourceName)) {
    MapHelpers.removeSource(featureSourceName);
  }
};

export const addRadiusAura = (
  geoJson: GeoJSON.Feature<GeoJSON.LineString> | null
) => {
  if (!geoJson) {
    return;
  }
  const featureSourceName = `${geoJson.id}-radius-aura-source`;
  if (!MapHelpers.getSource(featureSourceName)) {
    MapHelpers.addSource(featureSourceName, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
        properties: {},
      },
    });
  }

  if (!MapHelpers.getLayer(featureSourceName)) {
    const { tenantId } = store.getState().user.idToken!;
    const colourScheme: ColourScheme | undefined = colourSchemes.get(
      getFromTheme(tenantId as Tenant, 'colourScheme')
    );
    MapLayerManager.AddLayerinGroups(
      {
        id: featureSourceName,
        type: 'fill',
        source: featureSourceName,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-color': colourScheme?.areaTint,
          'fill-opacity': 0.2,
        },
      },
      [MapGroupLayer.ROUTES]
    );
    // We want draw vertex handles to render above the aura.
    // map.moveLayer with no second arg moves the layer to the top.
    // since aura is just added now, it will be on top of the handles.
    MapHelpers.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold');
    MapHelpers.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot');
  }

  updateRadiusAura(geoJson);
};

export const removeRadiusAura = (
  geoJson: GeoJSON.Feature<GeoJSON.LineString> | null
) => {
  if (!geoJson) {
    return;
  }
  const featureSourceName = `${geoJson.id}-radius-aura-source`;
  if (MapHelpers.getLayer(featureSourceName)) {
    MapHelpers.removeLayer(featureSourceName);
  }
  if (MapHelpers.getSource(featureSourceName)) {
    MapHelpers.removeSource(featureSourceName);
  }
};

export default addRouteLayer;
