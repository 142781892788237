import { useAppDispatch } from '../hooks';
import { IncidentsLayers } from '../map/map-layer-manager/map-layer.enum';
import { defaultIncidentFilters } from '../maritime-menu-options/incidents-panel/incident-panel.slice';
import DateTimeHelpers from './date-time-helpers.utils';
import { loadIncidentsSetup } from './user.utils';

const loadLiteMode = (dispatch: ReturnType<typeof useAppDispatch>) => {
  const today = new Date();
  const oneMonthAgo = DateTimeHelpers.subtractMonths(today, 1);

  return loadIncidentsSetup(
    dispatch,
    {
      ...defaultIncidentFilters,
      startDate: DateTimeHelpers.dateToIsoDate(oneMonthAgo),
      endDate: DateTimeHelpers.dateToIsoDate(today),
    },
    true,
    IncidentsLayers.INCIDENTS
  );
};

export default loadLiteMode;
