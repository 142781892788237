enum VesselTypes {
  DRY_BULK = 'DRY_BULK',
  GENERAL_CARGO = 'GENERAL_CARGO',
  CONTAINER = 'CONTAINER',
  REEFER = 'REEFER',
  ROLL_ON_ROLL_OFF = 'ROLL_ON_ROLL_OFF',
  OFFSHORE = 'OFFSHORE',
  HIGH_SPEED_CRAFT = 'HIGH_SPEED_CRAFT',
  CAR_CARRIER = 'CAR_CARRIER',
  VEHICLE_PASSENGER = 'VEHICLE_PASSENGER',
  PLEASURE_CRAFT = 'PLEASURE_CRAFT',
  FISHING = 'FISHING',
  GENERAL_TANKER = 'GENERAL_TANKER',
  GAS_TANKER = 'GAS_TANKER',
  TANKER_PRODUCT = 'TANKER_PRODUCT',
  PASSENGER = 'PASSENGER',
  OTHER_UNKNOWN = 'OTHER_UNKNOWN',
}

export default VesselTypes;
