import {
  StorageDirectory,
  StorageLevel,
} from '../../models/documents/document';
import store from '../../store';

const getEntityStorageDirectory = (
  entity: boolean,
  entityDirectory: string,
  nonEntityDirectory: string
) => (entity ? entityDirectory : nonEntityDirectory);

const getStorageFileDirectory = (
  storageLevel: StorageLevel,
  entityId?: string
) => {
  const companyId = store.getState().user.idToken?.companyId;
  const tenantId = store.getState().user.idToken?.tenantId;
  const isEntity = entityId !== undefined;

  let storageLocation: string;

  switch (storageLevel) {
    case StorageLevel.COMPANY:
      storageLocation = getEntityStorageDirectory(
        isEntity,
        `${StorageDirectory[StorageLevel.COMPANY]}${companyId}/${entityId}`,
        `${StorageDirectory[StorageLevel.COMPANY]}${companyId}/`
      );
      break;
    case StorageLevel.TENANT:
      storageLocation = getEntityStorageDirectory(
        isEntity,
        `${StorageDirectory[StorageLevel.TENANT]}${tenantId}/${entityId}`,
        `${StorageDirectory[StorageLevel.TENANT]}${tenantId}/`
      );
      break;
    case StorageLevel.PRIVATE:
      storageLocation = getEntityStorageDirectory(isEntity, `${entityId}`, '');
      break;
    default:
      throw new Error(`Invalid storage level: ${storageLevel}`);
  }
  return String(storageLocation);
};
export default getStorageFileDirectory;
