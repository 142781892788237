/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { AdtechData } from '../../api/adtech';

export type AdtechPanelState = {
  entityLoading: boolean | string;
  entityError: boolean;
  boundingBox: { bl: Position; tr: Position };
  entityData: AdtechData[];
  searchFilter: string;
  entitySearch: boolean;
  deviceEntityId: string;
  selectedTab: number;
  dataLayerVisible: boolean;
  entityDataLayerVisible: boolean;
  entityDataTracksLayerVisible: boolean;
};

export const INITIAL_ADTECH_PANEL_STATE: AdtechPanelState = {
  entityLoading: false,
  entityError: false,
  boundingBox: {
    bl: [27.35888889, 41.03361111],
    tr: [42.42416667, 47.47527778],
  },
  entityData: [],
  searchFilter: '',
  entitySearch: false,
  deviceEntityId: '',
  selectedTab: 0,
  dataLayerVisible: true,
  entityDataLayerVisible: true,
  entityDataTracksLayerVisible: true,
};

const AdtechPanelSlice = createSlice({
  name: 'adtechPanel',
  initialState: INITIAL_ADTECH_PANEL_STATE,
  reducers: {
    setBoundingBox: (
      state,
      action: PayloadAction<{ bl: Position; tr: Position }>
    ) => {
      state.boundingBox = action.payload;
    },
    setEntityData: (state, action: PayloadAction<AdtechData[]>) => {
      state.entityLoading = false;
      state.entityError = false;
      state.entityData = action.payload;
    },
    addEntityData: (state, action: PayloadAction<AdtechData[]>) => {
      state.entityData =
        state.entityData.length > 0
          ? state.entityData.concat(action.payload)
          : action.payload;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchFilter = action.payload;
    },
    setEntityLoading: (state, action: PayloadAction<boolean | string>) => {
      state.entityLoading = action.payload;
    },
    setEntityError: (state, action: PayloadAction<boolean>) => {
      state.entityError = action.payload;
    },
    setEntitySearch: (state, action: PayloadAction<boolean>) => {
      state.entitySearch = action.payload;
    },
    setDeviceEntityId: (state, action: PayloadAction<string>) => {
      state.deviceEntityId = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    setDataLayerVisible: (state, action: PayloadAction<boolean>) => {
      state.dataLayerVisible = action.payload;
    },
    setEntityDataLayerVisible: (state, action: PayloadAction<boolean>) => {
      state.entityDataLayerVisible = action.payload;
    },
    setEntityDataTracksLayerVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.entityDataTracksLayerVisible = action.payload;
    },
  },
});

export const {
  setEntityLoading,
  setEntityError,
  setBoundingBox,
  setEntityData,
  addEntityData,
  setSearchFilter,
  setEntitySearch,
  setDeviceEntityId,
  setSelectedTab,
  setDataLayerVisible,
  setEntityDataLayerVisible,
  setEntityDataTracksLayerVisible,
} = AdtechPanelSlice.actions;

export default AdtechPanelSlice.reducer;
