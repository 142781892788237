/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  City,
  ExpandedCity,
} from '../../models/risk-intelligence/risk_intelligence.model';

interface CitiesStateType {
  cities: City[] | null;
  selectedCity: null | ExpandedCity;
  selectedCityId: null | number;
  citiesLoading: boolean;
  cityLoading: boolean;
  error: boolean;
}

export const INITIAL_CITIES_STATE: CitiesStateType = {
  cities: null,
  selectedCity: null,
  selectedCityId: null,
  citiesLoading: false,
  cityLoading: false,
  error: false,
};

const CitiesSlice = createSlice({
  name: 'cities',
  initialState: INITIAL_CITIES_STATE,
  reducers: {
    setCities: (state, action: PayloadAction<City[]>) => {
      state.cities = action.payload;
      state.citiesLoading = false;
      state.error = false;
    },
    setSelectedCity: (state, action: PayloadAction<ExpandedCity | null>) => {
      state.cityLoading = false;
      state.error = false;
      state.selectedCity = action.payload;
    },
    setSelectedCityId: (state, action: PayloadAction<number | null>) => {
      state.selectedCityId = action.payload;
    },
    setCityLoading: (state) => {
      state.error = false;
      state.cityLoading = true;
    },
    setCitiesLoading: (state, action: PayloadAction<boolean>) => {
      state.citiesLoading = action.payload;
      state.error = false;
    },
    setError: (state) => {
      state.citiesLoading = false;
      state.cityLoading = false;
      state.error = true;
    },
  },
});

export const {
  setSelectedCity,
  setSelectedCityId,
  setCitiesLoading,
  setCityLoading,
  setError,
  setCities,
} = CitiesSlice.actions;

export default CitiesSlice.reducer;
