/* eslint-disable import/prefer-default-export */
import {
  setSelectedVesselIds,
  setSelectedVessels,
} from '../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { useAppDispatch } from '../hooks';
import { EDossiers, setSelectedDossier } from '../main-menu/menu.slice';
import {
  ExpandedId,
  MaybeExpandedId,
} from '../models/risk-intelligence/risk_intelligence.model';
import {
  setSelectedCity,
  setSelectedCityId,
} from '../state/cities/cities.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../state/countries/countries.slice';
import { setSelectedIncident } from '../state/incidents/incidents.slice';
import { setSelectedPort, setSelectedPortId } from '../state/ports/ports.slice';
import {
  setSelectedArea,
  setSelectedAreaId,
} from '../state/ri-maritime-areas/ri-maritime-areas.slice';
import store from '../store';

export const isExpandedId = (id: MaybeExpandedId): id is ExpandedId =>
  typeof id === 'object';

export const getRICountryTitle = (countryMaybeId: MaybeExpandedId) => {
  if (isExpandedId(countryMaybeId)) {
    return (countryMaybeId as ExpandedId).title;
  }
  const foundCountry = store
    .getState()
    .countries.countries?.find((country) => countryMaybeId === country.id);
  if (foundCountry) {
    return foundCountry.title;
  }
  return 'Not Found';
};

export const deselectAll = (dispatch: ReturnType<typeof useAppDispatch>) => {
  dispatch(setSelectedCity(null));
  dispatch(setSelectedPort(null));
  dispatch(setSelectedCountry(null));
  dispatch(setSelectedCityId(null));
  dispatch(setSelectedPortId(null));
  dispatch(setSelectedCountryId(null));
  dispatch(setSelectedIncident(null));
  dispatch(setSelectedVessels(null));
  dispatch(setSelectedVesselIds(null));
  dispatch(setSelectedArea(null));
  dispatch(setSelectedAreaId(null));
  dispatch(setSelectedDossier(EDossiers.NOTHING));
};
