/* eslint-disable import/prefer-default-export */
import {
  Drawing,
  DrawingDataResponse,
  DrawingDelete,
  DrawingInfoResponse,
  DrawingSubmit,
  DrawingUpdate,
} from '../models/drawings/drawings.model';
import store from '../store';
import { wrapRequest } from './base';

export const getDrawings = async (cognitoId?: string): Promise<Drawing[]> => {
  const cogId = cognitoId || store.getState().user.idToken?.sub;
  if (!cogId) throw new Error('Cannot determine user id');
  const drawingsPromise: Promise<DrawingInfoResponse[]> = wrapRequest(
    'get',
    'geonius',
    '/regions',
    {
      queryStringParameters: {
        cognito_id: cogId,
      },
    }
  );
  const drawingsDataPromise: Promise<DrawingDataResponse[]> = wrapRequest(
    'get',
    'geonius',
    '/regions/data',
    {
      queryStringParameters: {
        cognito_id: cogId,
      },
    }
  );
  const [drawings, drawingsData] = await Promise.all([
    drawingsPromise,
    drawingsDataPromise,
  ]);
  const fetchedDrawings: Drawing[] = drawings.map((drawingInfo) => {
    const drawingData = drawingsData.find(
      (drawing) => drawing.region_id === drawingInfo.region_id
    ) as DrawingDataResponse;

    return { ...drawingInfo, ...drawingData };
  });
  return fetchedDrawings;
};

export const deleteDrawings = async (
  drawingDelete: DrawingDelete[]
): Promise<void> =>
  wrapRequest('del', 'geonius', '/regions', {
    body: {
      regions: drawingDelete,
    },
  });

export const postDrawing = async (
  drawingSubmit: DrawingSubmit
): Promise<Drawing> =>
  wrapRequest('post', 'geonius', '/regions', {
    body: drawingSubmit,
  });

export const postUpdateDrawing = async (
  drawingUpdate: DrawingUpdate
): Promise<Drawing> =>
  wrapRequest('post', 'geonius', '/regions', {
    body: drawingUpdate,
    queryStringParameters: {
      region_id: drawingUpdate.region_id,
    },
  });

export const getCompanyDrawings = async (
  companyId?: string
): Promise<Drawing[]> => {
  const compId = companyId || store.getState().user.idToken?.companyId;
  const cogId = store.getState().user.idToken?.sub;
  if (!compId) throw new Error('No company id provided');
  if (!cogId) throw new Error('Cannot determine user id');
  const drawingsPromise: Promise<DrawingInfoResponse[]> = wrapRequest(
    'get',
    'geonius',
    '/regions/company',
    {
      queryStringParameters: {
        company_id: compId,
      },
    }
  );

  const drawingsDataPromise: Promise<DrawingDataResponse[]> = wrapRequest(
    'get',
    'geonius',
    '/regions/data',
    {
      queryStringParameters: {
        cognito_id: cogId,
      },
    }
  );

  const [drawings, drawingsData] = await Promise.all([
    drawingsPromise,
    drawingsDataPromise,
  ]);

  const fetchedDrawings: Drawing[] = drawings.map((drawingInfo) => {
    const drawingData = drawingsData.find(
      (drawing) => drawing.region_id === drawingInfo.region_id
    ) as DrawingDataResponse;

    return { ...drawingInfo, ...drawingData };
  });
  return fetchedDrawings;
};
