/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import {
  RfEvent,
  RfEventFilters,
  RfTarget,
} from '../../models/rf-data/rf-data.model';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';

export enum RfDataPanelViewState {
  SEARCH = 'search',
  TARGETS = 'targets',
  EVENTS = 'events',
}

type RfDataPanelViewType =
  | RfDataPanelViewState.SEARCH
  | RfDataPanelViewState.TARGETS
  | RfDataPanelViewState.EVENTS;

interface IRfDataState {
  rfLoading: boolean;
  rfEventsLoading: boolean;
  rfError: boolean;
  rfData: RfEvent[] | null;
  rfTargets: RfTarget[] | null;
  viewState: RfDataPanelViewState;
  rfDrawingValues: GeoJSON.Polygon | null;
}

export const defaultRfEventFilters: RfEventFilters = {
  startDate: DateTimeHelpers.dateToIsoDate(subMonths(new Date(), 1)),
  endDate: DateTimeHelpers.dateToIsoDate(new Date()),
  targets: [],
};

export const INITIAL_RF_DATA_STATE: IRfDataState = {
  rfLoading: false,
  rfEventsLoading: false,
  rfError: false,
  rfData: null,
  rfTargets: null,
  rfDrawingValues: null,
  viewState: RfDataPanelViewState.SEARCH,
};

export const RfDataSlice = createSlice({
  name: 'rfData',
  initialState: INITIAL_RF_DATA_STATE,
  reducers: {
    setRfLoading: (state) => {
      state.rfLoading = true;
      state.rfError = false;
    },
    setRfEventsLoading: (state) => {
      state.rfEventsLoading = true;
      state.rfError = false;
    },
    setRfError: (state) => {
      state.rfError = true;
      state.rfLoading = false;
      state.rfEventsLoading = false;
    },
    setRfData: (state, action: PayloadAction<RfEvent[] | null>) => {
      // skip updating state if it would be [] to prevent spurious renders
      if (!action.payload || action.payload.length > 0) {
        state.rfData = action.payload;
      }
      state.rfLoading = false;
      state.rfEventsLoading = false;
      state.rfError = false;
    },
    setRfTargets: (state, action: PayloadAction<RfTarget[] | null>) => {
      // skip updating state if it would be [] to prevent spurious renders
      if (!action.payload || action.payload.length > 0) {
        state.rfTargets = action.payload;
      }
      state.rfLoading = false;
      state.rfError = false;
    },
    setViewState: (state, action: PayloadAction<RfDataPanelViewType>) => {
      state.viewState = action.payload;
    },
    setRfDrawingValues: (
      state,
      action: PayloadAction<GeoJSON.Polygon | null>
    ) => {
      state.rfDrawingValues = action.payload;
    },
  },
});

export const {
  setRfDrawingValues,
  setRfLoading,
  setRfEventsLoading,
  setRfError,
  setRfData,
  setRfTargets,
  setViewState,
} = RfDataSlice.actions;

export default RfDataSlice.reducer;
