/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import TenantId from '../tenant';

export interface Token {
  sub: string;
  username: string;
  email: string;
  groups: string[] | null;
  roles: string[] | null;
  preferred_role: string;
  companyId: string;
  tenantId: TenantId;
}

export interface UserFeatureFlags {
  ports_license: boolean;
  cruise_license: boolean;
  fleet_license: boolean;
  ais_history: boolean;
  voyager_intel_tool: boolean;
  sanctions: boolean;
  avcs?: boolean;
}

export interface GeollectUserFeatureFlags {
  avcs?: boolean;
}

interface UserState {
  idToken: Token | null;
  idTokenError: string | null;
  liteMode: boolean;
  showLiteModePopup: boolean;
  userSigningOut: boolean;
  featureFlags: UserFeatureFlags | null;
  userMenuOpen: boolean;
}

export const LITE_MODE_LOCAL_STORAGE_KEY = 'lite_mode';

export const INITIAL_USER_STATE: UserState = {
  idToken: null,
  idTokenError: null,
  liteMode: JSON.parse(
    localStorage.getItem(LITE_MODE_LOCAL_STORAGE_KEY) || JSON.stringify(false)
  ),
  showLiteModePopup: false,
  userSigningOut: false,
  featureFlags: null,
  userMenuOpen: false,
};

const UserSlice = createSlice({
  name: 'User',
  initialState: INITIAL_USER_STATE,
  reducers: {
    setUserIdToken: (state, action) => {
      const token: Token = {
        sub: action.payload.sub,
        username: action.payload['cognito:username'],
        email: action.payload.email,
        groups: action.payload['cognito:groups'],
        roles: action.payload['cognito:roles'],
        preferred_role: action.payload.preferred_role, // Assign the value from object.preferred_role
        companyId: action.payload['custom:companyId'],
        tenantId: action.payload['custom:tenantId'],
      };
      state.idToken = token;
    },
    setUserIdTokenError: (state, action) => {
      state.idTokenError = action.payload;
    },
    setTenant: (state, action) => {
      if (!state.idToken) return;
      state.idToken.tenantId = action.payload;
    },
    setUserFeatureFlags: (
      state,
      action: PayloadAction<UserState['featureFlags']>
    ) => {
      if (!state.idToken) return;
      state.featureFlags = action.payload;
    },
    setLiteMode: (state, action: PayloadAction<UserState['liteMode']>) => {
      localStorage.setItem(
        LITE_MODE_LOCAL_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
      state.liteMode = action.payload;
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
    setShowLiteModePopup: (
      state,
      action: PayloadAction<UserState['showLiteModePopup']>
    ) => {
      state.showLiteModePopup = action.payload;
    },
    setUserSigningOut: (state) => {
      state.userSigningOut = true;
    },
    setUserMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.userMenuOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserIdToken,
  setUserIdTokenError,
  setTenant,
  setUserFeatureFlags,
  setLiteMode,
  setShowLiteModePopup,
  setUserSigningOut,
  setUserMenuOpen,
} = UserSlice.actions;

export default UserSlice.reducer;
