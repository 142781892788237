import { Feature, FeatureCollection, Point } from '@turf/helpers';
import { GeoJSONSource } from 'mapbox-gl';

import addSimpleGeoJsonSource from '../../map/map-layer-manager/layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../../map/map-layer-manager/map-layer-icon';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import {
  MapGroupLayer,
  RfLayers,
} from '../../map/map-layer-manager/map-layer.enum';
import RFVesselsController from '../../map/map-layer-manager/vessel-utils/rf-vessel-controller';
import MapHelpers from '../../map/map.utils';
import { RfTarget } from '../../models/rf-data/rf-data.model';
import store from '../../store';
import GeoHelper from '../../utils/geo-helpers.utils';

namespace RfTargetController {
  const rfTargetLayer = {
    addLayer: (layerId: string, data: FeatureCollection<Point>) => {
      const map = MapHelpers.getMapInstance();
      addSimpleGeoJsonSource(layerId);
      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: layerId,
        layout: {
          'icon-image': [
            'case',
            // TODO: Change json data & count to relevant target data
            ['==', ['get', 'rfgl_anomaly_count'], 0],
            MapLayerIcon.VESSEL_SEARCH_AREA.NORMAL,
            ['==', ['get', 'rfgl_anomaly_count'], 1],
            MapLayerIcon.VESSEL_SEARCH_AREA.AMBER,
            ['>', ['get', 'rfgl_anomaly_count'], 1],
            MapLayerIcon.VESSEL_SEARCH_AREA.ALERT,
            MapLayerIcon.VESSEL_SEARCH_AREA.NORMAL, // Default icon if none of the conditions are met
          ],
          'icon-allow-overlap': true,
          'icon-rotation-alignment': 'map',
          'icon-rotate': ['number', ['get', 'heading'], 360],
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.1, 10, 0.2],
        },
        metadata: MapLayerManager.groupLayerMetaData([
          layerId,
          MapGroupLayer.RF_TARGETS,
          MapGroupLayer.RF,
        ]),
      });

      (map.getSource(layerId) as GeoJSONSource)?.setData(data);
      // Tool Tip RF Information hover over
      map.on(
        'mouseenter',
        layerId,
        RFVesselsController.layerEvents.onMouseEnterTarget
      );
      map.on(
        'mouseleave',
        layerId,
        RFVesselsController.layerEvents.onMouseLeave
      );
      // map.on('click', layerId, onVesselClick);
    },
    groupLayer: MapGroupLayer.RF_TARGETS,
  };

  export const layerList = {
    RF_TARGETS: rfTargetLayer,
  };

  export const clearRfTargetLayers = () => {
    // There's actually only one target layer but we might want multiple in the future
    Object.values(layerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        item.groupLayer
      );
      existingLayers.forEach((layer) => MapLayerManager.destroyLayer(layer.id));
    });

    if (MapHelpers.getLayer(MapGroupLayer.RF_TARGETS)) {
      MapHelpers.removeLayer(MapGroupLayer.RF_TARGETS);
    }

    RfLayers.forEach((layer) => {
      if (MapHelpers.getLayer(layer)) {
        MapLayerManager.destroyLayer(layer);
      }
    });
  };

  export const handleTargets = (targets: RfTarget[]) => {
    const targetLocations: Feature<Point>[] = [];

    targets.forEach((target) => {
      targetLocations.push(
        GeoHelper.createFeaturePoint(
          [target.longitude, target.latitude],
          target
        ) // handle properties
      );
    });

    rfTargetLayer.addLayer(
      MapGroupLayer.RF_TARGETS,
      GeoHelper.createGeoJSON(targetLocations) as FeatureCollection<Point>
    );
  };

  export const init = () => {
    const { rfTargets } = store.getState().rfData;
    clearRfTargetLayers();
    if (rfTargets) {
      handleTargets(rfTargets);
    }
  };
}

export default RfTargetController;
