/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from '@turf/helpers';
import subYears from 'date-fns/subYears';
import DateFilters from '../../common-components/date-filter/date-filter.model';
import { CapellaFilters } from '../../models/capella/capella-model';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import {
  defaultBooleanFilters,
  defaultNumberFilters,
} from './capella-filters/default-filters';

interface CapellaPanelState {
  filters: CapellaFilters;
  searchArea: Feature<Point | Polygon> | null;
}

export const defaultCapellaFilters: CapellaFilters = {
  startDate: DateTimeHelpers.dateToIsoDate(subYears(new Date(), 1)),
  endDate: DateTimeHelpers.dateToIsoDate(new Date()),
  collections: [
    'capella-open-data',
    'capella-slc',
    'capella-geo',
    'capella-gec',
    'capella-sicd',
    'capella-vessel-detection',
    'capella-samples',
    'capella-sidd',
    'capella-cphd',
  ],
  search: '',
  booleanFilters: defaultBooleanFilters,
  numberFilters: defaultNumberFilters,
};

export const INITIAL_CAPELLA_PANEL_STATE: CapellaPanelState = {
  filters: defaultCapellaFilters,
  searchArea: null,
};

const CapellaPanelSlice = createSlice({
  name: 'capellaPanel',
  initialState: INITIAL_CAPELLA_PANEL_STATE,
  reducers: {
    setCapellaFilters: (
      state,
      action: PayloadAction<Partial<CapellaFilters> | null>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setSearchArea: (
      state,
      action: PayloadAction<Feature<Point | Polygon> | null>
    ) => {
      state.searchArea = action.payload;
    },
    setCapellaDates: (state, action: PayloadAction<DateFilters>) => {
      state.filters = {
        ...state.filters,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
  },
});

export const { setCapellaFilters, setSearchArea, setCapellaDates } =
  CapellaPanelSlice.actions;

export default CapellaPanelSlice.reducer;
