import { PermissionToggles } from '../../admin/admin-page/admin.slice';
import {
  ALERT_PERMISSIONS_MAPPING,
  AlertPermissionsMapping,
  EAlertTypes,
} from '../../models/alerts/alert-configuration';
import VesselTypes from '../../models/vessels/vessel-types.model';
import store from '../../store';
import TenantId from '../../tenant';
import { Token, UserFeatureFlags } from '../../user/user.slice';
import { UserRole } from '../../utils/user.enum';
import {
  COMPANIES_ALLOWED_ACCESS_TO_ADTECH_POC,
  COMPANIES_ALLOWED_ACCESS_TO_CAPELLA,
  COMPANIES_ALLOWED_ACCESS_TO_ESA,
  COMPANIES_ALLOWED_ACCESS_TO_FLEETS,
  COMPANIES_ALLOWED_ACCESS_TO_GLOBAL_AIS,
  COMPANIES_ALLOWED_ACCESS_TO_HISTORIC_VESSELS,
  COMPANIES_ALLOWED_ACCESS_TO_SUBSEA_INFRASTRUCTURE_POC,
  COMPANIES_ALLOWED_ACCESS_TO_UNSEEN_LABS,
  COMPANIES_ALLOWED_ACCESS_TO_TIMELINE,
} from './access-control-constants';
import { getReleaseToggles, ReleaseToggles } from './release-toggles';

export type Privileges = {
  canAccessAlerts: Record<EAlertTypes, boolean>;
  canAccessVesselTypes: Record<VesselTypes, boolean>;
  canAccessIncidents: boolean;
  canAccessMyFleetV1: {
    ais: boolean;
    ri: boolean;
  };
  canAccessMyFleetV2: boolean;
  canAccessFleetsPhase2: boolean;
  canAccessPorts: {
    wpi: boolean;
    land: boolean;
    coastal: boolean;
    cruise: boolean;
  };
  canAccessRoutes: boolean;
  canAccessHistory: boolean;
  canAccessBoundaries: boolean;
  canAccessDrawings: boolean;
  canAccessDrawingsColour: boolean;
  canAccessMaps: {
    openSeaMaps: boolean;
    bathymetry: boolean;
  };
  canAccessSanctions: boolean;
  canAccessRiMaritimeAreas: boolean;
  canAccessTimeline: boolean;
  canAccessMapTools: boolean;
  canAccessGraphs: boolean;
  canAccessNearbyVessels: boolean;
  canAccessHistoricVessels: boolean;
  canAccessWeather: boolean;
  canAccessSharedDrawings: boolean;
  canAccessShippingLanes: boolean;
  canMultiSelect: boolean;
  canAccessRiBoundaries: boolean;
  canAccessPositionValidation: boolean;
  canAccessHelp: boolean;
  canAccessDidYouKnow: boolean;
  canAccessIncidentsDateRangeIndicator: boolean;
  canAccessDatumRings: boolean;
  canAccessAdtechData: boolean;
  canDrawPoints: boolean;
  canAccessHighTierHistoryWS: boolean;
  canAccessAvcsCharts: boolean;
  canAccessSubseaInfrastructure: boolean;
  canAccessFathom: boolean;
  canAccessEsa: boolean;
  canAccessMapLayerHandler: boolean;
  canAccessGlobalAis: boolean;
  canAccessMergeStationaryPoints: boolean;
  canAccessCapella: boolean;
  canAccessRadioFrequencyData: boolean;
  canAccessPermissionToggles: boolean;
  canEditCompanySeats: boolean;
  canAccessMeasuringTool: boolean;
  canAccessRangeRings: boolean;
  canAccessNewsTicker: boolean;
  canAccessRICitiesCountriesData: boolean;
};

const getCanAccessPermissionToggles = (idToken: Token) => {
  const releaseToggles = getReleaseToggles();

  // Can't import the existing isAdmin function from `user.utils.tsx`, as it would create a circular dependency.
  const isAdminUser = [UserRole.GLOBAL_ADMIN, UserRole.COMPANY_ADMIN].some(
    (role) => store.getState().user.idToken?.groups?.includes(role)
  );

  const canAccessPermissionToggles =
    idToken?.tenantId === TenantId.GEOLLECT &&
    isAdminUser &&
    releaseToggles.adminPermissionsTogglesEnabled;

  return canAccessPermissionToggles;
};

export const NO_PRIVILEGES: Privileges = {
  canAccessAlerts: {
    AIS_OFF: false,
    AIS_ON: false,
    ENTER_PORT: false,
    ENTER_ROI: false,
    EXIT_ROI: false,
    INCIDENTS: false,
    INCIDENTS_ROUTE: false,
    DEP_PORT: false,
    STS: false,
    STATIC_CHANGE: false,
  },
  canAccessVesselTypes: {
    DRY_BULK: false,
    GENERAL_CARGO: false,
    CONTAINER: false,
    REEFER: false,
    ROLL_ON_ROLL_OFF: false,
    OFFSHORE: false,
    HIGH_SPEED_CRAFT: false,
    CAR_CARRIER: false,
    VEHICLE_PASSENGER: false,
    PLEASURE_CRAFT: false,
    FISHING: false,
    GENERAL_TANKER: false,
    GAS_TANKER: false,
    TANKER_PRODUCT: false,
    PASSENGER: false,
    OTHER_UNKNOWN: false,
  },
  canAccessIncidents: false,
  canAccessMyFleetV1: {
    ais: false,
    ri: false,
  },
  canAccessMyFleetV2: false,
  canAccessFleetsPhase2: false,
  canAccessPorts: {
    wpi: false,
    land: false,
    coastal: false,
    cruise: false,
  },
  canAccessRoutes: false,
  canAccessHistory: false,
  canAccessBoundaries: false,
  canAccessDrawings: false,
  canAccessDrawingsColour: false,
  canAccessMaps: {
    openSeaMaps: false,
    bathymetry: false,
  },
  canAccessSanctions: false,
  canAccessRiMaritimeAreas: false,
  canAccessTimeline: false,
  canAccessMapTools: false,
  canAccessGraphs: false,
  canAccessNearbyVessels: false,
  canAccessHistoricVessels: false,
  canAccessWeather: false,
  canAccessSharedDrawings: false,
  canAccessShippingLanes: false,
  canMultiSelect: false,
  canAccessRiBoundaries: false,
  canAccessPositionValidation: false,
  canAccessHelp: false,
  canAccessDidYouKnow: false,
  canAccessIncidentsDateRangeIndicator: false,
  canAccessDatumRings: false,
  canAccessAdtechData: false,
  canDrawPoints: false,
  canAccessHighTierHistoryWS: false,
  canAccessSubseaInfrastructure: false,
  canAccessAvcsCharts: false,
  canAccessFathom: false,
  canAccessEsa: false,
  canAccessMapLayerHandler: false,
  canAccessGlobalAis: false,
  canAccessMergeStationaryPoints: false,
  canAccessCapella: false,
  canAccessRadioFrequencyData: false,
  canAccessPermissionToggles: false,
  canEditCompanySeats: false,
  canAccessMeasuringTool: false,
  canAccessRangeRings: false,
  canAccessNewsTicker: false,
  canAccessRICitiesCountriesData: false,
};

const getCanAccessAlerts = (companyPermissionToggles: PermissionToggles) =>
  Object.keys(ALERT_PERMISSIONS_MAPPING).reduce((acc, alertType) => {
    const alertPermissions =
      ALERT_PERMISSIONS_MAPPING[alertType as keyof AlertPermissionsMapping];

    const isToggleEnabled = (toggle: keyof PermissionToggles) =>
      companyPermissionToggles[toggle]?.enabled ?? false;

    const allRequiredEnabled = alertPermissions.allOf.every(isToggleEnabled);
    const atLeastOneRequiredEnabled =
      alertPermissions.oneOf.length === 0 ||
      alertPermissions.oneOf.some(isToggleEnabled);

    return {
      ...acc,
      [alertType as EAlertTypes]:
        allRequiredEnabled && atLeastOneRequiredEnabled,
    };
  }, {} as Record<EAlertTypes, boolean>);

type GeollectPermissionTogglePrivilegesArgs = {
  releaseToggles: ReleaseToggles;
  permissionToggles: PermissionToggles | null;
  userFeatureFlags: UserFeatureFlags | null;
  idToken: Token;
};

export const geollectPrivilegesPermissionsTogglesV1 = ({
  permissionToggles,
  releaseToggles,
  userFeatureFlags,
  idToken,
}: GeollectPermissionTogglePrivilegesArgs): Privileges =>
  permissionToggles
    ? {
        canAccessAvcsCharts:
          releaseToggles.avcsEnabled &&
          !!permissionToggles.avcs?.enabled &&
          !!userFeatureFlags?.avcs,
        canAccessGlobalAis:
          releaseToggles.globalAisEnabled &&
          !!permissionToggles.global_ais?.enabled,
        canAccessRoutes: !!permissionToggles.routes?.enabled,
        canAccessMaps: {
          openSeaMaps: !!permissionToggles.opensea_maps?.enabled,
          bathymetry: !!permissionToggles.bathymetry?.enabled,
        },
        canAccessPorts: {
          wpi: !!permissionToggles.wpi_ports?.enabled,
          land: !!permissionToggles.ri_ports?.enabled,
          coastal: !!permissionToggles.ri_ports?.enabled,
          cruise: !!permissionToggles.ri_ports?.enabled,
        },
        canAccessVesselTypes: Object.keys(
          NO_PRIVILEGES.canAccessVesselTypes
        ).reduce(
          (acc, vesselType) => ({
            ...acc,
            [vesselType]:
              !!permissionToggles.fleets?.enabled ||
              !!permissionToggles.nearby_vessel?.enabled,
          }),
          NO_PRIVILEGES.canAccessVesselTypes
        ),
        canAccessCapella: !!permissionToggles.sar_imagery?.enabled,
        canAccessRiMaritimeAreas: !!permissionToggles.ri_areas?.enabled,
        canAccessBoundaries: !!permissionToggles.boundaries?.enabled,
        canAccessAdtechData: !!permissionToggles.adtech_data?.enabled,
        canAccessEsa: !!permissionToggles.esa_maps?.enabled,
        canAccessIncidents: !!permissionToggles.ri_incidents?.enabled,
        canAccessPositionValidation:
          releaseToggles.rfDataEnabled &&
          !!permissionToggles.position_validation?.enabled,
        canAccessRadioFrequencyData:
          releaseToggles.unseenLabsEnabled &&
          !!permissionToggles.radio_frequency_data?.enabled,
        canAccessSubseaInfrastructure:
          !!permissionToggles.subsea_infrastructure?.enabled,
        canAccessDrawings: !!permissionToggles.drawings?.enabled,
        canAccessDrawingsColour: !!permissionToggles.drawings?.enabled,
        canAccessSharedDrawings: !!permissionToggles.drawings?.enabled,
        canAccessPermissionToggles: getCanAccessPermissionToggles(idToken),
        canAccessHistory: !!permissionToggles.history_specific_period?.enabled,
        canEditCompanySeats: true,
        canAccessMeasuringTool: !!permissionToggles.measuring_tool?.enabled,
        canAccessMapTools:
          !!permissionToggles.measuring_tool?.enabled ||
          !!permissionToggles.range_rings?.enabled ||
          !!permissionToggles.weather?.enabled ||
          !!permissionToggles.shipping_lanes?.enabled ||
          !!permissionToggles.nearby_vessel?.enabled ||
          !!permissionToggles.search_by_area?.enabled, // Add || when other toggles for Tools are implemented
        canAccessHighTierHistoryWS:
          !!permissionToggles.history_specific_period?.enabled,
        canAccessAlerts: getCanAccessAlerts(permissionToggles),
        canAccessShippingLanes: !!permissionToggles.shipping_lanes?.enabled,
        canAccessRangeRings: !!permissionToggles.range_rings?.enabled,
        canAccessWeather: !!permissionToggles.weather?.enabled,
        canAccessNearbyVessels: !!permissionToggles.nearby_vessel?.enabled,
        canAccessHistoricVessels: !!permissionToggles.search_by_area?.enabled,
        canAccessMyFleetV1: {
          ais:
            !!permissionToggles.fleets?.enabled &&
            (!releaseToggles.fleetV2Enabled ||
              releaseToggles.fleetV1AvailableWithFleetV2),
          ri: false,
        },
        canAccessMyFleetV2:
          !!permissionToggles.fleets?.enabled && releaseToggles.fleetV2Enabled,
        canAccessFleetsPhase2:
          !!permissionToggles.fleets?.enabled &&
          releaseToggles.fleetV2Enabled &&
          releaseToggles.fleetV2Phase2Enabled,
        canAccessRICitiesCountriesData:
          !!permissionToggles.ri_cities_countries_data?.enabled,
        canAccessNewsTicker:
          !!permissionToggles.ri_incidents?.enabled ||
          !!permissionToggles.ri_cities_countries_data?.enabled ||
          !!permissionToggles.ri_areas?.enabled ||
          !!permissionToggles.ri_ports?.enabled,
        canAccessSanctions:
          !!permissionToggles.sanctions_countries_data?.enabled,
        canAccessTimeline: idToken.companyId
          ? COMPANIES_ALLOWED_ACCESS_TO_TIMELINE.includes(idToken.companyId)
          : false,
        canAccessGraphs: true,
        canMultiSelect: true,
        canAccessRiBoundaries: false,
        canAccessHelp: false,
        canAccessDidYouKnow: false,
        canAccessIncidentsDateRangeIndicator: false,
        canAccessDatumRings: false,
        canDrawPoints: !!permissionToggles.drawings?.enabled,
        canAccessFathom: true,
        canAccessMapLayerHandler: releaseToggles.mapLayerHandlerEnabled,
        canAccessMergeStationaryPoints:
          releaseToggles.mergeStationaryPointsEnabled,
      }
    : NO_PRIVILEGES;

type GeollectPrivilegesArgs = {
  releaseToggles: ReleaseToggles;
  userFeatureFlags: UserFeatureFlags | null;
  idToken: Token;
};

export const geollectPrivileges = ({
  idToken,
  releaseToggles,
  userFeatureFlags,
}: GeollectPrivilegesArgs): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessAlerts: {
    AIS_OFF: true,
    AIS_ON: true,
    ENTER_PORT: true,
    ENTER_ROI: true,
    EXIT_ROI: true,
    INCIDENTS: true,
    INCIDENTS_ROUTE: true,
    DEP_PORT: true,
    STS: true,
    STATIC_CHANGE: true,
  },
  canAccessVesselTypes: {
    DRY_BULK: true,
    GENERAL_CARGO: true,
    CONTAINER: true,
    REEFER: true,
    ROLL_ON_ROLL_OFF: true,
    OFFSHORE: true,
    HIGH_SPEED_CRAFT: true,
    CAR_CARRIER: true,
    VEHICLE_PASSENGER: true,
    PLEASURE_CRAFT: true,
    FISHING: true,
    GENERAL_TANKER: true,
    GAS_TANKER: true,
    TANKER_PRODUCT: true,
    PASSENGER: true,
    OTHER_UNKNOWN: true,
  },
  canAccessIncidents: true,
  canAccessMyFleetV1: {
    ais: true,
    ri: false,
  },
  canAccessMyFleetV2:
    releaseToggles.fleetV2Enabled &&
    COMPANIES_ALLOWED_ACCESS_TO_FLEETS.includes(idToken.companyId),
  canAccessFleetsPhase2:
    releaseToggles.fleetV2Enabled &&
    COMPANIES_ALLOWED_ACCESS_TO_FLEETS.includes(idToken.companyId) &&
    releaseToggles.fleetV2Phase2Enabled,
  canAccessPorts: {
    wpi: true,
    land: true,
    coastal: true,
    cruise: true,
  },
  canAccessRoutes: true,
  canAccessHistory: true,
  canAccessBoundaries: true,
  canAccessDrawings: true,
  canAccessDrawingsColour: true,
  canAccessMaps: {
    openSeaMaps: true,
    bathymetry: true,
  },
  canAccessSanctions: true,
  canAccessRiMaritimeAreas: true,
  canAccessTimeline: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_TIMELINE.includes(idToken.companyId)
    : false,
  canAccessMapTools: true,
  canAccessGraphs: true,
  canAccessNearbyVessels: true,
  canAccessHistoricVessels: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_HISTORIC_VESSELS.includes(idToken.companyId)
    : false,
  canAccessWeather: true,
  canAccessSharedDrawings: true,
  canAccessShippingLanes: true,
  canMultiSelect: true,
  canAccessCapella: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_CAPELLA.includes(idToken.companyId)
    : false,
  canAccessRadioFrequencyData: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_UNSEEN_LABS.includes(idToken.companyId)
    : false,
  canAccessPositionValidation: false,
  canAccessDatumRings: false,
  canAccessAdtechData: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_ADTECH_POC.includes(idToken.companyId)
    : false,
  canDrawPoints: true,
  canAccessHighTierHistoryWS: true,
  canAccessSubseaInfrastructure: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_SUBSEA_INFRASTRUCTURE_POC.includes(
        idToken.companyId
      )
    : false,
  canAccessAvcsCharts:
    releaseToggles.avcsEnabled && (userFeatureFlags?.avcs || false),
  canAccessFathom: true,
  canAccessEsa: idToken.companyId
    ? COMPANIES_ALLOWED_ACCESS_TO_ESA.includes(idToken.companyId)
    : false,
  canAccessMapLayerHandler: releaseToggles.mapLayerHandlerEnabled,
  canAccessGlobalAis:
    releaseToggles.globalAisEnabled &&
    !!idToken.companyId &&
    COMPANIES_ALLOWED_ACCESS_TO_GLOBAL_AIS.includes(idToken.companyId),
  canAccessMergeStationaryPoints: releaseToggles.mergeStationaryPointsEnabled,
  canAccessPermissionToggles: getCanAccessPermissionToggles(idToken),
  canEditCompanySeats: true,
  canAccessMeasuringTool: true,
  canAccessRangeRings: true,
  canAccessNewsTicker: true,
  canAccessRICitiesCountriesData: true,
});

export const riskIntelligencePrivileges = (
  userFeatureFlags: UserFeatureFlags | null
): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessAlerts: {
    ...NO_PRIVILEGES.canAccessAlerts,
    ENTER_ROI: userFeatureFlags?.fleet_license || false,
    INCIDENTS: userFeatureFlags?.fleet_license || false,
    INCIDENTS_ROUTE: userFeatureFlags?.voyager_intel_tool || false,
  },
  canAccessVesselTypes: {
    ...NO_PRIVILEGES.canAccessVesselTypes,
  },
  canAccessIncidents: true,
  canAccessMyFleetV1: {
    ais: userFeatureFlags?.fleet_license || false,
    ri: true,
  },
  canAccessPorts: {
    wpi:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    land:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    coastal:
      userFeatureFlags?.ports_license ||
      userFeatureFlags?.cruise_license ||
      false,
    cruise:
      (userFeatureFlags?.ports_license && userFeatureFlags?.cruise_license) ||
      false,
  },
  canAccessRoutes: userFeatureFlags?.voyager_intel_tool || false,
  canAccessHistory: userFeatureFlags?.ais_history || false,
  canAccessBoundaries: true,
  canAccessDrawings: true,
  canAccessDrawingsColour: false,
  canAccessMaps: {
    openSeaMaps: true,
    bathymetry: false,
  },
  canAccessSanctions: userFeatureFlags?.sanctions || false,
  canAccessRiMaritimeAreas: true,
  canAccessRiBoundaries: true,
  canAccessHelp: true,
  canAccessDidYouKnow: true,
  canAccessIncidentsDateRangeIndicator: true,
  canAccessDatumRings: true,
  canAccessFathom: true,
  canEditCompanySeats: false,
  canAccessNewsTicker: true,
  canAccessRICitiesCountriesData: true,
});

type CalculatePrivilegesArgs = {
  idToken: Token | null;
  userFeatureFlags: UserFeatureFlags | null;
  permissionToggles: PermissionToggles | null;
};

export const calculatePrivileges = ({
  idToken,
  permissionToggles,
  userFeatureFlags,
}: CalculatePrivilegesArgs) => {
  if (!idToken?.tenantId) return NO_PRIVILEGES;
  const releaseToggles = getReleaseToggles();

  const privileges: Record<TenantId, Privileges> = {
    [TenantId.GEOLLECT]: releaseToggles.permissionsTogglesEnabled
      ? geollectPrivilegesPermissionsTogglesV1({
          permissionToggles,
          releaseToggles,
          userFeatureFlags,
          idToken,
        })
      : geollectPrivileges({
          idToken,
          releaseToggles,
          userFeatureFlags,
        }),
    [TenantId.RISK_INTELLIGENCE]: riskIntelligencePrivileges(userFeatureFlags),
  };

  return privileges[idToken.tenantId];
};
