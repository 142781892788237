import { Auth } from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import { useEffect, useRef, useState } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMobile = () => useMediaQuery({ query: '(max-width: 750px)' });

export const useTenantId = () => {
  const idToken = useAppSelector((state) => state.user.idToken);
  if (!idToken) return undefined;
  return idToken.tenantId;
};

export const useSignedUrl = (url: string | undefined, shouldSign: boolean) => {
  const [signedUrl, setSignedUrl] = useState<URL | null>(null);
  useEffect(() => {
    const updateSignedUrl = async () => {
      if (!url) {
        // no url to sign
        return;
      }
      const unsignedUrl = new URL(url);
      if (
        unsignedUrl.searchParams.has('X-Amz-Algorithm') ||
        process.env.NODE_ENV === 'test'
      ) {
        // url already signed
        setSignedUrl(unsignedUrl);
        return;
      }
      const creds = await Auth.currentCredentials();

      setSignedUrl(
        new URL(
          Signer.signUrl(unsignedUrl.toString(), {
            access_key: creds.accessKeyId,
            secret_key: creds.secretAccessKey,
            session_token: creds.sessionToken,
          })
        )
      );
    };
    if (!shouldSign) {
      setSignedUrl(new URL(url!));
      return;
    }

    if (url) {
      updateSignedUrl();
    }
  }, [url, shouldSign]);
  return signedUrl;
};

// Allows setInterval to work (better) with react hooks
// using setInterval natively causes it to hold stale state
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void,
  delay: number,
  isPlaying: boolean = true
) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isPlaying) return () => {};
    const tick = () => savedCallback.current?.();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay, isPlaying]);
};
