/* eslint-disable import/no-named-as-default */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PermissionToggles } from '../../admin/admin-page/admin.slice';

type UserPermissionsInitialState = {
  permissions: PermissionToggles;
  userPermissionsInitialised: boolean;
};

export const DEFAULT_USER_PERMISSION_TOGGLES: PermissionToggles = {
  adtech_data: { enabled: false },
  ais_on_off: { enabled: false },
  ais_spoofing: { enabled: false },
  aoi_enter_exit: { enabled: false },
  avcs: { enabled: false },
  bathymetry: { enabled: false },
  boundaries: { enabled: false },
  discuss: { enabled: false },
  drawings: { enabled: false },
  esa_maps: { enabled: false },
  fleets: { enabled: false },
  global_ais: { enabled: false },
  history_all_periods: { enabled: false },
  history_specific_period: { enabled: false },
  incidents: { enabled: false },
  incidents_on_route: { enabled: false },
  measuring_tool: { enabled: false },
  nearby_vessel: { enabled: false },
  opensea_maps: { enabled: false },
  port_enter_exit: { enabled: false },
  position_validation: { enabled: false },
  radio_frequency_data: { enabled: false },
  range_rings: { enabled: false },
  ri_areas: { enabled: false },
  ri_incidents: { enabled: false },
  ri_ports: { enabled: false },
  ri_threat_assessments: { enabled: false },
  routes: { enabled: false },
  sar_imagery: { enabled: false },
  search_by_area: { enabled: false },
  shipping_lanes: { enabled: false },
  static_changes: { enabled: false },
  sts: { enabled: false },
  subsea_infrastructure: { enabled: false },
  weather: { enabled: false },
  wpi_ports: { enabled: false },
  sanctions_countries_data: { enabled: false },
  ri_cities_countries_data: { enabled: false },
};

export const INITIAL_USER_PERMISSIONS_STATE: UserPermissionsInitialState = {
  permissions: {
    ...DEFAULT_USER_PERMISSION_TOGGLES,
  },
  userPermissionsInitialised: false,
};

const UserPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState: INITIAL_USER_PERMISSIONS_STATE,
  reducers: {
    setUserPermissions: (
      state,
      action: PayloadAction<PermissionToggles | null>
    ) => {
      state.permissions = {
        ...DEFAULT_USER_PERMISSION_TOGGLES, // In case we don't receive a full list from the backend
        ...action.payload,
      };
    },
    setUserPermissionsInitialised: (state, action: PayloadAction<boolean>) => {
      state.userPermissionsInitialised = action.payload;
    },
  },
});

export const { setUserPermissions, setUserPermissionsInitialised } =
  UserPermissionsSlice.actions;

export default UserPermissionsSlice.reducer;
