/* eslint-disable import/prefer-default-export */
import { calculatePrivileges } from '../hooks/access-control/privileges';
import { Boundary, RiBoundaryData } from '../models/boundaries/boundary.model';
import store from '../store';
import { wrapRequest } from './base';

export const getBoundaries = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      format: 'vector_tile',
    },
  });

export const getCombinedEEZ = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.eez_combined',
    },
  });

export const getCombinedJWC = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.jwc_combined',
    },
  });

export const getCombinedTTW = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.ttw_combined',
    },
  });

export const getRiBoundaries = async (): Promise<RiBoundaryData[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/boundaries');

export const convertRiBoundaryToBoundary = (
  boundary: RiBoundaryData
): Boundary => ({
  boundary_id: boundary.id.toString(),
  boundary_type: (boundary.type && boundary.type.title) || 'Other',
  boundary_source_layer: `${boundary.title}_${boundary.id}_ri`,
  format: 'geojson',
  name: boundary.title,
  url: 'n/a',
  source: 'Risk Intelligence',
  description:
    (boundary.type && boundary.type.title) || 'No description available',
  geojson: boundary.geo as GeoJSON.GeoJSON,
  boundary_color: boundary.color.code,
});

export const extractBoundaryTypes = (boundaries: Boundary[]): string[] => {
  const types = [
    ...new Set(boundaries.map((boundary) => boundary.boundary_type)),
  ].sort();

  const reorganisedTypes = types.includes('Other')
    ? types.filter((type) => type !== 'Other').concat('Other')
    : types;
  return reorganisedTypes;
};

export const fetchAndCombineBoundaries = async (): Promise<{
  boundaries: Boundary[];
  boundaryTypes: string[];
}> => {
  let canAccessRiBoundaries = false;
  const { idToken, featureFlags } = store.getState().user;
  const permissionToggles = store.getState().userPermissions.permissions;

  if (idToken) {
    const { canAccessRiBoundaries: canAccessRiBoundariesFromPrivileges } =
      calculatePrivileges({
        idToken,
        userFeatureFlags: featureFlags,
        permissionToggles,
      });
    canAccessRiBoundaries = canAccessRiBoundariesFromPrivileges;
  }
  if (canAccessRiBoundaries) {
    const riBoundariesData: RiBoundaryData[] = await getRiBoundaries();
    const formattedRiBoundaries: Boundary[] = riBoundariesData
      .filter(
        (boundary: RiBoundaryData) =>
          !boundary.type || boundary.type.title !== 'Joint War Committee'
      )
      .map(convertRiBoundaryToBoundary);
    // extract boundary types from RI boundary data and move 'Other' to the end of the array

    const boundariesData = await getBoundaries();
    return {
      boundaries: [...boundariesData, ...formattedRiBoundaries],
      boundaryTypes: extractBoundaryTypes(formattedRiBoundaries),
    };
  }
  const boundariesData = await getBoundaries();
  return {
    boundaries: boundariesData,
    boundaryTypes: [],
  };
};

export const COMBINEDBOUNDARIESURLS = [
  'mapbox://geollectryan.eez_combined',
  'mapbox://geollectryan.jwc_combined',
  'mapbox://geollectryan.ttw_combined',
];
