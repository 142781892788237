import { setSelectedVessels } from '../../../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import store from '../../../store';
import MapHelpers from '../../map.utils';
import MapLayerIcon from '../map-layer-icon';
import MapLayer from '../map-layer.enum';

export function clearFocusRing() {
  MapHelpers.deleteSource(MapLayer.VESSEL_FOCUS_RING);
}

const clearSelectedVessels = () => {
  const { selectedVessels } = store.getState().vesselDossier;

  clearFocusRing();

  if (selectedVessels) {
    MapHelpers.setVesselOpacity(
      selectedVessels.allIds,
      MapLayer.MY_FLEET_VESSELS,
      1
    );
    Object.values(selectedVessels.byId).forEach((selectedVessel) => {
      const icon = MapLayerIcon.PLAIN_VESSEL.ALERT;
      const layer = MapLayer.CURRENTLY_ALERTING_VESSELS;
      MapHelpers.setVesselIcon(selectedVessel, layer, icon);
    });

    store.dispatch(setSelectedVessels(null));
  }
};

export default clearSelectedVessels;
