import { Box, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './loading-panel.scss';

interface LoadingPanelProps {
  size?: number;
  loadingMessage?: string;
}

function LoadingPanel(props: LoadingPanelProps) {
  const { size, loadingMessage } = props;

  return (
    <Box data-testid="loading-panel" className="loading-spinner">
      <Stack alignItems="center" spacing={1}>
        <CircularProgress size={size} sx={{ color: 'background.menuBorder' }} />
        {loadingMessage && <Typography>{loadingMessage}</Typography>}
      </Stack>
    </Box>
  );
}

LoadingPanel.defaultProps = {
  size: 50,
  loadingMessage: null,
};

export default LoadingPanel;
