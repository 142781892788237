export enum EColourScheme {
  DAY = 0,
  DAY_BRIGHT = 1,
  DAY_BLACKBACK = 2,
  DAY_WHITEBACK = 3,
  DUSK = 4,
  NIGHT = 5,
}

export enum EDisplayCategory {
  DISPLAYBASE = 1,
  STANDARD = 2,
  OTHER = 4,
}

export enum EDisplayAioFeatures {
  TEMPORARY_NOTICE = 1,
  PRELIMINARY_NOTICE = 2,
  ENC_PRELIMINARY_NOTICE = 3,
  NO_INFORMATION = 4,
}

export enum EAreaSymbolizationType {
  PLAIN = 1,
  SYMBOLIZED = 2,
}

export enum EDisplayLightSectors {
  OFF = 1,
  ON = 2,
}

export enum ETwoDepthShades {
  OFF = 1,
  ON = 2,
}

export enum EDisplaySafeSoundings {
  OFF = 1,
  ON = 2,
}

export enum ECompassRose {
  OFF = 1,
  ON = 2,
}

export enum EDisplayFrames {
  ON = 1,
  OFF = 2,
  VISIBLE_ONLY = 4,
}

export enum EDisplayFrameText {
  OFF = 0,
  DSNM = 1,
  CSCL = 2,
  BOTH = 3,
}

export enum ERemoveDuplicateText {
  OFF = 1,
  ON = 2,
}

export type AVCSDisplayParam = {
  name: string;
  value: string | number;
};
