import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  getLocationDataDAAS,
  getVesselHistory,
  mapMaritimeAisApiLocationDataToHistoricVesselPoints,
  VesselHistoryResponse,
} from '../api';
import MapLayer, {
  MapGroupLayer,
} from '../map/map-layer-manager/map-layer.enum';
import setVesselFeatures from '../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../map/map.utils';
import { FleetsShipType } from '../maritime-menu-options/fleets-panel/fleets.model';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import AISDataGapsController from '../maritime-menu-options/history-panel/history-ais-data-gaps/ais-data-gaps-controller.utils';
import AISMergedPointsController from '../maritime-menu-options/history-panel/history-ais-merged-points/history-ais-merged-points-controller.utils';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import VesselHistoryController from '../maritime-menu-options/history-panel/vessel-history-controller.utils';
import RfEventController from '../maritime-menu-options/rf-data-panel/rf-event-controller.utils';
import RfTargetController from '../maritime-menu-options/rf-data-panel/rf-target-controller.utils';
import HistoricAreaSearchVesselController from '../maritime-menu-options/tools-panel/historic-area-search/historic-area-search-vessel-controller.utils';
import { setHistoricAreaSearchVesselPoints } from '../maritime-menu-options/tools-panel/historic-area-search/historic-area-search.slice';
import { RfEvent, RfTarget } from '../models/rf-data/rf-data.model';
import {
  MaritimeAisApiLocation,
  MaritimeAisApiLocationData,
  VesselData,
} from '../models/vessels/maritime-ais-api';
import { Vessel, VesselSource } from '../models/vessels/vessel.model';
import {
  clearVesselHistoryData,
  prependHistoricVesselPoints,
  setError,
  setHistoricVesselPoints,
  setLoading,
  setMergedHistoricVesselPoints,
  setVesselHistoryData,
} from '../state/history/history.slice';
import {
  RfDataPanelViewState,
  setRfData,
  setRfTargets,
  setViewState as setRfViewState,
} from '../state/rf-data/rf-data.slice';
import store from '../store';
import TenantId from '../tenant';
import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { IMO_LENGTH, MMSI_LENGTH } from './vessel-constants.utils';

dayjs.extend(utc);

export const buildIdentifiers = (
  input: string[]
): {
  imos: string[];
  mmsis: string[];
} => {
  const seperated = input.reduce(
    (acc, str) => {
      if (str.length === IMO_LENGTH) {
        acc.imos.push(str);
      } else if (str.length === MMSI_LENGTH) {
        acc.mmsis.push(str);
      }
      return acc;
    },
    { imos: [], mmsis: [], callsign: [], shiptype: [] } as {
      imos: string[];
      mmsis: string[];
      callsign: string[];
      shiptype: string[];
    }
  );
  return seperated;
};

export const maritimeAisResponseToVessels = (
  maritimeAis: MaritimeAisApiLocationData
) => {
  const vessels: Vessel[] = [];
  Object.values(maritimeAis.data).forEach((vesselData) => {
    if (vesselData.vessel) {
      vessels.push({
        vessel_id: vesselData.vessel.vesselId,
        name: vesselData.vessel.staticData.name,
        imo: vesselData.vessel.staticData.imo,
        mmsi: vesselData.vessel.staticData.mmsi,
        heading: vesselData.messages[0].heading,
        latitude: vesselData.messages[0].position?.coordinates[1] || 0,
        longitude: vesselData.messages[0].position?.coordinates[0] || 0,
        course: vesselData.messages[0].course,
        callsign: vesselData.vessel.staticData.callsign,
        shiptype: vesselData.vessel.staticData.shipType,
        timestamp: vesselData.messages[0].timestamp,
        speed: vesselData.messages[0].speed,
        source: VesselSource.AIS,
      });
    }
  });
  return vessels;
};

export const renderNearbyVessels = (
  maritimeAisResponse: MaritimeAisApiLocationData | null,
  shipTypeFilters?: string[]
) => {
  if (!maritimeAisResponse) {
    setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  } else {
    let vessels = maritimeAisResponseToVessels(maritimeAisResponse);
    if (shipTypeFilters && shipTypeFilters.length > 0) {
      vessels = vessels.filter(
        (vessel) => !shipTypeFilters.includes(vessel.shiptype ?? 'OTHER')
      );
    }
    setVesselFeatures(MapLayer.NEARBY_VESSELS, vessels);
  }
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
  if (MapHelpers.getLayer(MapLayer.MY_FLEET_SELECTED_AIS_POSITION)) {
    MapHelpers.moveLayer(
      MapLayer.NEARBY_VESSELS,
      MapLayer.MY_FLEET_SELECTED_AIS_POSITION
    );
  } else {
    MapHelpers.moveLayer(MapLayer.NEARBY_VESSELS);
  }
};

const hideLayers = (layers: any[]) => {
  layers.forEach((layer) => {
    MapHelpers.setLayerVisibilityIfExists(layer.id, false);
  });
};

const getLayersToCreate = (visibleTracksIds: string[], transitLayers: any[]) =>
  visibleTracksIds.filter(
    (trackId) => !transitLayers.some((layer) => layer.id.includes(trackId))
  );

const getLayersToToggleOn = (
  visibleTracksIds: string[],
  toggledOffVesselIds: string[],
  transitLayers: any[]
) =>
  transitLayers.filter(
    (layer) =>
      visibleTracksIds.some((trackId) => layer.id.includes(trackId)) &&
      !toggledOffVesselIds.some((vesselId) => layer.id.includes(vesselId))
  );

const getLayersToToggleOff = (
  visibleTracksIds: string[],
  toggledOffVesselIds: string[],
  transitLayers: any[]
) =>
  transitLayers.filter(
    (layer) =>
      !visibleTracksIds.some((trackId) => layer.id.includes(trackId)) ||
      toggledOffVesselIds.some((vesselId) => layer.id.includes(vesselId))
  );

const toggleLayersVisibility = (layers: any[], visibility: boolean) => {
  layers.forEach((layer) => {
    MapHelpers.setLayerVisibilityIfExists(layer.id, visibility);
  });
};

const splitIntoBatches = <T>(array: T[], batchSize: number): T[][] => {
  const batches: T[][] = [];
  for (let i = 0; i < array.length; i += batchSize) {
    batches.push(array.slice(i, i + batchSize));
  }
  return batches;
};

const processBatch = async (
  batch: Vessel[],
  timeRange: { startDate: string | null; endDate: string | null },
  visibleTracksIds: string[]
) => {
  const searchStartDate = dayjs(timeRange.startDate).utc();
  const searchEndDate = dayjs(timeRange.endDate).utc();
  const mmsiList = batch.map((vessel) => String(vessel.mmsi));

  const response = await getLocationDataDAAS(
    searchStartDate.toDate(),
    searchEndDate.toDate(),
    mmsiList,
    [],
    '1h'
  );

  const points: HistoricVesselPoint[] =
    mapMaritimeAisApiLocationDataToHistoricVesselPoints(response);

  batch.forEach((vessel) => {
    if (!visibleTracksIds.includes(vessel.vessel_id)) {
      return; // Skip if vessel_id is not in visibleTracksIds
    }
    const matchingPoints: HistoricVesselPoint[] = points.filter(
      (point) => point.vessel_id === vessel.vessel_id
    );
    const sortedPoints = matchingPoints.sort(
      (a, b) =>
        dayjs(a.timestamp).utc().valueOf() - dayjs(b.timestamp).utc().valueOf()
    );

    const historyPoints: HistoricVesselPoint[] = sortedPoints;
    const inclusivePoints = [
      vessel as unknown as HistoricVesselPoint,
      ...historyPoints,
    ];
    const reSortedPoints = inclusivePoints.sort((a, b) => {
      const dateA = dayjs(a.timestamp ?? 0)
        .utc()
        .valueOf();
      const dateB = dayjs(b.timestamp ?? 0)
        .utc()
        .valueOf();
      return dateA - dateB;
    });

    const uniqueTransitLayerId = `${vessel.vessel_id}-${MapLayer.HISTORIC_VESSELS}-transit`;
    const uniquePositionsLayerId = `${vessel.vessel_id}-${MapLayer.HISTORIC_VESSELS}-transit`;
    HistoricAreaSearchVesselController.layerList.JOURNEYS.addLayer(
      uniqueTransitLayerId,
      reSortedPoints
    );
    store.dispatch(
      setHistoricAreaSearchVesselPoints({
        vesselId: vessel.vessel_id,
        points: reSortedPoints,
      })
    );
    HistoricAreaSearchVesselController.layerList.AIS_POSITIONS.addLayer(
      uniquePositionsLayerId,
      historyPoints
    );
    MapHelpers.moveLayerIfExists(MapLayer.HISTORIC_VESSELS);
  });
};

export const renderHistoricVesselTracks = async (
  vessels: Vessel[],
  visibleTracksIds: string[],
  toggledOffVesselIds: string[],
  timeRange: {
    startDate: string | null;
    endDate: string | null;
  }
) => {
  const allLayers = MapHelpers.getAllLayers();
  const transitLayers = allLayers.filter((layer) =>
    layer.id.includes(MapGroupLayer.HISTORIC_AREA_SEARCH)
  );

  if (vessels.length === 0) {
    hideLayers(transitLayers);
    return;
  }

  const layersToCreate = getLayersToCreate(visibleTracksIds, transitLayers);
  const layersToToggleOn = getLayersToToggleOn(
    visibleTracksIds,
    toggledOffVesselIds,
    transitLayers
  );
  const layersToToggleOff = getLayersToToggleOff(
    visibleTracksIds,
    toggledOffVesselIds,
    transitLayers
  );

  toggleLayersVisibility(layersToToggleOff, false);
  toggleLayersVisibility(layersToToggleOn, true);
  MapHelpers.moveLayerIfExists(MapLayer.HISTORIC_VESSELS);

  if (layersToCreate.length === 0) {
    return;
  }

  const uniqueVessels = vessels.filter((vessel) =>
    layersToCreate.includes(vessel.vessel_id)
  );

  const vesselBatches = splitIntoBatches(uniqueVessels, 25);
  await Promise.all(
    vesselBatches.map((batch) =>
      processBatch(batch, timeRange, visibleTracksIds)
    )
  );
};

export const renderHistoricVessels = async (
  maritimeAisResponse: MaritimeAisApiLocationData | null,
  shipTypeFilters: string[],
  visibleMmsis: number[],
  filteredVessels: MaritimeAisApiLocation[],
  visibleTracksIds: string[],
  timeRange: {
    startDate: string | null;
    endDate: string | null;
  }
) => {
  // If there are no vessels clear the layer, so no vessels are displayed
  // This is to handle the case where the user has filters that exclude all vessels
  if (filteredVessels.length === 0) {
    setVesselFeatures(MapLayer.HISTORIC_VESSELS, []);
    MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, false);
    return;
  }

  // if we have vessels but no location data, clear the layer so no vessels are displayed
  if (!maritimeAisResponse) {
    setVesselFeatures(MapLayer.HISTORIC_VESSELS, []);
    MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, false);
    return;
  }

  let vessels = maritimeAisResponseToVessels(maritimeAisResponse);
  const removedVesselIds = new Set<string>();

  if (filteredVessels.length > 0) {
    const filteredVesselsIds = filteredVessels.map(
      (vessel) => vessel.vessel?.vesselId
    );

    const initialVessels = vessels;
    vessels = vessels.filter((vessel) =>
      filteredVesselsIds.includes(vessel.vessel_id)
    );

    initialVessels
      .filter((vessel) => !filteredVesselsIds.includes(vessel.vessel_id))
      .forEach((vessel) => removedVesselIds.add(vessel.vessel_id));
  }

  if (shipTypeFilters && shipTypeFilters.length > 0) {
    const initialVessels = vessels;
    vessels = vessels.filter(
      (vessel) =>
        !shipTypeFilters.includes(vessel.shiptype ?? FleetsShipType.OTHER)
    );

    initialVessels
      .filter((vessel) =>
        shipTypeFilters.includes(vessel.shiptype ?? FleetsShipType.OTHER)
      )
      .forEach((vessel) => removedVesselIds.add(vessel.vessel_id));
  }

  if (visibleMmsis && visibleMmsis.length > 0) {
    const initialVessels = vessels;
    vessels = vessels.filter(
      (vessel) => !visibleMmsis.includes(Number(vessel.mmsi))
    );

    initialVessels
      .filter((vessel) => visibleMmsis.includes(Number(vessel.mmsi)))
      .forEach((vessel) => removedVesselIds.add(vessel.vessel_id));
  }
  const toggledOffVesselIds = Array.from(removedVesselIds);
  setVesselFeatures(MapLayer.HISTORIC_VESSELS, vessels);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, true);
  renderHistoricVesselTracks(
    vessels,
    visibleTracksIds,
    toggledOffVesselIds,
    timeRange
  );

  if (MapHelpers.getLayer(MapLayer.MY_FLEET_SELECTED_AIS_POSITION)) {
    MapHelpers.moveLayer(
      MapLayer.HISTORIC_VESSELS,
      MapLayer.MY_FLEET_SELECTED_AIS_POSITION
    );
  } else {
    MapHelpers.moveLayer(MapLayer.HISTORIC_VESSELS);
  }
};

export function renderVesselHistory(
  rawVesselPoints: HistoricVesselPoint[],
  userPreferences: UserPreferences,
  { prepend, shouldDisplayOtherVessels } = {
    prepend: true,
    shouldDisplayOtherVessels: false,
  }
) {
  const mergedFeaturePoints =
    AISMergedPointsController.getMergedFeaturePoints(rawVesselPoints);

  const formattedVesselPoints =
    AISMergedPointsController.addMergedFeaturePointData(
      rawVesselPoints,
      mergedFeaturePoints
    );

  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.MY_FLEET_VESSELS,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_VESSELS,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_VESSELS_POLYGON,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.VESSEL_FOCUS_RING,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_ROUTES,
    shouldDisplayOtherVessels
  );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.CURRENTLY_ALERTING_ROUTES_RADIUS,
    shouldDisplayOtherVessels
  );

  store.dispatch(setError(false));
  if (prepend) {
    store.dispatch(prependHistoricVesselPoints(formattedVesselPoints));
  } else {
    store.dispatch(setHistoricVesselPoints(formattedVesselPoints));
  }
  store.dispatch(setMergedHistoricVesselPoints(mergedFeaturePoints));

  VesselHistoryController.init(formattedVesselPoints, userPreferences);

  AISDataGapsController.resetToDefaults();
  AISMergedPointsController.resetToDefaults();
}

export const getRawVesselPoints = (data: any[]) =>
  data.flatMap(
    (vessel: { messages: HistoricVesselPoint[]; vessel: Vessel }) => {
      const rawVessels: HistoricVesselPoint[] = [];
      if (vessel.messages.length > 0 && vessel.vessel) {
        vessel.messages.forEach((message) => {
          const newMessage: HistoricVesselPoint = {
            ...message,
            imo: vessel.vessel.imo,
            name: vessel.vessel.name,
            callsign: vessel.messages[0].callsign,
            shiptype: vessel.messages[0].shiptype,
          };
          rawVessels.push(newMessage);
        });
      }
      return rawVessels;
    }
  );

export const getVesselHistoryData = (
  values: HistoryFormValues,
  tenantId?: TenantId,
  userPreferences?: UserPreferences
) => {
  const identifiers = buildIdentifiers(values.identifiers.split(' '));

  const queryParams = {
    'start-date': values.fromDate,
    'end-date': values.toDate,
    'sample-rate': values.sampleRate,
    mmsis: identifiers.mmsis,
    imos: identifiers.imos,
    tenantId,
  };
  return getVesselHistory(queryParams)
    .then((response: VesselHistoryResponse | VesselData[]) => {
      // response should only be null if we were in websocket mode, which we are not
      const { data } = response as VesselHistoryResponse;
      store.dispatch(
        setVesselHistoryData({
          data,
          formValues: values,
        })
      );

      const rawVesselPoints = getRawVesselPoints(data);
      renderVesselHistory(rawVesselPoints, userPreferences!);
      VesselHistoryController.onVesselHistoryDraw();
    })
    .catch(() => {
      store.dispatch(setError(true));
    })
    .finally(() => {
      store.dispatch(setLoading(false));
    });
};

export const clearVesselHistory = () => {
  store.dispatch(clearVesselHistoryData());
  VesselHistoryController.clearAllHistoryLayers();
};

export const clearNearbyVessels = () => {
  setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
};

export const clearHistoricAreaSearchVessels = () => {
  setVesselFeatures(MapLayer.HISTORIC_VESSELS, []);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.HISTORIC_VESSELS, true);
  HistoricAreaSearchVesselController.clearAllHistoricAreaSearchLayers();
};

export const hasMatchingMMSI = (
  vesselsArray: any,
  currentVessel: any
): boolean =>
  vesselsArray.some(
    (vessel: any) =>
      vessel.mmsi === currentVessel.mmsi &&
      vessel !== currentVessel &&
      vessel.mmsi
  );

export function renderVesselRfData(rfFeatures: RfEvent[]) {
  store.dispatch(setRfData(rfFeatures));
  // by default the rf data is hidden, so do not do any map movements here
  RfEventController.init();
}

export function renderRfTargets(rfTargets: RfTarget[]) {
  store.dispatch(setRfTargets(rfTargets));
  RfTargetController.init();
  store.dispatch(setRfViewState(RfDataPanelViewState.TARGETS));
}
