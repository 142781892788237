export enum UserPreferencesBaseMap {
  DEFAULT = 'Default',
  SATELLITE = 'Satellite',
}

export enum UserRole {
  GLOBAL_ADMIN = 'user_group_admin',
  COMPANY_ADMIN = 'user_group_company_admin',
  USER = 'user_group_user',
}
