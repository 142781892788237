import {
  BooleanFilter,
  BooleanFilterCollection,
  NumberFilter,
} from '../../../models/capella/capella-model';

export const defaultCollectionFilters: BooleanFilter[] = [
  {
    id: 'capella-cphd',
    label: 'CPHD',
    selected: false,
  },
  {
    id: 'capella-gec',
    label: 'GEC',
    selected: false,
  },
  {
    id: 'capella-geo',
    label: 'GEO',
    selected: false,
  },
  {
    id: 'capella-open-data',
    label: 'Open Data',
    selected: false,
  },
  {
    id: 'capella-samples',
    label: 'Samples',
    selected: false,
  },
  {
    id: 'capella-sicd',
    label: 'SICD',
    selected: false,
  },
  {
    id: 'capella-sidd',
    label: 'SIDD',
    selected: false,
  },
  {
    id: 'capella-slc',
    label: 'SLC',
    selected: false,
  },
  {
    id: 'capella-vessel-detection',
    label: 'Vessel Detection',
    selected: false,
  },
];

export const defaultTypeFilters: BooleanFilter[] = [
  {
    id: 'CPHD',
    label: 'CPHD',
    selected: false,
  },
  {
    id: 'GEC',
    label: 'GEC',
    selected: false,
  },
  {
    id: 'GEO',
    label: 'GEO',
    selected: false,
  },
  {
    id: 'SICD',
    label: 'SICD',
    selected: false,
  },
  {
    id: 'SIDD',
    label: 'SIDD',
    selected: false,
  },
  {
    id: 'SLC',
    label: 'SLC',
    selected: false,
  },
  {
    id: 'VS',
    label: 'VS',
    selected: false,
  },
];

export const defaultInstrumentFilters: BooleanFilter[] = [
  {
    id: 'slidingSpotlight',
    label: 'Sliding Spotlight',
    selected: false,
  },
  {
    id: 'spotlight',
    label: 'Spotlight',
    selected: false,
  },
  {
    id: 'stripmap',
    label: 'Stripmap',
    selected: false,
  },
];

export const defaultCollectionTimeFilters: BooleanFilter[] = [
  {
    id: 'day',
    label: 'Day',
    selected: false,
  },
  {
    id: 'capella-night',
    label: 'Night',
    selected: false,
  },
];

export const defaultProductCategoryFilters: BooleanFilter[] = [
  {
    id: 'custom',
    label: 'Custom',
    selected: false,
  },
  {
    id: 'extended',
    label: 'Extended',
    selected: false,
  },
  {
    id: 'standard',
    label: 'Standard',
    selected: false,
  },
];

export const defaultOrbitalPlaneFilters: BooleanFilter[] = [
  {
    id: 'capella-mid-lat-45',
    label: 'Mid-latitude: 45°',
    selected: false,
  },
  {
    id: 'capella-mid-lat-53',
    label: 'Mid-latitude: 53°',
    selected: false,
  },
  {
    id: 'capella-sso',
    label: 'SSO: 97°',
    selected: false,
  },
];

export const defaultOrbitalStateFilters: BooleanFilter[] = [
  {
    id: 'ascending',
    label: 'Ascending',
    selected: false,
  },
  {
    id: 'descending',
    label: 'Descending',
    selected: false,
  },
];

export const defaultObservationDirectionFilters: BooleanFilter[] = [
  {
    id: 'left',
    label: 'Left',
    selected: false,
  },
  {
    id: 'right',
    label: 'Right',
    selected: false,
  },
];

export const defaultPolarizationFilters: BooleanFilter[] = [
  {
    id: 'HH',
    label: 'HH',
    selected: false,
  },
  {
    id: 'VV',
    label: 'VV',
    selected: false,
  },
];

export const defaultBooleanFilters: BooleanFilterCollection[] = [
  {
    id: 'sar:product_type',
    label: 'Product Type',
    filters: defaultTypeFilters,
  },
  {
    id: 'sar:instrument_mode',
    label: 'Instrument Mode',
    filters: defaultInstrumentFilters,
  },
  {
    id: 'locale:time',
    label: 'Collection Time',
    filters: defaultCollectionTimeFilters,
    additionalInfo: 'Day: 0600 - 1800, Night: 1800 - 0600',
  },
  {
    id: 'capella:product_category',
    label: 'Product Category',
    filters: defaultProductCategoryFilters,
  },
  {
    id: 'capella:orbital_plane',
    label: 'Orbital Plane',
    filters: defaultOrbitalPlaneFilters,
  },
  {
    id: 'sat:orbit_state',
    label: 'Orbital State',
    filters: defaultOrbitalStateFilters,
  },
  {
    id: 'sar:observation_direction',
    label: 'Observation Direction',
    filters: defaultObservationDirectionFilters,
  },
  {
    id: 'sar:polarization',
    label: 'Polarization',
    filters: defaultPolarizationFilters,
  },
];

export const defaultSelectedIncidenceAngleFilter: NumberFilter = {
  id: 'view:incidence_angle',
  label: 'Incidence Angle Range',
  min: 5,
  max: 75,
  selectedMin: 5,
  selectedMax: 75,
};

export const defaultSelectedAzimuthResolutionFilter: NumberFilter = {
  id: 'sar:resolution_azimuth',
  label: 'Azimuth Resolution Range',
  min: 0.5,
  max: 11.5,
  selectedMin: 0.5,
  selectedMax: 11.5,
};

export const defaultSelectedSlantRangeFilter: NumberFilter = {
  id: 'sar:resolution_range',
  label: 'Slant Resolution Range',
  min: 0.3,
  max: 10,
  selectedMin: 0.3,
  selectedMax: 10,
};

export const defaultNumberFilters: NumberFilter[] = [
  defaultSelectedIncidenceAngleFilter,
  defaultSelectedAzimuthResolutionFilter,
  defaultSelectedSlantRangeFilter,
];
