/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MyFleetVessel } from '../../maritime-menu-options/my-fleet-panel/myFleetVessels.model';

export type NormalisedMyFleetVessels = {
  byId: Record<string, MyFleetVessel>;
  idsByMmsi: Record<string, string>;
  idsByImo: Record<string, string>;
  allIds: MyFleetVessel['vessel_id'][];
};

export const normaliseVessels = (vessels: MyFleetVessel[]) =>
  vessels.reduce<NormalisedMyFleetVessels>(
    (acc, vessel) => {
      acc.byId[vessel.vessel_id] = vessel;
      if (vessel.mmsi) acc.idsByMmsi[vessel.mmsi.toString()] = vessel.vessel_id;
      if (vessel.imo) acc.idsByImo[vessel.imo.toString()] = vessel.vessel_id;
      acc.allIds.push(vessel.vessel_id);
      return acc;
    },
    {
      byId: {},
      idsByMmsi: {},
      idsByImo: {},
      allIds: [],
    }
  );

interface IMyFleet {
  loading: boolean;
  fleetsVesselsLoading: boolean;
  error: boolean;
  fleetsVesselsError: boolean;
  myFleet: NormalisedMyFleetVessels | null;
  vesselTypeDefaultColouring: boolean;
}

export const INITIAL_MY_FLEET_STATE: IMyFleet = {
  loading: false,
  fleetsVesselsLoading: false,
  error: false,
  fleetsVesselsError: false,
  myFleet: null,
  vesselTypeDefaultColouring: false,
};

const MyFleetSlice = createSlice({
  name: 'myFleet',
  initialState: INITIAL_MY_FLEET_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setMyFleet: (state, action: PayloadAction<MyFleetVessel[] | null>) => {
      state.myFleet = action.payload ? normaliseVessels(action.payload) : null;
      state.loading = false;
      state.error = false;
    },
    setVesselTypeDefaultColouring: (state, action: PayloadAction<boolean>) => {
      state.vesselTypeDefaultColouring = action.payload;
    },
    updateVesselInMyFleet: (state, action: PayloadAction<MyFleetVessel>) => {
      if (state.myFleet) {
        state.myFleet.byId[action.payload.vessel_id] = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  setMyFleet,
  updateVesselInMyFleet,
  setVesselTypeDefaultColouring,
} = MyFleetSlice.actions;

export default MyFleetSlice.reducer;
