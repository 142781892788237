/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import { RfEventFilters } from '../../models/rf-data/rf-data.model';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import {
  FORMATTED_ONE_MONTH_AGO,
  FORMATTED_TODAY,
} from '../history-panel/history-panel.slice';
import { RfDataFormValues } from './rf-data-form/rf-data-form-validators';

interface IRfDataPanelState {
  formValues: RfDataFormValues;
  filters: RfEventFilters;
}

export const defaultRfEventFilters: RfEventFilters = {
  startDate: DateTimeHelpers.dateToIsoDate(subMonths(new Date(), 1)),
  endDate: DateTimeHelpers.dateToIsoDate(new Date()),
  targets: [],
};

export const INITIAL_RF_DATA_PANEL_STATE: IRfDataPanelState = {
  formValues: {
    identifiers: '',
    toDate: FORMATTED_TODAY,
    fromDate: FORMATTED_ONE_MONTH_AGO, // TODO store these somewhere that's not the history panel
  },
  filters: defaultRfEventFilters,
};

export const RfDataPanelSlice = createSlice({
  name: 'rfDataPanel',
  initialState: INITIAL_RF_DATA_PANEL_STATE,
  reducers: {
    setRfFormValues: (
      state,
      action: PayloadAction<Partial<RfDataFormValues> | null>
    ) => {
      // Only updates filters explicitly mentioned in action
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      };
    },
    setRfEventFilters: (
      state,
      action: PayloadAction<Partial<RfEventFilters> | null>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

export const { setRfFormValues, setRfEventFilters } = RfDataPanelSlice.actions;

export default RfDataPanelSlice.reducer;
