/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertingVesselsFilters = {
  hiddenAlerts: string[];
  open: boolean;
};

type AlertingVesselsPanelState = {
  alertingVesselsFilterValue: string;
  filters: AlertingVesselsFilters;
};

export const INITIAL_ALERTING_VESSELS_PANEL_STATE: AlertingVesselsPanelState = {
  alertingVesselsFilterValue: '',
  filters: {
    hiddenAlerts: [],
    open: false,
  },
};

const AlertingVesselsPanelSlice = createSlice({
  name: 'alertingVesselsPanel',
  initialState: INITIAL_ALERTING_VESSELS_PANEL_STATE,
  reducers: {
    setAlertingVesselsFilterValue: (state, action: PayloadAction<string>) => {
      state.alertingVesselsFilterValue = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function

export const { setAlertingVesselsFilterValue, setFilters } =
  AlertingVesselsPanelSlice.actions;

export default AlertingVesselsPanelSlice.reducer;
