export const colourPalette = {
  // Roke's colour palette
  primary: {
    p50: '#8E90A9',
    p100: '#6F7A96',
    p200: '#57617F',
    p300: '#3C496B',
    p400: '#2A395C',
    p500: '#12284C',
    p600: '#011C3D',
    p700: '#000829',
    p800: '#000017',
    p900: '#000000',
  },
  secondary: {
    s50: '#F6FFF2',
    s100: '#DBFED1',
    s200: '#BAEBAA',
    s300: '#99DB83',
    s400: '#83CF67',
    s500: '#6CC34A',
    s600: '#62B442',
    s700: '#54A037',
    s800: '#47902C',
    s900: '#35711E',
  },
  tertiary: {
    t50: '#B1C6ED',
    t100: '#8BB0D7',
    t200: '#7195BF',
    t300: '#507CA9',
    t400: '#396A9A',
    t500: '#165788',
    t600: '#004977',
    t700: '#003760',
    t800: '#00254A',
    t900: '#001232',
  },
  error: {
    e50: '#FAFFFF',
    e100: '#FAE2E6',
    e200: '#EDAEB2',
    e300: '#E7888F',
    e400: '#F86A71',
    e500: '#FF595A',
    e600: '#EE5157',
    e700: '#DB464F',
    e800: '#CF4148',
    e900: '#C2373B',
  },
  warning: {
    w50: '#EDEAE7',
    w100: '#F2D8BC',
    w200: '#F4C38C',
    w300: '#F6AC5B',
    w400: '#F69B3C',
    w500: '#F88D2A',
    w600: '#F38227',
    w700: '#ED7327',
    w800: '#E56425',
    w900: '#DB4A22',
  },
  highlight: {
    h50: '#FFFFFF',
    h100: '#DEFFFF',
    h200: '#BDF3FB',
    h300: '#98D8E1',
    h400: '#7EC5D0',
    h500: '#61B0BC',
    h600: '#549FAA',
    h700: '#468A93',
    h800: '#38747E',
    h900: '#275D65',
  },
  neutral1: {
    n50: '#FFFFFF',
    n100: '#FFFFFF',
    n200: '#FFFFFF',
    n300: '#FFFFFF',
    n400: '#F9F9F9',
    n500: '#DADADA',
    n600: '#AEAEAE',
    n700: '#999999',
    n800: '#777777',
    n900: '#525252',
  },
  neutral2: {
    n50: '#F3F3F3',
    n100: '#EDEDED',
    n200: '#E8E8E8',
    n300: '#DADADA',
    n400: '#B5B5B5',
    n500: '#989898',
    n600: '#6F6F6F',
    n700: '#5B5B5B',
    n800: '#3D3D3D',
    n900: '#1D1D1D',
  },
};

export default colourPalette;
