import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';

export function getUniqueHistoricVessels(points: HistoricVesselPoint[]) {
  // Get the most recent historicVesselPoint for each unique vessel in points
  const vesselMap = new Map<string, HistoricVesselPoint>();
  points.forEach((point) => {
    const existingPoint = vesselMap.get(point.vessel_id);
    if (
      !existingPoint ||
      new Date(point.timestamp) > new Date(existingPoint.timestamp)
    ) {
      vesselMap.set(point.vessel_id, point);
    }
  });
  return Array.from(vesselMap.values());
}

export function getUniqueHistoricMmsis(points: HistoricVesselPoint[]) {
  return Array.from(new Set(points.map((point) => point.mmsi)));
}
