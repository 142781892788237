import { MaritimeArea } from '../../../models/risk-intelligence/risk_intelligence.model';
import MapHelpers from '../../map.utils';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerPaint from '../map-layer-paint';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';

function setMaritimeAreasFeatures(
  layerId: MapLayer | string,
  areas: MaritimeArea[]
) {
  areas.forEach((area) => {
    const sourceId = `${layerId}_${area.id}`;

    if (!MapHelpers.getSource(sourceId)) {
      MapHelpers.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: area.geo,
          properties: {
            id: area.id,
            title: area.title,
          },
        },
      });
    }

    if (!MapHelpers.getLayer(sourceId)) {
      MapLayerManager.AddLayerinGroups(
        {
          id: sourceId,
          type: 'fill',
          source: sourceId,
          layout: {
            visibility: MapLayerVisibility.VISIBLE,
          },
          paint: MapLayerPaint.RI_MARITIME_AREAS_SHAPE_DEFAULT_PAINT,
        },
        [MapGroupLayer.RI_MARITIME_AREAS]
      );
    }

    MapHelpers.moveLayerIfExists(sourceId, MapLayer.RI_MARITIME_AREAS);
  });
}

export default setMaritimeAreasFeatures;
