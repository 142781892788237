/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotePadState {
  notePadOpen: boolean;
  notePadText: string;
}

export const INITIAL_NOTEPAD_STATE: NotePadState = {
  notePadOpen: false,
  notePadText: '',
};

const NotePadSlice = createSlice({
  name: 'notePad',
  initialState: INITIAL_NOTEPAD_STATE,
  reducers: {
    setNotePadOpen: (state, action: PayloadAction<boolean>) => {
      state.notePadOpen = action.payload;
    },
    setNotePadText: (state, action: PayloadAction<string>) => {
      state.notePadText = action.payload;
    },
  },
});

export const { setNotePadOpen, setNotePadText } = NotePadSlice.actions;

export default NotePadSlice.reducer;
