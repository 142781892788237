import { Company, PermissionToggles } from '../../admin/admin-page/admin.slice';
import { kebabify } from '../../utils/text-formatting.utils';
import { wrapRequest } from '../base';

export const getCompany = async (companyIdOrName: string): Promise<Company> => {
  const result = await wrapRequest<Company[]>('get', 'geonius', `/companies`);
  if (result.length === 0) {
    throw new Error('Company not found');
  }
  const normalisedCompanyName = kebabify(companyIdOrName);
  // can search by company name or id
  const company = result.find(
    (c) =>
      c.company_id === companyIdOrName ||
      kebabify(c.company_name) === normalisedCompanyName
  );
  if (!company) {
    throw new Error('Company not found');
  }
  return company;
};

export const getCompanies = async (): Promise<Company[]> =>
  wrapRequest('get', 'geonius', `/companies`);

export const postCompany = async (
  companyName: string,
  tenantId: string,
  seatCount: number = 1000
): Promise<'Successfully created Company'> =>
  wrapRequest('post', 'geonius', `/companies`, {
    body: {
      data: {
        company_name: companyName,
        tenant_id: tenantId,
        seat_count: seatCount,
      },
    },
  });

export const deleteCompanies = async (
  companyIds: string[]
): Promise<'Successfully deleted company or companies'> =>
  wrapRequest('del', 'geonius', `/companies`, {
    body: {
      data: {
        company_ids: companyIds,
      },
    },
  });

export const updateSeatCount = async (
  companyId: string,
  newSeatCount: number
): Promise<'Successfully patched company license'> => {
  const testCompany = await getCompany(companyId);

  const newLicense = testCompany!.feature_licenses;
  newLicense!.seats.total = newSeatCount;

  const newLicenseCopy = JSON.parse(JSON.stringify(newLicense));

  // Need to remove the "available" key as the lambda does not want it
  Object.keys(newLicenseCopy.licenses).forEach((license) => {
    delete newLicenseCopy.licenses[license].available;
    delete newLicenseCopy.licenses[license].total;
    // Matching the sub-licenses (AVCS/Fleet etc.) to new seat-count
    newLicenseCopy.licenses[license] = newSeatCount;
  });

  return wrapRequest('patch', 'geonius', `/companies/licenses`, {
    body: {
      company_id: companyId,
      // Need to match the data, remove available seats from dict
      feature_licenses: {
        seats: newSeatCount,
        licenses: newLicenseCopy.licenses,
      },
    },
  });
};

export const updateCompanyPermissionToggles = async (
  companyId: string,
  permissionToggles: PermissionToggles
): Promise<'Successfully updated permission toggles.'> =>
  wrapRequest('put', 'geonius', '/companies/permission_toggles', {
    body: {
      company_id: companyId,
      permission_toggles: permissionToggles,
    },
  });
