import { getUniqueHistoricVessels } from '../../../map/map-layer-manager/vessel-utils/get-unique-historic-vessels';
import MapHelpers from '../../../map/map.utils';
import { Vessel } from '../../../models/vessels/vessel.model';
import {
  MergedHistoricVesselPoint,
  setSelectedMergedPointIndex,
} from '../../../state/history/history.slice';
import store from '../../../store';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { HistoricVesselPoint } from '../historic-vessel-point.model';
import {
  INITIAL_HISTORY_PANEL_STATE,
  setMergedPointsMinimumCount,
  setMergedPointsMinimumGapLength,
} from '../history-panel.slice';
import {
  AISMergedPointsPopup,
  hideAISMergedPointsPopup,
  showAISMergedPointsPopup,
} from './history-ais-merged-points-popup';

namespace AISMergedPointsController {
  export const resetToDefaults = () => {
    store.dispatch(
      setMergedPointsMinimumCount(
        INITIAL_HISTORY_PANEL_STATE.mergedPointsMinimumCount
      )
    );
    store.dispatch(
      setMergedPointsMinimumGapLength(
        INITIAL_HISTORY_PANEL_STATE.mergedPointsMinimumGapLength
      )
    );
  };

  export const clearSelectedFeature = () => {
    store.dispatch(setSelectedMergedPointIndex(null));
  };

  export const addPopupEventListeners = () => {
    clearSelectedFeature();
  };

  export const onFeatureSelect = (point: MergedHistoricVesselPoint) => {
    // mapbox issue, this is the closest it will zoom before the points go offscreen
    MapHelpers.zoomToFeatureCollection(
      GeoHelper.createGeoJSON(point.features),
      { zoom: 19 }
    );
    showAISMergedPointsPopup(point);
  };

  export const getMergedPointByIndex = (index: number) =>
    store
      .getState()
      .history.mergedHistoricVesselPoints.find(
        (point) => point.index === index
      );

  export const onAISPositionsLayerVisibilityChange = (layerId: string) => {
    const { selectedMergedPointIndex } = store.getState().history;

    // remove the Pop up Close Event Listener
    // prevent feature being de-selected once layer visibility changes
    AISMergedPointsPopup.off('close', addPopupEventListeners);

    if (!selectedMergedPointIndex) {
      return;
    }

    const feature = getMergedPointByIndex(selectedMergedPointIndex);
    const isLayerVisible = MapHelpers.isLayerVisible(layerId);

    if (isLayerVisible && feature) {
      showAISMergedPointsPopup(feature);
    } else {
      hideAISMergedPointsPopup();
    }
  };

  export const getMergedFeaturePoints = (points: HistoricVesselPoint[]) => {
    const uniqueVessels = getUniqueHistoricVessels(points);
    const mergedPoints: MergedHistoricVesselPoint[] = [];

    uniqueVessels.forEach((vessel: Vessel) => {
      const filteredPoints = points.filter(
        (point) => point.vessel_id === vessel.vessel_id
      );

      const { mmsi, imo } = filteredPoints[0];

      const mergedFeatures = GeoHelper.mergeGeoJSONFeatures(
        GeoHelper.vesselsToGeoJSONFeatures(filteredPoints)
      );

      mergedFeatures.forEach((featureGroup) => {
        const dateTimestamps = featureGroup.map((feature) =>
          Date.parse(String(feature.properties?.timestamp))
        );
        const startTimestamp = Math.min.apply(null, dateTimestamps);
        const endTimestamp = Math.max.apply(null, dateTimestamps);

        mergedPoints.push({
          startTimestamp,
          endTimestamp,
          unique_vessel_identifier: vessel.vessel_id,
          mmsi,
          imo,
          features: featureGroup as GeoJSON.Feature<
            GeoJSON.Point,
            HistoricVesselPoint
          >[],
          index: mergedPoints.length,
        });
      });
    });

    return mergedPoints;
  };

  export const addMergedFeaturePointData = (
    points: HistoricVesselPoint[],
    mergedFeaturePoints: MergedHistoricVesselPoint[]
  ) => {
    const updatedFeaturePoints = [...points];

    mergedFeaturePoints.forEach((group) => {
      const { features, startTimestamp, endTimestamp, index } = group;
      features.forEach((feature) => {
        const searchIndex = updatedFeaturePoints.findIndex(
          (point) => point.id === feature.properties?.id
        );

        if (searchIndex > -1) {
          updatedFeaturePoints[searchIndex] = {
            ...updatedFeaturePoints[searchIndex],
            startTimestamp,
            endTimestamp,
            pointCount: features.length,
            index,
          };
        }
      });
    });

    return updatedFeaturePoints;
  };

  export const init = () => {
    // register the event for de-selecting a feature only when popup is open
    // this should prevent duplicates/unnecessary popup events being called
    AISMergedPointsPopup.on('open', () => {
      AISMergedPointsPopup.once('close', addPopupEventListeners);
    });
  };
}

export default AISMergedPointsController;
