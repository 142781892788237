import { UserPreferences } from '../../../user-settings/user-preferences/user-preferences.slice';
import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addAlertingVesselLayerToMap from './add-alerting-vessel-layer';
import addHistoricVesselLayerToMap from './add-historic-vessel-layer';
import addNearbyVesselLayerToMap from './add-nearby-vessel-layer';
import addPlainVesselLayerToMap from './add-plain-vessel-layer';
import VesselsController from './vessel-controller.utils';

interface Metadata {
  groupLayers?: string[];
}

function addVesselsLayer(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata?: Metadata,
  userPreferences?: UserPreferences
) {
  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layer);

    if (layer === MapLayer.CURRENTLY_ALERTING_VESSELS) {
      addAlertingVesselLayerToMap(layer, visibility, metadata, userPreferences);
    } else if (layer === MapLayer.NEARBY_VESSELS) {
      addNearbyVesselLayerToMap(layer, visibility, metadata, userPreferences);
    } else if (layer === MapLayer.HISTORIC_VESSELS) {
      addHistoricVesselLayerToMap(layer, visibility, metadata, userPreferences);
    } else {
      addPlainVesselLayerToMap(layer, visibility, metadata, userPreferences);
    }
    MapHelpers.addMapEventListener('mouseenter', layer, (e) =>
      VesselsController.layerEvents.onMouseEnter(e)
    );
    MapHelpers.addMapEventListener('mouseleave', layer, (e) =>
      VesselsController.layerEvents.onMouseLeave(e)
    );
    resolve(MapHelpers);
  });
}

export default addVesselsLayer;
