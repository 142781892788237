/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';

import {
  applyPortFilter,
  applyVesselsFilter,
  extractPortThreatTypesFromFilter,
  fetchAndCombineBoundaries,
  getIncidents,
  getMaritimeAreas,
  getMyFleetData,
  getPorts,
} from '../api';

import {
  DateRangeMutators,
  SetDateIntervalStrings,
} from '../common-components/date-range-modal/date-range-presets';
import { useAppDispatch } from '../hooks';
import { Privileges } from '../hooks/access-control/privileges';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import setMaritimeAreasFeatures from '../map/map-layer-manager/maritime-areas-utils/set-maritime-areas-features';
import setPortFeatures from '../map/map-layer-manager/port-utils/set-port-features';
import setVesselFeatures from '../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../map/map.utils';
import { updateDrawings } from '../maritime-menu-options/areas-panel/drawings-panel.utils';
import BoundariesController from '../maritime-menu-options/boundaries-panel/boundaries-controller.utils';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import { setHistoryFormValues } from '../maritime-menu-options/history-panel/history-panel.slice';
import {
  defaultIncidentFilters,
  setIncidentFilters,
} from '../maritime-menu-options/incidents-panel/incident-panel.slice';
import { IncidentFilters } from '../maritime-menu-options/incidents-panel/incident.model';
import {
  DEFAULT_FLEET_FILTERS,
  FleetFilters,
  setFleetFilters,
} from '../maritime-menu-options/my-fleet-panel/myFleet-panel.slice';
import updateRoutes from '../maritime-menu-options/routes-panel/routes-panel.utils';
import {
  DEFAULT_PORT_FILTERS,
  PortsFilters,
  getDefaultPortFilterForTenant,
  setPortsFilters,
} from '../maritime-menu-options/world-ports-panel/world-ports-panel.slice';
import { setBoundaries } from '../state/boundaries/boundaries.slice';
import { setLoading as setVesselHistoryLoading } from '../state/history/history.slice';
import { setIncidents } from '../state/incidents/incidents.slice';
import { setAreas } from '../state/ri-maritime-areas/ri-maritime-areas.slice';
import { setMyFleet } from '../state/vessels/vessels.slice';
import store from '../store';
import TenantId from '../tenant';
import {
  setApplySetupBarError,
  setApplySetupBarNumber,
} from '../user-settings/apply-setup-bar/apply-setup-bar.slice';
import {
  DEFAULT_USER_PREFERENCES,
  UserPreferences,
} from '../user-settings/user-preferences/user-preferences.slice';
import { UserRole } from './user.enum';
import { getVesselHistoryData } from './vessels.utils';
// user is admin if either super admin or company admin
export const isAdmin = () =>
  [UserRole.GLOBAL_ADMIN, UserRole.COMPANY_ADMIN].some((role) =>
    store.getState().user.idToken?.groups?.includes(role)
  );
export const isGlobalAdmin = () =>
  store.getState().user.idToken?.groups?.includes(UserRole.GLOBAL_ADMIN);

export const loadBoundariesSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  visibleLayers: string[] = []
) => {
  if (!visibleLayers.length) {
    return;
  }

  // 1. Fetch and populate boundaries metadata:
  const boundariesMetaData =
    store.getState().boundaries.boundaries ??
    (await fetchAndCombineBoundaries()).boundaries;

  // 2. filter boundaries metadata to isolate those that are visible
  if (!boundariesMetaData) {
    return;
  }
  const visibleBoundariesMetaData = (boundariesMetaData ?? []).filter(
    (boundary) => visibleLayers.includes(boundary.boundary_source_layer)
  );

  // 3. add only the visible boundaries to the map.
  const tenantId = store.getState().user.idToken?.tenantId;
  await BoundariesController.addBoundariesToMap(
    visibleBoundariesMetaData,
    tenantId,
    {
      createVisible: true,
    }
  );

  dispatch(setBoundaries(boundariesMetaData));
};

export const loadHistoryPanelSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  formValues: HistoryFormValues,
  disabled: boolean,
  userPreferences: UserPreferences
): Promise<void> => {
  const initialFormValues =
    !disabled && formValues && formValues.identifiers
      ? formValues
      : DEFAULT_USER_PREFERENCES.historyPanel!.formValues;

  dispatch(setHistoryFormValues(initialFormValues));

  if (disabled || initialFormValues.identifiers.length === 0) {
    // don't load if no layers to display
    return;
  }

  const { idToken } = store.getState().user;
  dispatch(setVesselHistoryLoading(true));

  try {
    await getVesselHistoryData(formValues, idToken?.tenantId, userPreferences);
  } finally {
    dispatch(setVesselHistoryLoading(false));
  }
};

export const loadAlertsPanelSetup = async (
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    // eslint-disable-next-line no-useless-return
    return;
  }
  // TODO implement rest of logic for alerts panel
};

export const loadRIMaritimeAreasPanelSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const areas =
    store.getState().riMaritimeAreas.areas ?? (await getMaritimeAreas());
  dispatch(setAreas(areas));
  if (areas) {
    setMaritimeAreasFeatures(MapLayer.RI_MARITIME_AREAS, areas);
  }
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export const loadDrawingsSetup = async (visibleLayers: string[] = []) => {
  if (visibleLayers.length === 0) {
    return;
  }

  await updateDrawings(store.getState().user.idToken?.sub!);
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export const loadRoutesSetup = async (
  visibleLayers: string[] = []
): Promise<void> => {
  if (!visibleLayers.length) {
    return;
  }

  await updateRoutes();
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export const loadGlobalAisSetup = async (
  privileges: Privileges,
  disabled: boolean | undefined,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || !visibleLayers.length) {
    return;
  }

  const { canAccessGlobalAis } = privileges;

  if (canAccessGlobalAis) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }
};

export function incidentFilterToApply(filters: IncidentFilters | undefined) {
  const updatedFilters: IncidentFilters = {
    ...defaultIncidentFilters,
    ...filters,
  };
  if (filters) {
    const { datePreset } = filters;
    if (
      datePreset &&
      datePreset.length > 0 &&
      SetDateIntervalStrings.includes(datePreset)
    ) {
      // Any preset dates should be from the current day not the date the preference was set
      const startAndEndDays: Dayjs[] = DateRangeMutators[datePreset]();
      updatedFilters.startDate = startAndEndDays[0].toISOString().slice(0, 10);
      updatedFilters.endDate = startAndEndDays[1].toISOString().slice(0, 10);
    }
    if (!updatedFilters.startDate) {
      updatedFilters.startDate = defaultIncidentFilters.startDate;
    }
    if (!updatedFilters.endDate) {
      updatedFilters.endDate = defaultIncidentFilters.endDate;
    }

    return updatedFilters;
  }
  return defaultIncidentFilters;
}

export const loadIncidentsSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  filters?: IncidentFilters,
  disabled: boolean = true,
  visibleLayers: string[] = []
) => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const filterToApply = incidentFilterToApply(filters);

  dispatch(setIncidentFilters(filterToApply));

  const incidentsResponse = await getIncidents({ filters: filterToApply });
  dispatch(setIncidents(incidentsResponse));

  if (visibleLayers.length) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }
};

export const loadWorldPortsPanelSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  filters: PortsFilters = DEFAULT_PORT_FILTERS,
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const { idToken } = store.getState().user;

  const filterToApply =
    filters ?? getDefaultPortFilterForTenant(idToken?.tenantId);
  dispatch(setPortsFilters(filterToApply));

  if (visibleLayers.length) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }

  const ports = await getPorts(
    idToken,
    extractPortThreatTypesFromFilter(filterToApply)
  );

  setPortFeatures(MapLayer.PORTS, applyPortFilter(ports, filterToApply));
};

export const loadMyFleetPanelSetup = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  privileges: Privileges,
  filters: FleetFilters | undefined,
  disabled: boolean | undefined,
  visibleLayers: MapLayer[] | undefined
): Promise<void> => {
  if (disabled || visibleLayers?.length === 0) {
    return;
  }

  const { idToken } = store.getState().user;

  const filterToApply = filters ?? DEFAULT_FLEET_FILTERS;
  dispatch(setFleetFilters(filterToApply));

  if (visibleLayers) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }

  if (idToken) {
    const {
      canAccessMyFleetV1: canAccessFleetV1,
      canAccessMyFleetV2: canAccessFleetsV2,
    } = privileges;

    const vessels = await getMyFleetData(
      idToken.companyId,
      idToken.tenantId,
      canAccessFleetV1.ais || canAccessFleetsV2,
      canAccessFleetV1.ri
    );

    dispatch(setMyFleet(vessels));
    setVesselFeatures(
      MapLayer.MY_FLEET_VESSELS,
      applyVesselsFilter(vessels, filterToApply)
    );
    MapHelpers.moveLayer(MapLayer.MY_FLEET_VESSELS);
  }
};

const loadMapSetupActions: Partial<
  Record<keyof UserPreferences, Promise<void> | any>
> = {
  alertsPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) =>
    loadAlertsPanelSetup(
      preferences.alertsPanel?.disabled,
      preferences.alertsPanel?.layers
    ),
  boundariesPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    if (privileges.canAccessBoundaries) {
      loadBoundariesSetup(dispatch, preferences.boundariesPanel?.layers);
    }
  },
  drawingsPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    if (privileges.canAccessDrawings) {
      loadDrawingsSetup(preferences.drawingsPanel?.layers);
    }
  },
  routesPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    if (privileges.canAccessRoutes) {
      loadRoutesSetup(preferences.routesPanel?.layers);
    }
  },
  incidentsPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) =>
    loadIncidentsSetup(
      dispatch,
      preferences.incidentsPanel?.filters,
      preferences.incidentsPanel?.disabled,
      preferences.incidentsPanel?.layers
    ),
  worldPortsPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) =>
    loadWorldPortsPanelSetup(
      dispatch,
      preferences.worldPortsPanel?.filters,
      preferences.worldPortsPanel?.disabled,
      preferences.worldPortsPanel?.layers
    ),
  historyPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    if (privileges.canAccessHistory) {
      loadHistoryPanelSetup(
        dispatch,
        preferences.historyPanel?.formValues as HistoryFormValues,
        preferences.historyPanel?.disabled as boolean,
        preferences
      );
    }
  },
  RIMaritimeAreasPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    if (privileges.canAccessRiMaritimeAreas) {
      loadRIMaritimeAreasPanelSetup(
        dispatch,
        preferences.RIMaritimeAreasPanel?.disabled,
        preferences.RIMaritimeAreasPanel?.layers
      );
    }
  },
  myFleetPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) =>
    loadMyFleetPanelSetup(
      dispatch,
      privileges,
      preferences.myFleetPanel?.filters,
      preferences.myFleetPanel?.disabled,
      preferences.myFleetPanel?.layers
    ),
  globalAisPanel: (
    preferences: UserPreferences,
    dispatch: ReturnType<typeof useAppDispatch>,
    privileges: Privileges
  ) => {
    loadGlobalAisSetup(
      privileges,
      preferences.globalAisPanel?.disabled,
      preferences.globalAisPanel?.layers
    );
  },
};

export const setDefaultFiltersForUser = (tenantId: TenantId) => {
  store.dispatch(setPortsFilters(getDefaultPortFilterForTenant(tenantId)));
};

export const loadMapSavedSetup = async (
  preferences: UserPreferences,
  dispatch: ReturnType<typeof useAppDispatch>,
  privileges: Privileges
) => {
  const actionKeys = Object.keys(loadMapSetupActions);
  // eslint-disable-next-line no-restricted-syntax
  for (const action of actionKeys) {
    dispatch(
      setApplySetupBarNumber(actionKeys.length - actionKeys.indexOf(action))
    );

    const preference = preferences?.[action as keyof UserPreferences];

    if (!preference) {
      // eslint-disable-next-line no-continue
      continue;
    }

    try {
      // eslint-disable-next-line no-await-in-loop
      await loadMapSetupActions?.[action as keyof UserPreferences]?.(
        preferences,
        dispatch,
        privileges
      );
    } catch (_error) {
      dispatch(setApplySetupBarError(true));
      break;
    }
  }
  dispatch(setApplySetupBarNumber(0));
};
