import { ExpandedId } from '../risk-intelligence/risk_intelligence.model';

export interface DrawingInfoResponse {
  company_id: string;
  description: string;
  region_id: string;
  region_name: string;
  date_created: string;
  owner_id: string;
  colour_code?: string | undefined;
  opacity?: number | undefined;
}

export interface DrawingDataResponse
  extends Omit<DrawingInfoResponse, 'description'> {
  region_data: GeoJSON.Feature;
}

export interface Drawing extends DrawingInfoResponse, DrawingDataResponse {}

export interface DrawingData {
  [id: string]: GeoJSON.Feature;
}

export interface DrawingSubmit {
  opacity?: number | undefined;
  colour_code?: string | undefined;
  region_id?: string;
  region_name: string;
  region_data: GeoJSON.Feature;
  company_id: string;
  owner_id: string | undefined;
  cognito_id: string | undefined;
  description: string;
}

export interface DrawingUpdate extends DrawingSubmit {
  region_id: string;
}

export interface DrawingDelete {
  region_id: string;
  owner_id: string;
  company_id: string;
}

export type DrawingCircleFeature = GeoJSON.Feature<
  GeoJSON.Polygon,
  { isCircle: true; center: number[]; radiusInNm?: number }
>;

export const isDrawingCircleFeature = (
  feature: GeoJSON.Feature
): feature is DrawingCircleFeature =>
  (feature as DrawingCircleFeature)?.properties?.isCircle;

export enum DrawingShape {
  Polygon = 'Polygon',
  Circle = 'Circle',
  Line = 'Line',
  Point = 'Point',
}

export interface CreateDrawingValues {
  common: {
    drawingId?: string; // if drawingId is defined, we're editing an existing drawing
    name: string;
    description: string;
    hexCodeAlpha: string;
    company: string;
    shape: DrawingShape;
    edited: boolean;
  };
  [DrawingShape.Circle]?: {
    centrePointInputLat: string;
    centrePointInputLong: string;
    radiusInNauticalMilesInput: string;
  };
  [DrawingShape.Polygon]?: {
    coordinates: [string, string][];
  };
  [DrawingShape.Line]?: {
    coordinates: [string, string][];
  };
  [DrawingShape.Point]?: {
    inputLat: string;
    inputLong: string;
  };
}

export type PartialCreateDrawingValues = Partial<{
  common: Partial<CreateDrawingValues['common'] & { shapeId: string }>;
  [DrawingShape.Circle]?: Partial<CreateDrawingValues['Circle']>;
  [DrawingShape.Polygon]?: Partial<CreateDrawingValues['Polygon']>;
  [DrawingShape.Line]?: Partial<CreateDrawingValues['Line']>;
  [DrawingShape.Point]?: Partial<CreateDrawingValues['Point']>;
}>;

export interface DrawingFilters {
  startDate: string | null;
  endDate: string | null;
  ownerTypes: OwnerType[];
  open: boolean;
}

export interface DrawingOptions {
  drawingColour: string;
  drawingOpacity: number;
  drawingHexAlpha: string;
}

export type OwnerType = ExpandedId;

export enum DrawingType {
  Personal = 'Personal Drawing',
  Company = 'Company Drawing',
}
