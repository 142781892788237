/**
 * Defines temporary / short-lived toggles used to control whether an in-development feature
 * is available in the environment.
 * The values for these are currently expected to come from environment variables configured in
 * the build environment. These can be defined in
 * - local development: local environment/.env files
 * - deployment builds: the amplify configuration (amplify.yml)
 *
 * TODO: One day we'd like these to be more dynamic and sourced from an API instead, so that
 * code changes & releases are not needed to enable a feature once ready & tested.
 */
export type ReleaseToggles = {
  rfDataEnabled: boolean;
  unseenLabsEnabled: boolean;
  globalAisEnabled: boolean;
  mergeStationaryPointsEnabled: boolean;
  avcsEnabled: boolean;
  permissionsTogglesEnabled: boolean;
  adminPermissionsTogglesEnabled: boolean;
  /**
   * Release toggle to specify whether the new Fleets V2 feature is enabled.
   */
  fleetV2Enabled: boolean;
  /**
   * This release toggle is only used while parallel development is ongoing, to allow Fleets V1 to be available alongside Fleets V2.
   *
   * Once Fleets V2 is fully released, this toggle should be removed.
   *
   * This toggle should not be enabled in the production environment, where we only want to offer
   * Fleets V1 or Fleets V2 exclusively to each other.
   */
  fleetV1AvailableWithFleetV2: boolean;
  /**
   * Release toggle to specify whether the map/geospatial layer handler is enabled.
   */
  mapLayerHandlerEnabled: boolean;
  /**
   * Release toggle to specify whether the Fleets V2 Phase 2 feature is enabled.
   */
  fleetV2Phase2Enabled: boolean;
};

export const getReleaseToggles = (): ReleaseToggles => ({
  rfDataEnabled: process.env.REACT_APP_RF_DATA_ENABLED === 'true',
  unseenLabsEnabled: process.env.REACT_APP_UNSEENLABS_DATA_ENABLED === 'true',
  globalAisEnabled: process.env.REACT_APP_GLOBAL_AIS_ENABLED === 'true',
  mergeStationaryPointsEnabled:
    process.env.REACT_APP_MERGE_STATIONARY_POINTS_ENABLED === 'true',
  avcsEnabled: process.env.REACT_APP_AVCS_ENABLED === 'true',
  permissionsTogglesEnabled:
    process.env.REACT_APP_PERMISSIONS_TOGGLE_EFFECTS_ENABLED === 'true',
  adminPermissionsTogglesEnabled:
    process.env.REACT_APP_PERMISSIONS_TOGGLE_ADMINISTRATION_ENABLED === 'true',
  fleetV2Enabled: process.env.REACT_APP_FLEET_V2_ENABLED === 'true',
  fleetV1AvailableWithFleetV2:
    process.env.REACT_APP_PERMISSIONS_TOGGLE_ALLOW_FLEET_V1_WITH_FLEET_V2 ===
    'true',
  mapLayerHandlerEnabled:
    process.env.REACT_APP_MAP_LAYER_HANDLER_ENABLED === 'true',
  fleetV2Phase2Enabled: process.env.REACT_APP_FLEETS_PHASE_2_ENABLED === 'true',
});
