import {
  Feature,
  FeatureCollection,
  MultiLineString,
  Point,
} from '@turf/helpers';
import { GeoJSONSource } from 'mapbox-gl';

import addSimpleGeoJsonSource from '../../map/map-layer-manager/layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../../map/map-layer-manager/map-layer-icon';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';
import MapLayerVisibility from '../../map/map-layer-manager/map-layer-visibility.enum';
import {
  MapGroupLayer,
  RfLayers,
} from '../../map/map-layer-manager/map-layer.enum';
import RFVesselsController from '../../map/map-layer-manager/vessel-utils/rf-vessel-controller';
import MapHelpers from '../../map/map.utils';
import { RfEvent } from '../../models/rf-data/rf-data.model';
import store from '../../store';
import GeoHelper from '../../utils/geo-helpers.utils';

namespace RfEventController {
  const rfEstimatedPositionLayer = {
    addLayer: (layerId: string, data: FeatureCollection<Point>) => {
      const map = MapHelpers.getMapInstance();
      addSimpleGeoJsonSource(layerId);
      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: layerId,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
          'icon-image': MapLayerIcon.RADIO_POSITION,
          'icon-allow-overlap': true,
          'icon-rotation-alignment': 'map',
          'icon-rotate': ['number', ['get', 'heading'], 360],
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is 5 (or less) -> icon will be half size
            5,
            0.5,
            // zoom is 10 (or greater) -> icon will be full size
            10,
            1,
          ],
        },
        metadata: MapLayerManager.groupLayerMetaData([
          layerId,
          MapGroupLayer.RF_ESTIMATED_POSITIONS,
          MapGroupLayer.RF,
        ]),
      });
      (map.getSource(layerId) as GeoJSONSource)?.setData(data);

      map.on(
        'mouseenter',
        layerId,
        RFVesselsController.layerEvents.onMouseEnterEvent
      );
      map.on(
        'mouseleave',
        layerId,
        RFVesselsController.layerEvents.onMouseLeave
      );
    },
    getLayerKey: (vesselId: string) =>
      `${vesselId}_${MapGroupLayer.RF_ESTIMATED_POSITIONS}`,
    groupLayer: MapGroupLayer.RF_ESTIMATED_POSITIONS,
  };

  const rfReportedPositionLayer = {
    addLayer: (layerId: string, data: FeatureCollection<Point>) => {
      const map = MapHelpers.getMapInstance();

      addSimpleGeoJsonSource(layerId);
      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: layerId,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
          'icon-image': MapLayerIcon.HISTORIC_POSITION,
          'icon-allow-overlap': true,
          'icon-rotation-alignment': 'map',
          'icon-rotate': ['number', ['get', 'heading'], 360],
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // zoom is 5 (or less) -> icon will be half size
            5,
            0.5,
            // zoom is 10 (or greater) -> icon will be full size
            10,
            1,
          ],
        },
        metadata: MapLayerManager.groupLayerMetaData([
          layerId,
          MapGroupLayer.RF_REPORTED_POSITIONS,
          MapGroupLayer.RF,
        ]),
      });

      (map.getSource(layerId) as GeoJSONSource)?.setData(data);
    },
    getLayerKey: (vesselId: string) =>
      `${vesselId}_${MapGroupLayer.RF_REPORTED_POSITIONS}`,
    groupLayer: MapGroupLayer.RF_REPORTED_POSITIONS,
  };

  const rfTrackLayer = {
    addLayer: (layerId: string, data: FeatureCollection<MultiLineString>) => {
      const map = MapHelpers.getMapInstance();
      addSimpleGeoJsonSource(layerId);
      map.addLayer({
        id: layerId,
        type: 'line',
        source: layerId,
        layout: {
          visibility: MapLayerVisibility.VISIBLE,
        },
        paint: MapLayerPaint.RF_POSITION_LINKS,
        metadata: MapLayerManager.groupLayerMetaData([
          layerId,
          MapGroupLayer.RF_TRACKS,
          MapGroupLayer.RF,
        ]),
      });
      (map.getSource(layerId) as GeoJSONSource)?.setData(data);
    },
    getLayerKey: (vesselId: string) => `${vesselId}_${MapGroupLayer.RF_TRACKS}`,
    groupLayer: MapGroupLayer.RF_TRACKS,
  };

  export const layerList = {
    RF_REPORTED_POSITIONS: rfReportedPositionLayer,
    RF_ESTIMATED_POSITIONS: rfEstimatedPositionLayer,
    RF_TRACKS: rfTrackLayer,
  };

  export const clearRfEventLayers = () => {
    Object.values(layerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        item.groupLayer
      );
      existingLayers.forEach((layer) => MapLayerManager.destroyLayer(layer.id));
    });

    RfLayers.forEach((layer) => {
      if (MapHelpers.getLayer(layer)) {
        MapLayerManager.destroyLayer(layer);
      }
    });
  };

  export const toggleAllRfLayers = () => {
    Object.values(layerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        item.groupLayer
      );

      existingLayers.forEach((layer) => {
        const layerId = layer.id;
        MapHelpers.setLayerVisibility(layerId, false);
        MapHelpers.setLayerVisibility(MapGroupLayer.RF_TARGETS, false);
      });
    });
  };

  export const toggleAllRfLayersOn = () => {
    Object.values(layerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        item.groupLayer
      );

      existingLayers.forEach((layer) => {
        const layerId = layer.id;
        MapHelpers.setLayerVisibility(layerId, true);
        MapHelpers.setLayerVisibility(MapGroupLayer.RF_TARGETS, true);
      });
    });
  };

  export const handleVessel = (vessel: RfEvent) => {
    // Sidebar results should expand to show the three layers, and also have some useful information displayed like average displacement etc.
    const reportedPositions: Feature<Point>[] = [];
    const estimatedPositions: Feature<Point>[] = [];
    const estimatedReportedSegments: Feature<MultiLineString>[] = [];

    vessel.geo.features.forEach((feature: any) => {
      reportedPositions.push(
        GeoHelper.createFeaturePoint(
          feature.properties?.rfgl_reported_position.coordinates,
          feature.properties
        )
      );
      estimatedPositions.push(
        GeoHelper.createFeaturePoint(
          feature.properties?.rfgl_estimated_position.coordinates,
          feature.properties
        )
      );
      estimatedReportedSegments.push(
        GeoHelper.createFeatureMultiLineString(
          feature.properties?.rfgl_estimated_reported_segment.coordinates,
          feature.properties
        )
      );
    });

    const reportedPositionsCollection = GeoHelper.createGeoJSON(
      reportedPositions
    ) as FeatureCollection<Point>;
    const estimatedPositionsCollection = GeoHelper.createGeoJSON(
      estimatedPositions
    ) as FeatureCollection<Point>;
    const estimatedSegmentsCollection = GeoHelper.createGeoJSON(
      estimatedReportedSegments
    ) as FeatureCollection<MultiLineString>;

    rfTrackLayer.addLayer(
      rfTrackLayer.getLayerKey(vessel.mmsi),
      estimatedSegmentsCollection
    ); // TODO this isn't necessarily RF tracks, as much as it is joining the two points together - rename?
    rfEstimatedPositionLayer.addLayer(
      rfEstimatedPositionLayer.getLayerKey(vessel.mmsi),
      estimatedPositionsCollection
    );
    rfReportedPositionLayer.addLayer(
      rfReportedPositionLayer.getLayerKey(vessel.mmsi),
      reportedPositionsCollection
    );
  };

  export const alreadyShowingThisVessel = (mmsi: string | number) => {
    const currentTargets = store.getState().rfData.rfTargets;
    if (!currentTargets) {
      return false;
    }
    return currentTargets
      ?.map((target) => target.mmsi.toString())
      .includes(mmsi.toString());
  };

  export const init = () => {
    const { rfData } = store.getState().rfData;
    if (rfData) {
      Object.values(rfData).forEach((vessel) => {
        handleVessel(vessel);
      });
    }
  };
}

export default RfEventController;
