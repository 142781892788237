/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DocumentFilters {
  open?: boolean;
  search: string;
}

export type DocumentsPanelState = {
  filters: DocumentFilters;
};

export const INITIAL_DOCUMENTS_PANEL_STATE: DocumentsPanelState = {
  filters: {
    open: false,
    search: '',
  },
};

export const DocumentsPanelSlice = createSlice({
  name: 'documentsPanel',
  initialState: INITIAL_DOCUMENTS_PANEL_STATE,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<DocumentFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

export const { setFilters } = DocumentsPanelSlice.actions;

export default DocumentsPanelSlice.reducer;
