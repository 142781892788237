import { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import AISMergedPointsController from '../../../maritime-menu-options/history-panel/history-ais-merged-points/history-ais-merged-points-controller.utils';
import { setSelectedMergedPointIndex } from '../../../state/history/history.slice';
import store from '../../../store';
import {
  hideAISPositionInfoPopUp,
  showAISPositionInfoPopup,
} from './ais-position-info-popup';

namespace AISVesselsController {
  export const layerEvents = {
    onMouseEnter: showAISPositionInfoPopup,
    onMouseLeave: hideAISPositionInfoPopUp,
    onClick: (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
      if (e.features?.length) {
        const selectedFeature = e.features[0];
        const index = selectedFeature.properties?.index;
        const selectedPoint =
          AISMergedPointsController.getMergedPointByIndex(index);
        if (selectedPoint) {
          AISMergedPointsController.onFeatureSelect(selectedPoint);
          store.dispatch(setSelectedMergedPointIndex(index));
        }
      }

      e.preventDefault();
    },
  };
}

export default AISVesselsController;
