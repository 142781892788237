/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SecurityRiskAssessmentInfo = {
  id: number | null;
};

type SecurityRiskAssessmentStateType = {
  selectedEntitySecurityAssessmentInfo: SecurityRiskAssessmentInfo;
  loading: boolean;
  error: boolean;
};

const INITIAL_SECURITY_RISK_ASSESSMENT_STATE: SecurityRiskAssessmentStateType =
  {
    selectedEntitySecurityAssessmentInfo: { id: null },
    loading: false,
    error: false,
  };

const SecurityRiskAssessmentSlice = createSlice({
  name: 'security-risk-assessment',
  initialState: INITIAL_SECURITY_RISK_ASSESSMENT_STATE,
  reducers: {
    setSelectedEntitySecurityAssessmentInfo: (
      state,
      action: PayloadAction<SecurityRiskAssessmentInfo>
    ) => {
      state.selectedEntitySecurityAssessmentInfo.id = action.payload.id;
    },
  },
});

export const { setSelectedEntitySecurityAssessmentInfo } =
  SecurityRiskAssessmentSlice.actions;

export default SecurityRiskAssessmentSlice.reducer;
