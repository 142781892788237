/* eslint-disable @typescript-eslint/naming-convention */
import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { RfEventInfo, RfTarget } from '../../../models/rf-data/rf-data.model';
import PopupHelpers from '../../../popup/popup.utils';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideRfPopup(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showRfEventInfoPopup(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  // TODO handle something to do with merged points...
  // potentially when a vessel is stationary

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as RfEventInfo;

    const {
      vessel_name,
      imo,
      mmsi,
      heading,
      rfgl_dtg,
      rfgl_event_type,
      sog,
      rfgl_estimated_reported_delta_km,
      nav_status,
    } = properties;

    const header = 'Radio Frequency Event';

    PopupHelpers.createInfoCard(
      popup,
      [
        {
          label: 'Name',
          value: vessel_name,
        },
        {
          label: 'MMSI',
          value: mmsi,
        },
        {
          label: 'IMO',
          value: imo,
        },
        {
          label: 'Event Type',
          value: rfgl_event_type,
        },
        {
          label: 'Speed:',
          value: `${sog} kts`,
        },
        {
          label: 'Delta:',
          value: `${
            rfgl_estimated_reported_delta_km
              ? `${rfgl_estimated_reported_delta_km.toFixed(2)} km`
              : 'N/A'
          }`,
        },
        {
          label: 'Timestamp:',
          value: rfgl_dtg,
        },
        {
          label: 'Heading:',
          value: `${heading !== null ? heading : '0'}°`,
        },
        {
          label: 'Navigational Status',
          value: nav_status,
        },
      ],
      header
    );

    // @ts-ignore
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}

export function showRfTargetInfoPopup(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as RfTarget;

    const {
      vessel_name,
      imo,
      mmsi,
      heading,
      rfgl_latest_validated_dtg,
      rfgl_latest_anomaly_dtg,
      rfgl_anomaly_count,
      rfgl_latest_is_validated,
    } = properties;

    const header = 'Radio Frequency Target';

    PopupHelpers.createInfoCard(
      popup,
      [
        {
          label: 'Name',
          value: vessel_name,
        },
        {
          label: 'MMSI',
          value: mmsi,
        },
        {
          label: 'IMO',
          value: imo,
        },
        {
          label: 'Latest RF Event',
          value: rfgl_latest_is_validated
            ? rfgl_latest_validated_dtg
            : rfgl_latest_anomaly_dtg,
        },
        {
          label: 'Anomaly Count',
          value: rfgl_anomaly_count,
        },
        {
          label: 'Heading',
          value: `${heading !== null ? heading : '0'}°`,
        },
      ],
      header
    );

    // @ts-ignore
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}
