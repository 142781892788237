/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

interface SpeedDialState {
  speedDialOpen: false;
}

export const INITIAL_SPEEDDIAL_STATE: SpeedDialState = {
  speedDialOpen: false,
};

const SpeedDialSlice = createSlice({
  name: 'speedDial',
  initialState: INITIAL_SPEEDDIAL_STATE,
  reducers: {
    setSpeedDialOpen: (state, action) => {
      state.speedDialOpen = action.payload;
    },
  },
});

export const { setSpeedDialOpen } = SpeedDialSlice.actions;

export default SpeedDialSlice.reducer;
