/* eslint-disable no-param-reassign */
import { Dictionary, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DrawingFilters,
  DrawingOptions,
  PartialCreateDrawingValues,
} from '../../models/drawings/drawings.model';

type DrawingsPanelState = {
  initialFormValues: PartialCreateDrawingValues;
  nameDescriptionValues: Dictionary<string>;
  searchFilterValue: string;
  filters: DrawingFilters;
  drawingOptions: DrawingOptions;
};

const INITIAL_DRAWINGS_PANEL_STATE: DrawingsPanelState = {
  initialFormValues: {} as PartialCreateDrawingValues,
  nameDescriptionValues: { name: '', description: '' },
  searchFilterValue: '',
  filters: {
    startDate: null,
    endDate: null,
    ownerTypes: [],
    open: false,
  },
  drawingOptions: {
    drawingColour: '#ffac1c',
    drawingOpacity: 0.3,
    drawingHexAlpha: '#ffac1c6e',
  },
};

const DrawingsPanelSlice = createSlice({
  name: 'drawingsPanel',
  initialState: INITIAL_DRAWINGS_PANEL_STATE,
  reducers: {
    setInitialFormValues: (
      state,
      action: PayloadAction<PartialCreateDrawingValues>
    ) => {
      state.initialFormValues = action.payload;
    },
    setNameDescriptionValues: (
      state,
      action: PayloadAction<Dictionary<string>>
    ) => {
      state.nameDescriptionValues = action.payload;
    },
    setSearchFilterValue: (state, action: PayloadAction<string>) => {
      state.searchFilterValue = action.payload;
    },
    setDrawingFilters: (
      state,
      action: PayloadAction<Partial<DrawingFilters> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setDrawingOptions: (
      state,
      action: PayloadAction<Partial<DrawingOptions> | null>
    ) => {
      // Only updates options explictly mentioned in action
      state.drawingOptions = {
        ...state.drawingOptions,
        ...action.payload,
      };
    },
  },
});

export const {
  setInitialFormValues,
  setNameDescriptionValues,
  setDrawingFilters,
  setSearchFilterValue,
  setDrawingOptions,
} = DrawingsPanelSlice.actions;

export default DrawingsPanelSlice.reducer;
