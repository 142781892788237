import {
  hideRfPopup,
  showRfEventInfoPopup,
  showRfTargetInfoPopup,
} from './rf-position-info-popup';

namespace RFVesselsController {
  export const layerEvents = {
    onMouseEnterEvent: showRfEventInfoPopup,
    onMouseEnterTarget: showRfTargetInfoPopup,
    onMouseLeave: hideRfPopup,
  };
}

export default RFVesselsController;
