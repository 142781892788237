import { FeedbackType } from '../maritime-menu-options/feedback-panel/feedback-form-validators';
import { wrapRequest } from './base';

export interface FeedbackBody {
  feedback_type: FeedbackType;
  email: string;
  full_name: string;
  tenant_id: string;
  text_content: string;
  images?: {
    name: string;
    type: string;
    data?: string;
  }[];
}

export const postFeedback = async (feedbackBody: FeedbackBody) =>
  wrapRequest('post', 'geonius', `/feedback`, {
    body: feedbackBody,
  });
