/* eslint-disable react/jsx-no-useless-fragment */
import {
  CssBaseline,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setStyle } from '../map/map.slice';
import TenantId from '../tenant';
import { Tenant, getFromTheme, setTheme } from '../theme';
import ThemeConfig from './theme-config';

type AppThemeProviderProps = PropsWithChildren;

const tenantForDomain = (): Tenant => {
  const RI_DOMAIN = process.env.REACT_APP_RI_DOMAIN;
  switch (window.location.host) {
    case RI_DOMAIN:
      return TenantId.RISK_INTELLIGENCE;
    default:
      return TenantId.GEOLLECT;
  }
};

function AppThemeProvider({ children }: AppThemeProviderProps) {
  const { idToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [tenant, setTenant] = useState<Tenant>(tenantForDomain);

  useEffect(() => {
    if (idToken?.tenantId && idToken.tenantId in TenantId) {
      setTenant(idToken.tenantId);
    } else {
      setTenant(tenantForDomain);
    }
  }, [idToken?.tenantId]);

  useEffect(() => {
    if (tenant) {
      setTheme(tenant);
      dispatch(setStyle(getFromTheme(tenant, 'mapboxStyle')));
    }
  }, [tenant]);

  return (
    <ThemeProvider
      theme={createTheme(
        (ThemeConfig[tenant] as ThemeOptions) || ThemeConfig[TenantId.GEOLLECT]
      )}
    >
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
}

export default AppThemeProvider;
