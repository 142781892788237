/* eslint-disable import/prefer-default-export */

import getDaasAuthToken from '../api/daas-auth';
import { useAppDispatch } from '../hooks';
import {
  clearError,
  setDaasToken,
  setDaasTokenExpiry,
  setError,
} from '../state/global-ais/global-ais.slice';
import store from '../store';

export async function refreshOAuthToken(
  dispatch: ReturnType<typeof useAppDispatch>
): Promise<boolean> {
  const { daasToken, daasTokenExpiry } = store.getState().globalAis;
  if (daasToken && daasTokenExpiry && new Date().getTime() < daasTokenExpiry) {
    return true;
  }
  try {
    const response = await getDaasAuthToken();
    dispatch(setDaasToken(response.access_token));
    // Token expiry is set to 95% of the actual expiry time to avoid token expiry during the request
    dispatch(
      setDaasTokenExpiry(
        new Date(new Date().getTime() + response.expires_in * 950).getTime()
      )
    );
    dispatch(clearError());
    return true;
  } catch (error) {
    dispatch(setError());
    return false;
  }
}
