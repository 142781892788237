import prepareVesselStylingData from '../../../utils/vessel-styling.utils';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addAnyVesselLayer from './add-any-vessel-layer';

function addPlainVesselLayerToMap(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata: object = {},
  userPreferences: any = {}
) {
  const iconImage = MapLayerIcon.PLAIN_VESSEL.NORMAL;
  const { colour } = prepareVesselStylingData(userPreferences);

  addAnyVesselLayer(
    layer,
    metadata,
    visibility,
    iconImage,
    colour,
    userPreferences
  );
}

export default addPlainVesselLayerToMap;
