/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdtechData } from '../../api/adtech';

export type AdtechState = {
  loading: boolean | string;
  error: boolean;
  data: AdtechData[];
  selectedAdtech: AdtechData | null;
};

export const INITIAL_ADTECH_STATE: AdtechState = {
  loading: false,
  error: false,
  data: [],
  selectedAdtech: null,
};

const AdtechSlice = createSlice({
  name: 'adtech',
  initialState: INITIAL_ADTECH_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean | string>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setData: (state, action: PayloadAction<AdtechData[]>) => {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    },
    addData: (state, action: PayloadAction<AdtechData[]>) => {
      state.data =
        state.data.length > 0
          ? state.data.concat(action.payload)
          : action.payload;
    },
    setSelectedAdtech: (state, action: PayloadAction<AdtechData | null>) => {
      state.selectedAdtech = action.payload;
    },
  },
});

export const { setLoading, setError, setData, addData, setSelectedAdtech } =
  AdtechSlice.actions;

export default AdtechSlice.reducer;
