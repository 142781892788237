import { EAlertTypes } from '../models/alerts/alert-configuration';
import { AlertingRoute, PostRoute, Route } from '../models/routes/routes.model';
import { wrapRequest } from './base';

export interface RouteAlertResponse {
  route_id: string;
  radius: number;
  route_name: string;
  route_data: GeoJSON.Feature<GeoJSON.LineString>;
  description: string;
  company_id: string;
  cognito_id: string;
  shared: boolean;
  alert_id: string;
  result_hash: string;
  vessel_id: null;
  alert_type: EAlertTypes.INCIDENTS_ROUTE;
  alert_modifiers: AlertModifiers;
  alert_time: string;
  expiry_time: string;
  additional_details: RouteAdditionalDetails;
  alert_config_id: string;
}

interface RouteAdditionalDetails {
  radius: number | string;
  incidents: number[];
}

interface AlertModifiers {
  hours: number;
  route_id: string;
}

// this is a guess of the call signatures. update once api endpoint exists
export const getRoutes = async (): Promise<Route[]> =>
  wrapRequest('get', 'geonius', '/routes');

export const postOrUpdateRoute = (route: PostRoute) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  if (route.route_id) {
    return wrapRequest('put', 'geonius', `/routes`, { body: route });
  }
  return wrapRequest('post', 'geonius', '/routes', { body: route });
};

export const deleteRoute = async (routeIds: string[]): Promise<void> =>
  wrapRequest('del', 'geonius', '/routes', {
    body: {
      route_ids: routeIds,
    },
  });

export const fetchAlertingRoutesForCompany = async (): Promise<
  RouteAlertResponse[]
> => wrapRequest('get', 'geonius', `/alerting-routes`);

export const getAlertingRoutesForCompany = async (): Promise<
  AlertingRoute[]
> => {
  const alertingRoutes = await fetchAlertingRoutesForCompany();
  return alertingRoutes.map((alertingRoute) => ({
    ...alertingRoute,
    additionalDetails: alertingRoute.additional_details,
    alertTime: alertingRoute.alert_time,
    expiryTime: alertingRoute.expiry_time,
    alertType: alertingRoute.alert_type,
  }));
};
