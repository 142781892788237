/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RenameFleetState {
  renameFleetDialogOpen: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  currentFleets: string[];
}

export const INITAL_RENAME_FLEET_STATE: RenameFleetState = {
  renameFleetDialogOpen: false,
  loading: false,
  success: false,
  error: false,
  currentFleets: [],
};

const RenameFleetDialogSlice = createSlice({
  name: 'rename fleet dialog',
  initialState: INITAL_RENAME_FLEET_STATE,
  reducers: {
    setRenameFleetDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.renameFleetDialogOpen = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

export const { setRenameFleetDialogOpen, setLoading, setSuccess, setError } =
  RenameFleetDialogSlice.actions;

export default RenameFleetDialogSlice.reducer;
