import mapboxgl from 'mapbox-gl';
import {
  ColourScheme,
  Tenant,
  colourSchemes,
  getFromTheme,
} from '../../../theme';
import MapHelpers from '../../map.utils';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

function addPointLayer(
  layer: MapLayer | string,
  paint: mapboxgl.CirclePaint = {},
  tenant: Tenant = 'GEOLLECT',
  groupLayerid?: string,
  drawingColour?: string
) {
  const colourScheme: ColourScheme | undefined = colourSchemes.get(
    getFromTheme(tenant, 'colourScheme')
  );
  const map = MapHelpers.getMapInstance();
  const canvas = map.getCanvasContainer();

  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    if (groupLayerid) {
      MapLayerManager.AddLayerinGroups(
        {
          id: layer,
          type: 'circle',
          source: layer,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
          paint,
        },
        [groupLayerid]
      );
    } else {
      MapHelpers.addLayer({
        id: layer,
        type: 'circle',
        source: layer,
        layout: {
          visibility: MapLayerVisibility.NOT_VISIBLE,
        },
        paint,
      });
    }

    map.on('mouseenter', layer, () => {
      map.setPaintProperty(
        layer,
        'circle-color',
        drawingColour || colourScheme?.sarTint
      );
      canvas.style.cursor = 'pointer';
    });

    map.on('mouseleave', layer, () => {
      map.setPaintProperty(
        layer,
        'circle-color',
        drawingColour || colourScheme?.drawActive
      );
      canvas.style.cursor = '';
    });

    resolve(MapHelpers);
  });
}

export default addPointLayer;
