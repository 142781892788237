/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ArabianSeaDataPointOne,
  ArabianSeaDataPointTwo,
  BlackSeaDataPoint,
  BlackSeaDataPointTwo,
} from '../../models/unseen-labs/unseen.model';

export enum UnseenPanelViewState {
  MENU = 'Unseen-panel',
}

interface UnseenPanelState {
  viewState: UnseenPanelViewState;
  blackSeaDatasetOne: BlackSeaDataPoint[];
  blackSeaDatasetTwo: BlackSeaDataPointTwo[];
  arabianSeaDatasetOne: ArabianSeaDataPointOne[];
  arabianSeaDatasetTwo: ArabianSeaDataPointTwo[];
}

export const INITIAL_UNSEEN_LABS_PANEL_STATE: UnseenPanelState = {
  viewState: UnseenPanelViewState.MENU,
  blackSeaDatasetOne: [],
  blackSeaDatasetTwo: [],
  arabianSeaDatasetOne: [],
  arabianSeaDatasetTwo: [],
};

const UnseenPanelSlice = createSlice({
  name: 'unseen',
  initialState: INITIAL_UNSEEN_LABS_PANEL_STATE,
  reducers: {
    setBlackSeaDatasetOne: (
      state,
      action: PayloadAction<BlackSeaDataPoint[]>
    ) => {
      state.blackSeaDatasetOne = action.payload;
    },
    setBlackSeaDatasetTwo: (
      state,
      action: PayloadAction<BlackSeaDataPointTwo[]>
    ) => {
      state.blackSeaDatasetTwo = action.payload;
    },
    setArabianSeaDatasetOne: (
      state,
      action: PayloadAction<ArabianSeaDataPointOne[]>
    ) => {
      state.arabianSeaDatasetOne = action.payload;
    },
    setArabianSeaDatasetTwo: (
      state,
      action: PayloadAction<ArabianSeaDataPointTwo[]>
    ) => {
      state.arabianSeaDatasetTwo = action.payload;
    },
    // setUnseenPanelViewState: (
    //   state,
    //   action: PayloadAction<UnseenPanelState['viewState']>
    // ) => {
    //   // state.viewState = action.payload;
    // },
  },
});

// export const { setUnseenPanelViewState } = UnseenPanelSlice.actions;
export const {
  setBlackSeaDatasetOne,
  setBlackSeaDatasetTwo,
  setArabianSeaDatasetOne,
  setArabianSeaDatasetTwo,
} = UnseenPanelSlice.actions;

export default UnseenPanelSlice.reducer;
