/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import './user-preferences-page.scss';

function UserPreferencesPage() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.menu',
        height: '100vh',
        display: 'flex',
      }}
    >
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default UserPreferencesPage;
