import { Feature, Point, Polygon } from '@turf/helpers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormikValues } from 'formik';

dayjs.extend(utc);

export interface HistoricVesselsFormValues extends FormikValues {
  dateTimeRange: {
    startDate: string | null;
    endDate: string | null;
  };
  searchArea: Feature<Point | Polygon> | null;
}

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);
const startDate = currentDate.toISOString();
const endDate = dayjs().toISOString();

export const defaultValues: HistoricVesselsFormValues = {
  dateTimeRange: {
    startDate,
    endDate,
  },
  searchArea: null,
};
