export const MapBoxLayerExclusions = [
  'water',
  'depth',
  'admin-0-boundary copy',
  'water-point-label',
  'country-label copy',
  'settlement-major-label copy',
  'continent-label copy',
  'water-line-label',
  'Large Ports',
  'All Ports',
  'waterway',
  'waterway-label',
  'land-structure-line',
  'land-structure-polygon',
  'building',
  'lat-long-box-draw',
  'capellaSar',
  'measurement-lines',
  'measurement-lines-temp',
  'measurement-points',
  'measurement-points-temp',
  'weather-pin',
  'weather-visibility-ring',
  'weather-visibility-label',
  'sar-imagery-box-area-fill',
  'sar-imagery-box-area-outline',
  'sar-imagery-box-area',
  'circle-edit-temp',
  'gl-draw-polygon-fill-inactive.cold',
  'gl-draw-polygon-stroke-inactive.cold',
  'gl-draw-polygon-and-line',
  'gl-draw-polygon-fill-inactive.hot',
  'gl-draw-polygon-stroke-inactive.hot',
  'gl-draw-polygon-fill-inactive.neutral',
  'gl-draw-polygon-stroke-inactive.neutral',
  'gl-draw-polygon-fill-inactive',
  'gl-draw-polygon-stroke-inactive',
  'gl-draw-polygon-fill-inactive.cold',
  'gl-draw-polygon-stroke-inactive.cold',
  'gl-draw-polygon-fill-inactive.hot',
  'gl-draw-polygon-stroke-inactive.hot',
  'gl-draw-polygon-fill-inactive.neutral',
  'gl-draw-polygon-stroke-inactive.neutral',
  'gl-draw-polygon-fill-inactive',
  'gl-draw-polygon-stroke-inactive',
  'gl-draw-point',
  'gl-draw-line',
  'gl-draw-polygon-fill',
  'gl-draw-polygon-stroke',
  'gl-draw-polygon-and-line',
  'gl-draw-point-point',
  'admin-0-boundary-disputed',
];

export const ExclusionList = [
  'ports',
  'port-cluster-count',
  'port-clusters',
  'incidents',
  'incident-clusters',
  'incident-cluster-count',
  'routes-incidents',
  'routes-incident-clusters',
  'routes-incident-cluster-count',
  'routes-ports',
  'routes-port-clusters',
  'routes-port-cluster-count',
  'my-fleet-vessels',
];
