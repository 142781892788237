/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type VulnerabilityAssessmentInfo = {
  id: number | null;
};

type VulnerabilityAssessmentStateType = {
  selectedEntityVulnerabilityAssessmentInfo: VulnerabilityAssessmentInfo;
  loading: boolean;
  error: boolean;
};

const INITAL_VULNERABILITY_ASSESSMENT_STATE: VulnerabilityAssessmentStateType =
  {
    selectedEntityVulnerabilityAssessmentInfo: { id: null },
    loading: false,
    error: false,
  };

const VulnerabilityAssessmentSlice = createSlice({
  name: 'vulnerability-assessment',
  initialState: INITAL_VULNERABILITY_ASSESSMENT_STATE,
  reducers: {
    setSelectedEntityVulnerabilityAssessmentInfo: (
      state,
      action: PayloadAction<VulnerabilityAssessmentInfo>
    ) => {
      state.selectedEntityVulnerabilityAssessmentInfo.id = action.payload.id;
    },
  },
});

export const { setSelectedEntityVulnerabilityAssessmentInfo } =
  VulnerabilityAssessmentSlice.actions;

export default VulnerabilityAssessmentSlice.reducer;
