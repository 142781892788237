import VesselTypes from '../../models/vessels/vessel-types.model';

export const VesselTypeLabels: { [key in VesselTypes]: string } = {
  [VesselTypes.DRY_BULK]: 'Dry Bulk',
  [VesselTypes.GENERAL_CARGO]: 'General Cargo',
  [VesselTypes.CONTAINER]: 'Container',
  [VesselTypes.REEFER]: 'Reefer',
  [VesselTypes.ROLL_ON_ROLL_OFF]: 'Roll On Roll Off',
  [VesselTypes.OFFSHORE]: 'Offshore',
  [VesselTypes.HIGH_SPEED_CRAFT]: 'High Speed Craft',
  [VesselTypes.CAR_CARRIER]: 'Car Carrier',
  [VesselTypes.VEHICLE_PASSENGER]: 'Vehicle Passenger',
  [VesselTypes.PLEASURE_CRAFT]: 'Pleasure Craft',
  [VesselTypes.FISHING]: 'Fishing',
  [VesselTypes.GENERAL_TANKER]: 'General Tanker',
  [VesselTypes.GAS_TANKER]: 'Gas Tanker',
  [VesselTypes.TANKER_PRODUCT]: 'Tanker Product',
  [VesselTypes.PASSENGER]: 'Passenger',
  [VesselTypes.OTHER_UNKNOWN]: 'Other/Unknown',
};

export const VesselTypeCode: { [key in VesselTypes]: string } = {
  [VesselTypes.DRY_BULK]: 'DB',
  [VesselTypes.GENERAL_CARGO]: 'GC',
  [VesselTypes.CONTAINER]: 'C',
  [VesselTypes.REEFER]: 'R',
  [VesselTypes.ROLL_ON_ROLL_OFF]: 'RO',
  [VesselTypes.OFFSHORE]: 'OFF',
  [VesselTypes.HIGH_SPEED_CRAFT]: 'HSC',
  [VesselTypes.CAR_CARRIER]: 'CC',
  [VesselTypes.VEHICLE_PASSENGER]: 'VP',
  [VesselTypes.PLEASURE_CRAFT]: 'PC',
  [VesselTypes.FISHING]: 'F',
  [VesselTypes.GENERAL_TANKER]: 'GT',
  [VesselTypes.GAS_TANKER]: 'GT',
  [VesselTypes.TANKER_PRODUCT]: 'TP',
  [VesselTypes.PASSENGER]: 'P',
  [VesselTypes.OTHER_UNKNOWN]: 'OU',
};

export const VesselTypeStyle: {
  [key in VesselTypes]: string;
} = {
  [VesselTypes.DRY_BULK]: '#e3e649',
  [VesselTypes.GENERAL_CARGO]: '#2343fa',
  [VesselTypes.CONTAINER]: '#b33951',
  [VesselTypes.REEFER]: '#e08d9e',
  [VesselTypes.ROLL_ON_ROLL_OFF]: '#f4bef7',
  [VesselTypes.OFFSHORE]: '#f70f93',
  [VesselTypes.HIGH_SPEED_CRAFT]: '#7f009c',
  [VesselTypes.CAR_CARRIER]: '#f73b45',
  [VesselTypes.VEHICLE_PASSENGER]: '#f7920c',
  [VesselTypes.PLEASURE_CRAFT]: '#fa5c23',
  [VesselTypes.FISHING]: '#42f542',
  [VesselTypes.GENERAL_TANKER]: '#1d7334',
  [VesselTypes.GAS_TANKER]: '#9da5eb',
  [VesselTypes.TANKER_PRODUCT]: '#05a68e',
  [VesselTypes.PASSENGER]: '#fbfc9a',
  [VesselTypes.OTHER_UNKNOWN]: '#FFFFFF',
};
