import { LngLatLike, Popup } from 'mapbox-gl';
import MapHelpers from '../../../map/map.utils';
import PopupHelpers from '../../../popup/popup.utils';
import { MergedHistoricVesselPoint } from '../../../state/history/history.slice';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';

export const AISMergedPointsPopup = new Popup({
  closeButton: false,
  closeOnMove: false,
});

export function showAISMergedPointsPopup(point: MergedHistoricVesselPoint) {
  const { startTimestamp, endTimestamp, features, mmsi } = point;
  const map = MapHelpers.getMapInstance();
  const gapLength = DateTimeHelpers.getHoursInMilliseconds(
    endTimestamp - startTimestamp
  );

  PopupHelpers.createInfoCard(
    AISMergedPointsPopup,
    [
      {
        label: 'MMSI',
        value: mmsi,
      },
      {
        label: 'From',
        // TODO: move to date helpers
        value: `${new Date(startTimestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}`,
      },
      {
        label: 'To',
        // TODO: move to date helpers
        value: `${new Date(endTimestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}`,
      },
      {
        label: 'Hours',
        value: `${gapLength}`,
      },
    ],
    `${features.length} Points in current position`
  );

  AISMergedPointsPopup.setLngLat(
    features[0].geometry.coordinates as LngLatLike
  );
  AISMergedPointsPopup.addTo(map);
}

export function hideAISMergedPointsPopup() {
  AISMergedPointsPopup.remove();
}
