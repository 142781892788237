/* eslint-disable import/prefer-default-export */
import { Sanction } from '../dossiers_and_panels/country-dossier/sanctions/sanction.model';
import { useAppDispatch } from '../hooks';
import store from '../store';
import { refreshOAuthToken } from '../utils/auth-helpers.utils';
import { wrapRequest } from './base';

export const getSanctions = async (
  countryCodes: string[],
  dispatch: ReturnType<typeof useAppDispatch>
): Promise<Sanction[]> => {
  const authorised = await refreshOAuthToken(dispatch);

  if (!authorised) {
    throw new Error('Not authorised');
  }

  const { daasToken } = store.getState().globalAis;

  return wrapRequest('get', 'atlas', `/sanctions`, {
    queryStringParameters: {
      'iso2-country-codes': countryCodes
        .map((code) => code.toUpperCase())
        .join(','),
    },
    headers: { Authorization: `Bearer ${daasToken}` },
  });
};
