/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentWithMeta } from '../../models/documents/document';

export type DocumentsState = {
  loading: boolean;
  error: boolean;
  myDocuments: null | DocumentWithMeta[];
  organisationDocuments: null | DocumentWithMeta[];
  tenantDocuments: null | DocumentWithMeta[];
};

export const INITIAL_DOCUMENTS_STATE: DocumentsState = {
  loading: false,
  error: false,
  myDocuments: null,
  organisationDocuments: null,
  tenantDocuments: null,
};

export const DocumentsSlice = createSlice({
  name: 'documents',
  initialState: INITIAL_DOCUMENTS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setDocuments: (
      state,
      action: PayloadAction<{
        myDocuments: DocumentWithMeta[] | null;
        organisationDocuments: DocumentWithMeta[] | null;
        tenantDocuments: DocumentWithMeta[] | null;
      }>
    ) => {
      state.myDocuments = action.payload.myDocuments;
      state.organisationDocuments = action.payload.organisationDocuments;
      state.tenantDocuments = action.payload.tenantDocuments;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { setLoading, setError, setDocuments } = DocumentsSlice.actions;

export default DocumentsSlice.reducer;
