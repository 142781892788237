import { S3ProviderListOutputItem, Storage } from '@aws-amplify/storage';
import getStorageFileDirectory from '../common-components/upload-entity-documents-modal/upload-entity-documents.utils';
import {
  DocumentWithMeta,
  EntityDocument,
  EntityType,
  FileOwner,
  FileOwnerToStorageLevel,
  IDocument,
  StorageLevel,
  StorageLevelToAmplifyStorageAccessLevel,
  serializeS3ProviderListOutputItem,
} from '../models/documents/document';
import store from '../store';
import { UserFeatureFlags } from '../user/user.slice';
import DateTimeHelpers from '../utils/date-time-helpers.utils';
import DownloadHelpers from '../utils/download.utils';
import { getFilenameFromDirectory } from '../utils/text-formatting.utils';
import { wrapRequest } from './base';

interface GetEntityDocumentBody extends Pick<EntityDocument, 'entity_id'> {
  company_id: string;
  user_id: string;
  tenant_id: string;
}

interface PutDocumentMetaDataParams {
  file: File;
  fileOwner: FileOwner;
  storageLevel: StorageLevel;
  entityId: string;
  entityType: string;
  description: string;
  name: string;
}

export const getStorageLevel = (fileOwner: FileOwner): StorageLevel =>
  FileOwnerToStorageLevel[fileOwner];

export const putDocumentMetadata = async ({
  file,
  fileOwner,
  storageLevel,
  entityId,
  entityType,
  name = '',
  description = '',
}: PutDocumentMetaDataParams): Promise<'Successfully updated document information'> => {
  const tenantId = store.getState().user.idToken?.tenantId;
  const companyId = store.getState().user.idToken?.companyId;
  const userId = store.getState().user.idToken?.sub;

  return wrapRequest('put', 'geonius', `/documents`, {
    body: {
      // display name for document
      filename: name,
      date_uploaded: DateTimeHelpers.dateToIso8601(new Date()),
      file_date: DateTimeHelpers.dateToIso8601(new Date(file.lastModified)),
      description,
      s3_location: `${getStorageFileDirectory(storageLevel, entityId)}/${
        file.name
      }`,
      entity_id: entityId,
      entity_type: entityType,
      file_tenant: tenantId,
      file_company: companyId,
      file_owner: storageLevel === StorageLevel.PRIVATE ? userId : companyId,
      file_owner_enum: fileOwner,
    },
  });
};

export const getDocumentMetadata = async (
  body: Partial<GetEntityDocumentBody> = {
    company_id: store.getState().user.idToken?.companyId,
    user_id: store.getState().user.idToken?.sub,
    tenant_id: store.getState().user.idToken?.tenantId,
  }
): Promise<EntityDocument[]> =>
  wrapRequest('get', 'geonius', `/documents`, {
    queryStringParameters: body,
  });

export const deleteDocumentMetadata = async (
  storageLevel: StorageLevel,
  documentId: string,
  entityId: string,
  entityType: string
): Promise<'Successfully deleted document metadata'> => {
  const tenantId = store.getState().user.idToken?.tenantId;
  const companyId = store.getState().user.idToken?.companyId;
  const userId = store.getState().user.idToken?.sub;
  return wrapRequest('del', 'geonius', `/documents`, {
    body: {
      data: {
        document_id: documentId,
        entity_id: entityId,
        entity_type: entityType,
        company_id: companyId,
        user_id: userId,
        tenant_id: tenantId,
        file_owner_enum:
          storageLevel === StorageLevel.PRIVATE
            ? FileOwner.PRIVATE
            : storageLevel,
      },
    },
  });
};

// These functions are technically not API calls,
// but we never use the S3 objects without enriching them with metadata from the api, so they can live here

const addMetadataToS3Objects = async (
  s3Objects: IDocument[],
  metadataPromise: Promise<EntityDocument[]>
): Promise<DocumentWithMeta[]> => {
  const metadata = await metadataPromise;
  return s3Objects
    .map((s3Object) => {
      const metaDoc = metadata.find((m) => m.s3_location === s3Object.key);
      if (metaDoc) {
        return {
          ...s3Object,
          metadata: metaDoc,
        } as DocumentWithMeta;
      }
      // Can't handle documents without metadata
      return null;
    })
    .filter(Boolean) as DocumentWithMeta[];
};

const serializeResults = (results: S3ProviderListOutputItem[]): IDocument[] =>
  results.map((result) => serializeS3ProviderListOutputItem(result));

/**
 * Get S3 Docs from Amplify Storage, and add metadata from the API
 * Supports passing through filters to getDocumentMetadata
 * @param body
 * @returns
 */

function getDocsPromise(
  storageLevel: StorageLevel,
  metadataPromise: Promise<any>,
  body?: Partial<GetEntityDocumentBody>
) {
  const level = StorageLevelToAmplifyStorageAccessLevel[storageLevel];
  return Storage.list(getStorageFileDirectory(storageLevel, body?.entity_id), {
    level,
  }).then(({ results }) => {
    const serialized = serializeResults(results);
    const docsWithMetadata = addMetadataToS3Objects(
      serialized,
      metadataPromise
    );
    return docsWithMetadata;
  });
}

export const EntityTypeToFeatureFlag: Partial<
  Record<EntityType, keyof UserFeatureFlags>
> = {
  [EntityType.port]: 'ports_license' as keyof UserFeatureFlags,
};

export const filterDocumentsOnUsersLicense = (
  documents: DocumentWithMeta[],
  licenses: UserFeatureFlags | null
) =>
  documents.filter(({ metadata }) => {
    const license = EntityTypeToFeatureFlag[metadata.entity_type as EntityType];
    return license ? licenses?.[license] : true;
  });

export const getS3DocsWithMetadata = async (
  body?: Partial<GetEntityDocumentBody>
): Promise<{
  myDocuments: DocumentWithMeta[];
  organisationDocuments: DocumentWithMeta[];
  tenantDocuments: DocumentWithMeta[];
}> => {
  const tenantId = store.getState().user.idToken?.tenantId;
  const myDocumentsMetaPromise = getDocumentMetadata(body);
  const licenses = store.getState().user.featureFlags ?? null;

  const myDocsPromise = getDocsPromise(
    StorageLevel.PRIVATE,
    myDocumentsMetaPromise,
    body
  );
  const orgDocsPromise = getDocsPromise(
    StorageLevel.COMPANY,
    myDocumentsMetaPromise,
    body
  );
  const tenantDocsPromise = getDocsPromise(
    StorageLevel.TENANT,
    myDocumentsMetaPromise,
    body
  );

  const [myDocuments, organisationDocuments, tenantDocuments] =
    await Promise.all([myDocsPromise, orgDocsPromise, tenantDocsPromise]);
  return {
    myDocuments,
    organisationDocuments: filterDocumentsOnUsersLicense(
      organisationDocuments,
      licenses
    ).filter((doc) => doc.metadata.file_owner_enum === FileOwner.COMPANY),
    tenantDocuments: filterDocumentsOnUsersLicense(
      tenantDocuments,
      licenses
    ).filter(
      (doc) =>
        doc.metadata.file_owner_enum === FileOwner.TENANT &&
        doc.metadata.file_tenant === tenantId
    ),
  };
};

export const downloadDocument = async (document: IDocument) => {
  const { key, metadata } = document;

  if (!metadata) {
    return Promise.reject(Error('No metadata found for document'));
  }

  const storageLevel = getStorageLevel(metadata.file_owner_enum);
  if (!storageLevel) {
    throw new Error('Invalid storage level');
  }

  const amplifyAccessLevel =
    StorageLevelToAmplifyStorageAccessLevel[storageLevel];

  return DownloadHelpers.downloadS3File(key!, {
    level: amplifyAccessLevel,
  })
    .then((uri) =>
      DownloadHelpers.downloadURI(getFilenameFromDirectory(key!) as string, uri)
    )
    .catch(() => {});
};

export const deleteDocument = async (document: DocumentWithMeta) => {
  const storageLevel = getStorageLevel(document.metadata.file_owner_enum);

  const getDocumentDeletePromise = async () => {
    if (storageLevel === StorageLevel.PRIVATE) {
      return Storage.remove(document.key!, {
        level: StorageLevel.PRIVATE,
      });
    }

    return Storage.remove(document.key!);
  };

  const getDocumentMetadataDeletePromises = () =>
    deleteDocumentMetadata(
      storageLevel,
      document.metadata.document_id!,
      document.metadata.entity_id!,
      document.metadata.entity_type!
    );

  return Promise.all([
    getDocumentDeletePromise(),
    getDocumentMetadataDeletePromises(),
  ]);
};
