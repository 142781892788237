/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from '@turf/helpers';
import DateFilters from '../../../common-components/date-filter/date-filter.model';
import { MaritimeAisApiLocationData } from '../../../models/vessels/maritime-ais-api';
import { HistoricVesselPoint } from '../../history-panel/historic-vessel-point.model';
import {
  defaultValues,
  HistoricVesselsFormValues,
} from './historic-area-search-form/historic-area-search-form-values';

export interface HistoricAreaSearchVesselPointsState {
  [vesselId: string]: HistoricVesselPoint[];
}

interface HistoricVesselsState {
  historicVessels: MaritimeAisApiLocationData | null;
  historicVesselsFormValues: HistoricVesselsFormValues;
  historicVesselsPoints: Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]; // TODO this might be wrong
  loading: boolean;
  error: boolean;
  vesselTypeDefaultColouring: boolean;
  visibleMmsis: number[];
  visibleTracksIds: string[];
  historicAreaSearchVesselPoints: HistoricAreaSearchVesselPointsState;
  websocketId: string | undefined;
  progressPercent: number;
  receivedAllMessages: boolean;
  endTime: Date | null;
  startTime: Date | null;
  lastReceivedTime: Date | null;
  searchInitiated: boolean;
}

export const HISTORIC_VESSELS_INITIAL_STATE: HistoricVesselsState = {
  historicVessels: null,
  historicVesselsFormValues: defaultValues,
  historicVesselsPoints: [],
  loading: false,
  error: false,
  vesselTypeDefaultColouring: false,
  visibleMmsis: [],
  visibleTracksIds: [],
  historicAreaSearchVesselPoints: {},
  websocketId: undefined,
  progressPercent: 0,
  receivedAllMessages: false,
  endTime: null,
  startTime: null,
  lastReceivedTime: null,
  searchInitiated: false,
};

const historicVesselsSlice = createSlice({
  name: 'historicVessels',
  initialState: HISTORIC_VESSELS_INITIAL_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHistoricVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData | null>
    ) => {
      state.historicVessels = action.payload;
      state.loading = false;
      state.error = false;
    },
    setHistoricVesselsFormValues: (
      state,
      action: PayloadAction<Partial<HistoricVesselsFormValues>>
    ) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        ...action.payload,
      };
    },
    setDates: (state, action: PayloadAction<DateFilters>) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        dateTimeRange: action.payload,
      };
    },
    setSearchArea: (
      state,
      action: PayloadAction<Feature<Point | Polygon> | null>
    ) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        searchArea: action.payload,
      };
    },
    setHistoricVesselsPoints: (
      state,
      action: PayloadAction<Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]>
    ) => {
      state.historicVesselsPoints = action.payload;
      state.loading = false;
      state.error = false;
    },
    appendHistoricVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData>
    ) => {
      const { data: newData } = action.payload;
      if (newData) {
        return {
          ...state,
          historicVessels: {
            ...(state.historicVessels || {}),
            data: {
              ...newData,
              ...(state.historicVessels?.data || {}),
            },
          },
        };
      }
      return state;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setHistoricVesselsColouring: (state, action: PayloadAction<boolean>) => {
      state.vesselTypeDefaultColouring = action.payload;
    },
    setVisibleMmsis: (state, action: PayloadAction<number | null>) => {
      if (!action.payload) {
        state.visibleMmsis = [];
        return;
      }
      const mmsiIndex = state.visibleMmsis.indexOf(action.payload);
      if (mmsiIndex === -1) {
        state.visibleMmsis.push(action.payload);
      } else {
        state.visibleMmsis.splice(mmsiIndex, 1);
      }
    },
    setVisibleTracksIds: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        state.visibleTracksIds = [];
        return;
      }
      const visibleTracksIdIndex = state.visibleTracksIds.indexOf(
        action.payload
      );
      if (visibleTracksIdIndex === -1) {
        state.visibleTracksIds.push(action.payload);
      } else {
        state.visibleTracksIds.splice(visibleTracksIdIndex, 1);
      }
    },
    toggleAllVesselsByMmsi: (state, action: PayloadAction<number[] | null>) => {
      if (action.payload) {
        state.visibleMmsis = action.payload;
      } else {
        state.visibleMmsis = [];
      }
    },
    setHistoricAreaSearchVesselPoints(
      state,
      action: PayloadAction<{ vesselId: string; points: any[] }>
    ) {
      state.historicAreaSearchVesselPoints[action.payload.vesselId] =
        action.payload.points;
    },
    clearHistoricAreaSearchVesselPoints(state) {
      state.historicAreaSearchVesselPoints = {};
    },
    setWebsocketId(state, action: PayloadAction<string | undefined>) {
      state.websocketId = action.payload;
    },
    setProgressPercent(state, action: PayloadAction<number>) {
      state.progressPercent = action.payload;
    },
    setReceivedAllMessages(state, action: PayloadAction<boolean>) {
      state.receivedAllMessages = action.payload;
    },
    setStartTime(state, action: PayloadAction<Date | null>) {
      state.startTime = action.payload;
    },
    setEndTime(state, action: PayloadAction<Date | null>) {
      state.endTime = action.payload;
    },
    setLastReceivedTime(state, action: PayloadAction<Date | null>) {
      state.lastReceivedTime = action.payload;
    },
    resetProgressBarStates(state) {
      state.progressPercent = 0;
      state.receivedAllMessages = false;
      state.startTime = null;
      state.endTime = null;
      state.lastReceivedTime = null;
    },
    setSearchInitiated(state, action: PayloadAction<boolean>) {
      state.searchInitiated = action.payload;
    },
  },
});

export const {
  setLoading,
  setHistoricVessels,
  appendHistoricVessels,
  setHistoricVesselsFormValues,
  setHistoricVesselsPoints,
  setError,
  setDates,
  setSearchArea,
  setHistoricVesselsColouring,
  setVisibleMmsis,
  toggleAllVesselsByMmsi,
  setVisibleTracksIds,
  setHistoricAreaSearchVesselPoints,
  clearHistoricAreaSearchVesselPoints,
  setWebsocketId,
  setProgressPercent,
  setReceivedAllMessages,
  setStartTime,
  setEndTime,
  setLastReceivedTime,
  resetProgressBarStates,
  setSearchInitiated,
} = historicVesselsSlice.actions;

export default historicVesselsSlice.reducer;
