/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateFormikVals } from '../../../fleets.model';

interface CreateFleetsState {
  currentFleets: string[];
  createdFleet: string | null;
  createFormData: CreateFormikVals | null;
  fleetName: string;
  fleetNameError: boolean;
}

export const INITIAL_CREATE_FLEETS_STATE: CreateFleetsState = {
  currentFleets: [],
  createdFleet: null,
  createFormData: null,
  fleetName: '',
  fleetNameError: false,
};

const CreateFleetsDialogSlice = createSlice({
  name: 'fleets',
  initialState: INITIAL_CREATE_FLEETS_STATE,
  reducers: {
    setCurrentFleets: (state, action: PayloadAction<string[]>) => {
      state.currentFleets = action.payload;
    },
    setCreatedFleet: (state, action: PayloadAction<string | null>) => {
      state.createdFleet = action.payload;
    },
    setCreateFormData(state, action: PayloadAction<CreateFormikVals>) {
      state.createFormData = action.payload;
    },
    setFleetName(state, action: PayloadAction<string>) {
      state.fleetName = action.payload;
    },
    setFleetNameError(state, action: PayloadAction<boolean>) {
      state.fleetNameError = action.payload;
    },
    resetCreateState() {
      return INITIAL_CREATE_FLEETS_STATE;
    },
  },
});

export const {
  setCurrentFleets,
  setCreatedFleet,
  setCreateFormData,
  setFleetName,
  setFleetNameError,
  resetCreateState,
} = CreateFleetsDialogSlice.actions;

export default CreateFleetsDialogSlice.reducer;
