import {
  EAreaSymbolizationType,
  EColourScheme,
  EDisplayAioFeatures,
  EDisplayCategory,
  EDisplayFrameText,
  EDisplayFrames,
  EDisplayLightSectors,
  EDisplaySafeSoundings,
  ERemoveDuplicateText,
  ETwoDepthShades,
} from './avcs-config.model';

export interface AvcsConfigFormValues {
  colourScheme: EColourScheme;
  displayAioFeatures: EDisplayAioFeatures[];
  displayCategory: EDisplayCategory[];
  safetyContour: number;
  areaSymbolizationType: EAreaSymbolizationType;
  displayLightSectors: EDisplayLightSectors;
  twoDepthShades: ETwoDepthShades;
  shallowContour: number;
  safetyDepth: number;
  deepContour: number;
  displaySafeSounding: EDisplaySafeSoundings;
  displayFrames: EDisplayFrames;
  displayFrameText: EDisplayFrameText;
  removeDuplicateText: ERemoveDuplicateText;
}

export const defaultAvcsConfigFormValues: AvcsConfigFormValues = {
  colourScheme: EColourScheme.DAY,
  displayAioFeatures: [
    EDisplayAioFeatures.TEMPORARY_NOTICE,
    EDisplayAioFeatures.PRELIMINARY_NOTICE,
    EDisplayAioFeatures.ENC_PRELIMINARY_NOTICE,
    EDisplayAioFeatures.NO_INFORMATION,
  ],
  displayCategory: [EDisplayCategory.DISPLAYBASE, EDisplayCategory.STANDARD],
  safetyContour: 10,
  areaSymbolizationType: EAreaSymbolizationType.PLAIN,
  displayLightSectors: EDisplayLightSectors.ON,
  twoDepthShades: ETwoDepthShades.OFF,
  shallowContour: 2.0,
  safetyDepth: 30.0,
  deepContour: 30.0,
  displaySafeSounding: EDisplaySafeSoundings.ON,
  displayFrames: EDisplayFrames.OFF,
  displayFrameText: EDisplayFrameText.OFF,
  removeDuplicateText: ERemoveDuplicateText.OFF,
};
