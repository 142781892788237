/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  WeatherFormValues,
  WeatherResponse,
} from '../../models/weather/weather.model';

interface WeatherState {
  error: string | null;
  formValues: WeatherFormValues;
  loading: boolean;
  results: WeatherResponse | null;
  success: boolean;
}

export const INITIAL_WEATHER_STATE: WeatherState = {
  error: null,
  formValues: {
    location: {
      latitude: '00° 00\' 00" N',
      longitude: '00° 00\' 00" E',
    },
    startDate: null,
    endDate: null,
  },
  loading: false,
  results: null,
  success: false,
};

const WeatherSlice = createSlice({
  name: 'weather',
  initialState: INITIAL_WEATHER_STATE,
  reducers: {
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.error = null;
      state.loading = false;
    },
    setWeatherFormValues: (state, action: PayloadAction<WeatherFormValues>) => {
      state.formValues = action.payload;
    },
    setWeatherResults: (
      state,
      action: PayloadAction<WeatherResponse | null>
    ) => {
      state.results = action.payload;
    },
  },
});

export const {
  setWeatherFormValues,
  setWeatherResults,
  setError,
  setLoading,
  setSuccess,
} = WeatherSlice.actions;

export default WeatherSlice.reducer;
