/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExpandedIncident,
  Incident,
  IncidentTargetType,
  IncidentType,
} from '../../maritime-menu-options/incidents-panel/incident.model';
import {
  Area,
  Region,
  TimeOfDay,
} from '../../models/risk-intelligence/risk_intelligence.model';
import {
  populateAreasCache,
  populateIncidentTypeCache,
  populateRegionCache,
  populateTargetTypesCache,
} from '../../utils/incidents-helpers';

export type NormalisedIncidents = {
  byId: Record<string, Incident>;
  allIds: Incident['id'][];
};

const normaliseIncidents = (
  incidents: Incident[],
  selectedIncident: Incident | null
) =>
  incidents.reduce<NormalisedIncidents>(
    (acc, incident) => {
      acc.byId[incident.id] = {
        ...incident,
        selected: incident.id === selectedIncident?.id,
      };
      if (incident.id !== selectedIncident?.id) {
        // lets not duplicate the selected incident
        acc.allIds.push(incident.id);
      }
      return acc;
    },
    {
      byId: selectedIncident ? { [selectedIncident.id]: selectedIncident } : {},
      allIds: selectedIncident ? [selectedIncident.id] : [],
    }
  );

interface IncidentsState {
  loading: boolean;
  error: boolean;
  incidents: NormalisedIncidents | null;
  searchIncidents: NormalisedIncidents | null; // search needs to hold all incidents since '06, easier to not pollute the incidents state which includes filters
  incidentTypes: IncidentType[] | null;
  incidentRegions: Region[] | null;
  incidentTimeOfDay: TimeOfDay[] | null;
  incidentAreas: Area[] | null;
  incidentTargetTypes: IncidentTargetType[] | null;
  selectedIncident: Incident | null;
  selectedIncidentId: string | null;
  tickerOpen: boolean;
}

export const INITIAL_INCIDENTS_STATE: IncidentsState = {
  loading: false,
  error: false,
  incidents: null,
  searchIncidents: null,
  incidentTypes: null,
  incidentRegions: null,
  incidentAreas: null,
  incidentTimeOfDay: null,
  incidentTargetTypes: null,
  selectedIncident: null,
  selectedIncidentId: null,
  tickerOpen: false,
};

const IncidentsSlice = createSlice({
  name: 'incidents',
  initialState: INITIAL_INCIDENTS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = false;
    },
    setIncidents: (state, action: PayloadAction<Incident[] | null>) => {
      state.incidents = action.payload
        ? normaliseIncidents(action.payload, state.selectedIncident)
        : null;
      state.loading = false;
      state.error = false;
    },
    setSearchIncidents: (state, action: PayloadAction<Incident[] | null>) => {
      state.searchIncidents = action.payload
        ? normaliseIncidents(action.payload, state.selectedIncident)
        : null;
      state.loading = false;
      state.error = false;
    },
    setIncidentTypes: (state, action: PayloadAction<IncidentType[] | null>) => {
      state.incidentTypes = action.payload;
      populateIncidentTypeCache(action.payload);
    },
    setIncidentRegions: (state, action: PayloadAction<Region[] | null>) => {
      state.incidentRegions = action.payload;
      populateRegionCache(action.payload);
    },
    setIncidentAreas: (state, action: PayloadAction<Area[] | null>) => {
      state.incidentAreas = action.payload;
      populateAreasCache(action.payload);
    },
    setIncidentTargetTypes: (
      state,
      action: PayloadAction<IncidentTargetType[] | null>
    ) => {
      state.incidentTargetTypes = action.payload;
      populateTargetTypesCache(action.payload);
    },
    setExpandedIncident: (state, action: PayloadAction<ExpandedIncident>) => {
      const expandedIncident = action.payload;

      const existingIncident = state.incidents?.byId[expandedIncident.id];

      const mergedIncident = {
        ...(existingIncident || {}),
        ...expandedIncident,
        expanded: true,
      };
      if (state.incidents) {
        state.incidents.byId[expandedIncident.id] = mergedIncident;
      }
      state.selectedIncident = {
        ...mergedIncident,
        selected: true,
      };
    },
    setSelectedIncidentId: (state, action: PayloadAction<string | null>) => {
      state.selectedIncidentId = action.payload;
    },
    setSelectedIncident: (
      state,
      action: PayloadAction<IncidentsState['selectedIncident']>
    ) => {
      const prevSelectedIncidentId = state.selectedIncident
        ? String(state.selectedIncident.id)
        : undefined;
      const newSelectedIncidentId = action.payload
        ? String(action.payload.id)
        : undefined;

      // if a new incident to select is provided, set it (with selected:true)
      state.selectedIncident = action.payload
        ? {
            ...action.payload,
            selected: true,
          }
        : null;

      if (state.incidents) {
        if (newSelectedIncidentId) {
          // Select the new incident
          state.incidents.byId[newSelectedIncidentId] = {
            ...action.payload!,
            selected: true,
          };
        }

        if (
          prevSelectedIncidentId &&
          prevSelectedIncidentId !== newSelectedIncidentId
        ) {
          // The selected incident has changed
          // Deselect the previous incident
          state.incidents.byId[prevSelectedIncidentId] = {
            ...state.incidents.byId[prevSelectedIncidentId],
            selected: false,
          };
        }
      }
    },
    setTickerOpen: (state, action: PayloadAction<boolean>) => {
      state.tickerOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  clearError,
  setIncidents,
  setSearchIncidents,
  setIncidentTypes,
  setIncidentRegions,
  setIncidentAreas,
  setIncidentTargetTypes,
  setExpandedIncident,
  setSelectedIncident,
  setSelectedIncidentId,
  setTickerOpen,
} = IncidentsSlice.actions;

export default IncidentsSlice.reducer;
