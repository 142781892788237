import { useEffect, useState } from 'react';
import { MaritimeArea } from '../models/risk-intelligence/risk_intelligence.model';
import { setBoundaries } from '../state/boundaries/boundaries.slice';
import { setCities } from '../state/cities/cities.slice';
import { setCountries } from '../state/countries/countries.slice';
import { setDocuments } from '../state/documents/documents.slice';
import { setDrawings } from '../state/drawings/drawings.slice';
import { setSearchIncidents } from '../state/incidents/incidents.slice';
import { setPorts } from '../state/ports/ports.slice';
import { setAreas } from '../state/ri-maritime-areas/ri-maritime-areas.slice';
import { setAlertingRoutes } from '../state/routes/routes.slice';
import { setMyFleet } from '../state/vessels/vessels.slice';
import store from '../store';
import TenantId from '../tenant';
import { fetchAndCombineBoundaries } from './boundaries';
import { getS3DocsWithMetadata } from './documents';
import { getDrawings } from './drawings';
import { getCities } from './risk-intelligence/cities';
import { getCountries } from './risk-intelligence/countries';
import { getIncidents, getMaritimeAreas } from './risk-intelligence/incidents';
import { getMergedPorts } from './risk-intelligence/ports';
import { getAlertingRoutesForCompany } from './routes';
import {
  getFleetWithLocationsForCompany,
  getRiskIntelligenceFleetForCompany,
  loadAlertingVesselsWithLocations,
} from './vessels';

export * from './risk-intelligence/cities';
export * from './risk-intelligence/countries';
export * from './risk-intelligence/incidents';
export * from './risk-intelligence/ports';
export * from './risk-intelligence/threat-assessments';

export * from './admin/companies';
export * from './admin/users';

export * from './documents';
export * from './feedback';
export * from './routes';

export * from './boundaries';
export * from './drawings';
export * from './mapbox';

export * from './sanctions';

export * from './user-preferences';

export * from './vessels';

export enum ReduxStatesToVerify {
  INCIDENTS = 'incidents',
  VESSELS = 'vessels',
  PORTS = 'ports',
  CITIES = 'cities',
  COUNTRIES = 'countries',
  DRAWINGS = 'drawings',
  BOUNDARIES = 'boundaries',
  DOCUMENTS = 'documents',
  MARITIME_AREAS = 'maritimeAreas',
  ALERTING_VESSELS = 'alertingVessels',
  ALERTING_ROUTES = 'alertingRoutes',
}

interface LoadingOptions {
  before?: () => void;
  after?: () => void;
}

// This function is used to ensure that the redux store is populated with the data needed for state-restoring
// or application-wide search.
// example:
//    await ensureReduxLoaded(['incidents', 'vessels', 'ports'])
//    // do stuff that needs those states
// WIP: This function is not complete. The other branches of the switch statement need to be implemented.
export const useEnsureReduxLoaded = (
  statesToVerify: ReduxStatesToVerify[],
  options?: LoadingOptions
) => {
  const { before, after } = options || {};
  const reduxState = store.getState();
  const [loadingCompleteState, setLoadingCompleteState] = useState<
    Record<Partial<ReduxStatesToVerify>, boolean>
  >(
    statesToVerify.reduce((acc, state) => {
      acc[state] = false;
      return acc;
    }, {} as Record<Partial<ReduxStatesToVerify>, boolean>)
  );

  const handleLoadingComplete = (state: ReduxStatesToVerify) => {
    setLoadingCompleteState((prevState) => ({
      ...prevState,
      [state]: true,
    }));
  };

  useEffect(() => {
    before?.();
    statesToVerify.forEach((state) => {
      switch (state) {
        case ReduxStatesToVerify.INCIDENTS: {
          const { searchIncidents } = reduxState.incidents;
          if (!searchIncidents) {
            // getIncidents with no filters
            getIncidents({ ignoreAllFilters: true }).then((incidents) => {
              store.dispatch(setSearchIncidents(incidents));
              handleLoadingComplete(state);
              return incidents;
            });
            break;
          }
          // Incidents already loaded, simply return them
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.VESSELS: {
          const reduxVessels = reduxState.myFleet.myFleet;
          if (!reduxVessels || reduxVessels.allIds.length === 0) {
            const fleetPromises = [];
            fleetPromises.push(
              getFleetWithLocationsForCompany(
                reduxState.user.idToken!.companyId
              )
            );
            if (
              reduxState.user.idToken!.tenantId === TenantId.RISK_INTELLIGENCE
            ) {
              fleetPromises.push(
                getRiskIntelligenceFleetForCompany(
                  reduxState.user.idToken!.companyId
                )
              );
            }
            Promise.all(fleetPromises).then((responses) => {
              store.dispatch(setMyFleet(responses.flat()));
              handleLoadingComplete(state);
              return responses.flat();
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.PORTS: {
          if (!reduxState.ports.ports || reduxState.ports.ports.length === 0) {
            getMergedPorts(reduxState.user.idToken).then((ports) => {
              store.dispatch(setPorts(ports));
              handleLoadingComplete(state);
              return ports;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.CITIES: {
          if (!reduxState.cities.cities) {
            getCities().then((cities) => {
              store.dispatch(setCities(cities));
              handleLoadingComplete(state);
              return cities;
            });

            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.COUNTRIES: {
          if (!reduxState.countries.countries) {
            getCountries().then((countries) => {
              store.dispatch(setCountries(countries));
              handleLoadingComplete(state);
              return countries;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.DRAWINGS: {
          if (
            !reduxState.drawings.drawings ||
            reduxState.drawings.drawings.length === 0
          ) {
            getDrawings(reduxState.user.idToken!.sub).then((drawings) => {
              store.dispatch(setDrawings(drawings));
              handleLoadingComplete(state);
              return drawings;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.BOUNDARIES: {
          if (!reduxState.boundaries.boundaries) {
            fetchAndCombineBoundaries()
              .then((response) => {
                store.dispatch(setBoundaries(response.boundaries));
                handleLoadingComplete(state);
                return response.boundaries;
              })
              .catch(() => {
                handleLoadingComplete(state);
              });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.DOCUMENTS: {
          // Refresh documents every time, in case a user was last looking
          // at a specific entity (and as such the documents would be filtered)
          getS3DocsWithMetadata().then(
            ({ myDocuments, organisationDocuments, tenantDocuments }) => {
              store.dispatch(
                setDocuments({
                  myDocuments,
                  organisationDocuments,
                  tenantDocuments,
                })
              );
              handleLoadingComplete(state);
            }
          );
          break;
        }
        case ReduxStatesToVerify.MARITIME_AREAS: {
          if (!reduxState.riMaritimeAreas.areas) {
            getMaritimeAreas()
              .then((areas: MaritimeArea[]) => {
                store.dispatch(setAreas(areas));
                handleLoadingComplete(state);
                return areas;
              })
              .catch(() => handleLoadingComplete(state));
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.ALERTING_VESSELS: {
          if (reduxState.alertingVessels.alertingVessels === null) {
            loadAlertingVesselsWithLocations(reduxState.user.idToken);
            handleLoadingComplete(state);
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.ALERTING_ROUTES: {
          if (reduxState.routes.alertingRoutes === null) {
            getAlertingRoutesForCompany().then((response) => {
              store.dispatch(setAlertingRoutes(response));
            });
            handleLoadingComplete(state);
          }
          handleLoadingComplete(state);
          break;
        }
        default:
          throw new Error(`Unknown redux to check for: ${state}`);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.values(loadingCompleteState).every((value) => value)) {
      after?.();
    }
  }, [loadingCompleteState]);

  return loadingCompleteState;
};
