/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Country,
  MapCountry,
} from '../../models/risk-intelligence/risk_intelligence.model';

interface CountriesStateType {
  countries: Country[] | null;
  selectedCountry: null | MapCountry;
  selectedCountryId: null | number | string;
  countriesLoading: boolean;
  countryLoading: boolean;
  error: boolean;
}

export const INITIAL_COUNTRIES_STATE: CountriesStateType = {
  countries: null,
  selectedCountry: null,
  selectedCountryId: null,
  countriesLoading: false,
  countryLoading: false,
  error: false,
};

const CountriesSlice = createSlice({
  name: 'countries',
  initialState: INITIAL_COUNTRIES_STATE,
  reducers: {
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
      state.countriesLoading = false;
      state.error = false;
    },
    setSelectedCountry: (state, action: PayloadAction<MapCountry | null>) => {
      state.countryLoading = false;
      state.error = false;
      state.selectedCountry = action.payload;
    },
    setSelectedCountryId: (
      state,
      action: PayloadAction<number | string | null>
    ) => {
      state.selectedCountryId = action.payload;
    },
    setCountryLoading: (state) => {
      state.error = false;
      state.countryLoading = true;
    },
    setCountriesLoading: (state, action: PayloadAction<boolean>) => {
      state.countriesLoading = action.payload;
      state.error = false;
    },
    setError: (state) => {
      state.countriesLoading = false;
      state.countryLoading = false;
      state.error = true;
    },
  },
});

export const {
  setSelectedCountry,
  setSelectedCountryId,
  setCountriesLoading,
  setCountryLoading,
  setError,
  setCountries,
} = CountriesSlice.actions;

export default CountriesSlice.reducer;
