import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { Privileges, calculatePrivileges } from './privileges';

const useAccessControl = (): Privileges => {
  const idToken = useAppSelector((state) => state.user.idToken);
  const userFeatureFlags = useAppSelector((state) => state.user.featureFlags);
  const permissionToggles = useAppSelector(
    (state) => state.userPermissions.permissions
  );

  const privileges = useMemo(
    () =>
      calculatePrivileges({
        idToken,
        userFeatureFlags,
        permissionToggles,
      }),
    [idToken, userFeatureFlags, permissionToggles]
  );

  return privileges;
};

export default useAccessControl;
