import { FormikErrors, FormikValues } from 'formik';
import sanitizeHtml from 'sanitize-html';

// In order to follow mapbox image-icon naming convention, convert to kebab
// trim whitespace around, toLower, turn multiple whitespaces into one, replace whitespace with -
// can also perform snake_case if provided with a different splitter, or remove all non-alphanumeric characters entirely.
export const kebabify = (unsantisedString: string, splitter = '-') =>
  unsantisedString
    .trim()
    .toLowerCase()
    .replaceAll(/'/g, '')
    .replaceAll(/\W+/g, ' ')
    .replaceAll(/[^a-z0-9]/g, splitter);

/**
 * Displays a number, respecting the users locale, with differing
 * decimal places depending on the size of the number.
 * @param n
 * @returns
 */
export const neatDisplayNumber = (n: number | undefined) => {
  if (!n) {
    return '';
  }
  return n.toLocaleString(undefined, {
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 3,
  });
};

export const titleCase = (string: string) =>
  string
    .split(/\s+/)
    .map((substr) =>
      substr
        .toLocaleLowerCase()
        .replace(/(\b[a-z](?!\s))/g, (x) => x.toLocaleUpperCase())
    )
    .join(' ');

export const isValidUrl = (url: string) => {
  try {
    return !!new URL(url);
  } catch (e) {
    return false;
  }
};

export const removeLineBreaksAndTrim = (str: string | undefined | null) => {
  if (!str) {
    return undefined;
  }
  return str.replace(/\n/g, '').trim();
};

export const sanitiseBlock = (block: string, allowedTags: string[]) =>
  sanitizeHtml(block, {
    allowedTags,
    allowedAttributes: {
      a: [
        'href', // allow any hrefs
        'target',
        'class',
      ],
    },
    transformTags: {
      a(tagName, attribs) {
        return {
          tagName: 'a',
          attribs: {
            ...attribs,
            target: '_blank',
            class: 'riAnchor',
          },
        };
      },
    },
  });

export const getFilenameFromDirectory = (filename: string) =>
  filename.split('/').pop();

/**
 * Given a String that may be constructed of many words, return how closely the query matches.
 * eg) 'hello world' with query 'he' returns 0.5, partial substring match
 * eg) 'hello world' with query 'world' returns 0.9, exact substring match
 * eg) 'Port of Memphis' with query 'phis' returns 0
 * numbers used are arbitrary, but should be between 0 and 1
 * @param str
 * @param query
 */
export const matchQuality = (str: string | undefined, query: string) => {
  if (!str) {
    return 0;
  }
  if (str.toLowerCase() === query.toLowerCase()) {
    // exact match 'new york' in 'New York'
    return 1;
  }
  const words = str.split(' ');
  if (words.some((word) => word.toLowerCase() === query.toLowerCase())) {
    // Exact subword match 'york' in 'New York'
    return 0.9;
  }

  // match provided text, but only if it starts with a word boundary. eg, 'rk' will not match 'New York' but 'york' or 'new yo' will
  const regex = new RegExp(`\\b${query}`, 'i');
  if (str.match(regex)) {
    return 0.8;
  }

  if (
    words.some((word) => word.toLowerCase().startsWith(query.toLowerCase()))
  ) {
    // partial subword match 'yo' in 'New York'
    return 0.5;
  }
  return 0;
};

export const removeWhiteSpace = (str: string) => {
  const whiteSpaceRegex = /\s|\r|\n/g;
  return str.split(whiteSpaceRegex).filter((char) => char !== '');
};

/**
 * Formik errors, especially for arrays of objects, can be quite complex.
 * @param error
 */
export const stringifyFormikError = <T extends FormikValues>(
  errors: FormikErrors<T> | undefined,
  name: string,
  index?: number,
  options: {
    valueOnly?: boolean;
  } = {}
): string | null => {
  if (!errors) {
    return null;
  }
  if (!errors[name]) {
    return null;
  }
  if (typeof errors[name] === 'object') {
    if (Array.isArray(errors[name])) {
      const arrayResult = errors[name] as Omit<FormikErrors<T>, string>[];
      if (index === undefined) {
        return JSON.stringify(arrayResult);
      }
      if (arrayResult[index]) {
        const arrayContents = arrayResult[index];
        if (typeof arrayContents === 'object') {
          // if the array contents are an object, we want to display the key value pairs
          return Object.entries(arrayContents)
            .map(([key, value]) =>
              options.valueOnly ? value : `${key}: ${value}`
            )
            .join(', ');
        }
        if (typeof arrayContents === 'string') {
          return arrayContents;
        }
        return JSON.stringify(arrayResult[index]);
      }
    }
    return JSON.stringify(errors[name]);
  }
  return errors[name] as string;
};
