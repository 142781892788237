/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeOptions } from '@mui/material';

const defaultTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1650, // all default except xl
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderColor: theme.palette.primary.main,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          scrollbarWidth: 'thin',
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '0',
          color: theme.palette.text.primary,
        }),
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.paper,
          '&:hover': theme.palette.background.paper,
        }),
        expandIconWrapper: {
          color: 'text.light',
        },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'contained' && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
            },
          }),
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: 'grey',
        },
        track: ({ ownerState, theme }) => ({
          opacity: 0.4,
          backgroundColor: theme.palette.error.light,
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.8,
            backgroundColor: theme.palette.success.main,
          },
        }),
      },
    },
  },

  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '22px',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
};

export default defaultTheme;
