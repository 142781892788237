import { FeatureCollection, Point, Position } from '@turf/helpers';
import MapHelpers from '../map/map.utils';

export function pointFeatureCollection(
  coordinates: Position[] = [],
  labels: string[] = []
): FeatureCollection<Point> {
  return {
    type: 'FeatureCollection',
    features: coordinates.map((c, i) => ({
      type: 'Feature',
      properties: {
        text: labels[i],
      },
      geometry: {
        type: 'Point',
        coordinates: c,
      },
    })),
  };
}

export const removeSourceIfExists = (sourceId: string) => {
  if (MapHelpers.getSource(sourceId)) {
    MapHelpers.removeSource(sourceId);
  }
};

export const removeLayerIfExists = (layerId: string) => {
  if (MapHelpers.getLayer(layerId)) {
    MapHelpers.removeLayer(layerId);
  }
};

export const addEndMarkerSourceAndLayer = (sourceLayer: string) => {
  const source = MapHelpers.getSource(sourceLayer);

  // Don't do anything if the source already exists
  if (source) return;

  MapHelpers.addSource(sourceLayer, {
    type: 'geojson',
    data: pointFeatureCollection([], []),
  });
  MapHelpers.addLayer({
    id: sourceLayer,
    type: 'symbol',
    source: sourceLayer,
    layout: {
      'text-field': '{text}',
      'text-font': ['Roboto Medium'],
      'text-anchor': 'top',
      'text-size': 14,
      'text-offset': [0, 0.8],
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#000',
      'text-halo-color': '#fff',
      'text-halo-width': 1,
    },
  });
};
