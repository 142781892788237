/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertingVessels } from '../../maritime-menu-options/alert-panel/alertingVessels.model';
import { Vessel } from '../../models/vessels/vessel.model';

export interface AlertingVessel extends Vessel {
  additionalDetails?: {
    vessel: {
      imo?: number | string;
      vessel_id: string;
      company_id: string;
      vessel_name: string;
      vessel_type: string;
      current_mmsi?: number | string;
    };
    destination_new_static?: string;
    destination_old_static?: string;
    name_new_static?: string;
    name_old_static?: string;
    flag_new_static?: string;
    flag_old_static?: string;
    draught_new_static?: string;
    draught_old_static?: string;
  };
}

type AlertingVesselsState = {
  loading: boolean;
  error: boolean;
  alertingVessels: null | AlertingVessels[];
  selectedPolygon: {
    feature: GeoJSON.Polygon;
    layer: string;
  } | null;
  selectedAlertId: string | null;
  selectedAlertVessel: AlertingVessel | null;
  alertDefaultColouring: boolean;
};

export const INITIAL_ALERTING_VESSELS_STATE: AlertingVesselsState = {
  loading: false,
  error: false,
  alertingVessels: null,
  selectedPolygon: null,
  selectedAlertId: null,
  selectedAlertVessel: null,
  alertDefaultColouring: false,
};

const AlertingVesselsSlice = createSlice({
  name: 'alertingVessels',
  initialState: INITIAL_ALERTING_VESSELS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setAlertingVessels: (state, action) => {
      state.alertingVessels = action.payload;
      state.loading = false;
      state.error = false;
    },
    setSelectedAlertPolygon: (
      state,
      action: PayloadAction<AlertingVesselsState['selectedPolygon']>
    ) => {
      state.selectedPolygon = action.payload;
    },
    setSelectedAlertId: (state, action: PayloadAction<string | null>) => {
      state.selectedAlertId = action.payload;
    },
    setSelectedAlertVessel: (state, action) => {
      state.selectedAlertVessel = action.payload;
    },
    setAlertDefaultColouring: (state, action: PayloadAction<boolean>) => {
      state.alertDefaultColouring = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  setAlertingVessels,
  setSelectedAlertPolygon,
  setSelectedAlertId,
  setSelectedAlertVessel,
  setAlertDefaultColouring,
} = AlertingVesselsSlice.actions;

export default AlertingVesselsSlice.reducer;
