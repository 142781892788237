namespace PopupHelpers {
  interface InfoCard {
    label: string;
    value: string | Number | undefined;
  }

  export const createInfoCard = (
    popup: mapboxgl.Popup,
    info: InfoCard[],
    header?: string
  ) => {
    const body = info
      .slice(1)
      .map((section) => {
        const { label, value } = section;
        return label !== ''
          ? `<div class="mapboxgl-popup-property">${
              label && `<b>${label} </b>`
            }${value || 'Unavailable'}</div>`
          : '';
      })
      .join('');

    const html = `${
      header ? `<div class="mapboxgl-popup-content-header">${header}</div>` : ''
    }<div class="mapboxgl-popup-name">${
      info[0].value || 'Unavailable'
    }</div><div class="mapboxgl-popup-properties">${body}</div>`;

    popup.setHTML(html);
  };
}

export default PopupHelpers;
