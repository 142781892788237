import {
  PermissionToggleNames,
  PermissionToggles,
} from '../../admin/admin-page/admin.slice';
import TenantId from '../../tenant';

export enum EAlertTypes {
  AIS_OFF = 'AIS_OFF',
  AIS_ON = 'AIS_ON',
  ENTER_ROI = 'ENTER_ROI',
  EXIT_ROI = 'EXIT_ROI',
  STATIC_CHANGE = 'STATIC_CHANGE',
  STS = 'STS',
  INCIDENTS = 'INCIDENTS',
  INCIDENTS_ROUTE = 'INCIDENTS_ROUTE',
  ENTER_PORT = 'ENTER_PORT',
  DEP_PORT = 'DEP_PORT',
}

export type AlertPermissionsMapping = Record<
  EAlertTypes,
  {
    allOf: (keyof typeof PermissionToggleNames)[];
    oneOf: (keyof typeof PermissionToggleNames)[];
  }
>;

export const ALERT_PERMISSIONS_MAPPING: AlertPermissionsMapping = {
  [EAlertTypes.AIS_OFF]: {
    allOf: ['ais_on_off', 'fleets'],
    oneOf: [
      /*
      The 'Global' geometry option is always available for AIS_OFF alerts
      And so should always be available as long as the all-required permissions
      are enabled.
      'drawings', 'boundaries', 'ri_areas'
      */
    ],
  },
  [EAlertTypes.AIS_ON]: {
    allOf: ['ais_on_off', 'fleets'],
    oneOf: [
      /*
      The 'Global' geometry option is always available for AIS_ON alerts
      And so should always be available as long as the all-required permissions
      are enabled.
      'drawings', 'boundaries', 'ri_areas'
      */
    ],
  },
  [EAlertTypes.ENTER_PORT]: {
    allOf: ['port_enter_exit', 'fleets'],
    oneOf: ['wpi_ports'],
  },
  [EAlertTypes.DEP_PORT]: {
    allOf: ['port_enter_exit', 'fleets'],
    oneOf: ['wpi_ports'],
  },
  [EAlertTypes.ENTER_ROI]: {
    allOf: ['aoi_enter_exit', 'fleets'],
    oneOf: ['drawings', 'boundaries', 'ri_areas'],
  },
  [EAlertTypes.EXIT_ROI]: {
    allOf: ['aoi_enter_exit', 'fleets'],
    oneOf: ['drawings', 'boundaries', 'ri_areas'],
  },
  [EAlertTypes.INCIDENTS]: { allOf: ['incidents', 'fleets'], oneOf: [] },
  [EAlertTypes.INCIDENTS_ROUTE]: {
    allOf: ['incidents_on_route', 'ri_incidents', 'routes'],
    oneOf: [],
  },
  [EAlertTypes.STS]: {
    allOf: ['sts', 'fleets'],
    oneOf: ['drawings', 'boundaries', 'ri_areas'],
  },
  [EAlertTypes.STATIC_CHANGE]: {
    allOf: ['static_changes', 'fleets'],
    oneOf: [],
  },
};

type PermissionsToAlertsMapping = Partial<
  Record<keyof PermissionToggles, (keyof AlertPermissionsMapping)[]>
>;

export const PERMISSIONS_ALERTS_MAPPING: PermissionsToAlertsMapping =
  Object.entries(ALERT_PERMISSIONS_MAPPING).reduce(
    (acc, [alertType, { allOf, oneOf }]) => {
      const permissions = [...allOf, ...oneOf];
      permissions.forEach((permission) => {
        if (!acc[permission]) {
          acc[permission] = [];
        }
        acc[permission]!.push(alertType as EAlertTypes);
      });
      return acc;
    },
    {} as PermissionsToAlertsMapping
  );

export const DEFAULT_ALERTS = [
  EAlertTypes.AIS_OFF,
  EAlertTypes.AIS_ON,
  EAlertTypes.DEP_PORT,
  EAlertTypes.ENTER_PORT,
  EAlertTypes.ENTER_ROI,
  EAlertTypes.EXIT_ROI,
  EAlertTypes.INCIDENTS_ROUTE,
  EAlertTypes.STATIC_CHANGE,
  EAlertTypes.STS,
];

export const AlertTypesByTenant: {
  [key in TenantId]: EAlertTypes[];
} = {
  [TenantId.RISK_INTELLIGENCE]: [
    EAlertTypes.INCIDENTS,
    EAlertTypes.INCIDENTS_ROUTE,
    EAlertTypes.ENTER_ROI,
  ],
  [TenantId.GEOLLECT]: DEFAULT_ALERTS,
};

export interface AlertNotificationPreferences {
  alert_config_id: string;
  company_id: string;
  region_id: string | null;
  alert_modifiers: Record<string, any> | null;
  alert_type: EAlertTypes;
  ports: AlertPortModifiers | null;
  email_frequency: number | string | null;
  notification_last_sent_time: Date | string | null;
  name: string | null;
  description: string | null;
}

export interface SendingAlertNotificationPreferences {
  alert_config_id: string;
  email_frequency: number | string | null;
  notification_last_sent_time: string | null;
}

export interface AlertPortModifiers {
  filter_type: string;
  country_codes?: string[] | null;
  locodes?: string[] | null;
}

export interface AlertConfiguration {
  alert_config_id: string | null;
  alert_type: EAlertTypes;
  company_id: string;
  alert_modifiers: Record<string, any> | null;
  boundary_id: string | null;
  region_id: string | null;
  ports: AlertPortModifiers | null;
  name: string;
  description: string;
  email_from_address: string;
}

export interface DeleteAlertConfiguration {
  alert_config_id: string;
  company_id: string;
}

export enum GeometryType {
  RI_AREA = 'RI Maritime Area',
  BOUNDARY = 'Boundary',
  DRAWING = 'Drawing',
  GLOBAL = 'Global',
}
