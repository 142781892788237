import defaultTheme from './default-theme';
import roke from './roke-colour-palette';

const geollectTheme = {
  ...defaultTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: roke.neutral2.n50, // #F3F3F3
      dark: roke.primary.p100, // #6F7A96
      contrastText: roke.neutral1.n50, // #FFFFFF
      icon: roke.neutral2.n50, // #F3F3F3
      light: roke.primary.p100, // #6F7A96
    },
    secondary: {
      light: roke.primary.p400, // #2A395C
      contrastText: roke.primary.p500, // #12284C
      main: roke.primary.p400, // #2A395C
    },
    background: {
      default: roke.primary.p500,
      paper: roke.primary.p500, // #12284C
      menu: roke.primary.p500, // #12284C
      menuBorder: roke.secondary.s700, // #54A037
      light: roke.highlight.h400, // #7EC5D0
    },
    text: {
      primary: roke.neutral2.n50, // #F3F3F3
      secondary: roke.neutral2.n400, // #B5B5B5
      light: roke.neutral1.n50, // #FFFFFF
    },
    error: {
      main: roke.error.e300, // #E7888F
      light: roke.error.e300, // #E7888F
    },
    success: {
      main: roke.secondary.s500, // #6CC34A
      light: roke.tertiary.t400, // #396A9A
      dark: roke.primary.p400, // #2A395C
    },
    divider: roke.secondary.s700, // #54A037
    white: '#fff',
    black: '#000',
  },
  shape: {
    borderRadius: 1.5,
  },
};

export default geollectTheme;
