/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import subYears from 'date-fns/subYears';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import { Incident, IncidentFilters } from './incident.model';

export type NormalisedIncidents = {
  byId: Record<string, Incident>;
  allIds: Incident['id'][];
};

interface IncidentsPanelState {
  filters: IncidentFilters;
}

export const defaultIncidentFilters: IncidentFilters = {
  incidentTypes: [],
  regions: [],
  timeOfDay: [],
  areas: [],
  place: [],
  targets: [],
  startDate: DateTimeHelpers.dateToIsoDate(subYears(new Date(), 1)),
  endDate: DateTimeHelpers.dateToIsoDate(new Date()),
  datePreset: null,
  open: false,
  geo: null,
  alerts: [],
};

export const INITIAL_INCIDENTS_PANEL_STATE: IncidentsPanelState = {
  filters: defaultIncidentFilters,
};

const IncidentsPanelSlice = createSlice({
  name: 'incidentsPanel',
  initialState: INITIAL_INCIDENTS_PANEL_STATE,
  reducers: {
    setIncidentFilters: (
      state,
      action: PayloadAction<Partial<IncidentFilters> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function

export const { setIncidentFilters } = IncidentsPanelSlice.actions;

export default IncidentsPanelSlice.reducer;
