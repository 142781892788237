import { getDrawings } from '../../api';
import addDrawingLayer from '../../map/map-layer-manager/drawings-utils/drawing-layer-handler';
import MapHelpers from '../../map/map.utils';
import { Drawing, DrawingShape } from '../../models/drawings/drawings.model';
import { setDrawings, setError } from '../../state/drawings/drawings.slice';
import store from '../../store';
import GeoHelper from '../../utils/geo-helpers.utils';

export const updateDrawings = async (cognitoId: string) =>
  getDrawings(cognitoId)
    .then((fetchedDrawings) => {
      fetchedDrawings.forEach((drawing) => {
        addDrawingLayer(drawing.region_id, drawing.region_data);
      });

      store.dispatch(setDrawings(fetchedDrawings));
    })
    .catch(() => {
      store.dispatch(setError());
    });

export const goToDrawing = (drawing: Drawing) => {
  const geojson =
    drawing.region_data.properties?.subType === DrawingShape.Circle
      ? GeoHelper.convertFeatureCircleToPolygon(
          drawing.region_data as GeoJSON.Feature<GeoJSON.Point>
        )
      : drawing.region_data;

  // if the drawingShape is Point, the zoom-to continues infinitely
  const options =
    drawing.region_data.geometry?.type === DrawingShape.Point
      ? { maxZoom: 6 }
      : undefined;

  MapHelpers.zoomToFeatureCollection(geojson, options);
  MapHelpers.setLayerVisibility(drawing.region_id, true);
};
