import { EAlertTypes } from '../../models/alerts/alert-configuration';

export const AlertLabels: { [key in EAlertTypes]: string } = {
  [EAlertTypes.AIS_OFF]: 'AIS Off',
  [EAlertTypes.AIS_ON]: 'AIS Back On',
  [EAlertTypes.ENTER_ROI]: 'Entered Region of Interest',
  [EAlertTypes.EXIT_ROI]: 'Exited Region of Interest',
  [EAlertTypes.STATIC_CHANGE]: 'Static Changes',
  [EAlertTypes.STS]: 'Ship-to-ship Transfer',
  [EAlertTypes.INCIDENTS]: 'Proximity to Incident',
  [EAlertTypes.INCIDENTS_ROUTE]: 'Incidents on Route',
  [EAlertTypes.ENTER_PORT]: 'Entered Port',
  [EAlertTypes.DEP_PORT]: 'Departed Port',
};

export const AlertCode: { [key in EAlertTypes]: string } = {
  [EAlertTypes.AIS_OFF]: '01D',
  [EAlertTypes.AIS_ON]: 'ABO',
  [EAlertTypes.ENTER_ROI]: 'ERI',
  [EAlertTypes.EXIT_ROI]: 'XRI',
  [EAlertTypes.STATIC_CHANGE]: 'CAS',
  [EAlertTypes.STS]: 'STS',
  [EAlertTypes.INCIDENTS]: 'INC',
  [EAlertTypes.INCIDENTS_ROUTE]: 'IOR',
  [EAlertTypes.ENTER_PORT]: 'PCS',
  [EAlertTypes.DEP_PORT]: 'XPI',
};

export const AlertCircleStyle: {
  [key in EAlertTypes]: React.CSSProperties;
} = {
  [EAlertTypes.AIS_OFF]: { backgroundColor: '#2500d0', color: 'white' },
  [EAlertTypes.AIS_ON]: { backgroundColor: '#006a38', color: 'white' },
  [EAlertTypes.ENTER_ROI]: { backgroundColor: '#ff15b8' },
  [EAlertTypes.EXIT_ROI]: { backgroundColor: '#ff9a98' },
  [EAlertTypes.STATIC_CHANGE]: { backgroundColor: '#c1f26b' },
  [EAlertTypes.STS]: { backgroundColor: '#ff7c1b' },
  [EAlertTypes.INCIDENTS]: { backgroundColor: '#12b83e' },
  [EAlertTypes.INCIDENTS_ROUTE]: { backgroundColor: '#ffeb3b' },
  [EAlertTypes.ENTER_PORT]: { backgroundColor: '#1cacff' },
  [EAlertTypes.DEP_PORT]: { backgroundColor: '#9a4ee6' },
};

export default AlertLabels;
