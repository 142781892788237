import {
  Country,
  ExpandedCountry,
} from '../../models/risk-intelligence/risk_intelligence.model';
import { wrapRequest } from '../base';

export const getCountries = async (): Promise<Country[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/countries');

export const getCountry = async (
  countryId: string | number
): Promise<ExpandedCountry> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/countries/${countryId}`);
