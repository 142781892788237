/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SubSeaInfraToggles {
  toggleTelecomms: boolean;

  togglePower: boolean;

  togglePipes: boolean;
}

interface SubSeaInfraState {
  loading: boolean;
  error: boolean;
  subSeaInfraToggles: SubSeaInfraToggles;
}

export const SUBSEA_INFRA_TOGGLES = 'subSeaInfraToggles';

export const INITIAL_SUBSEA_INFRA_STATE: SubSeaInfraState = {
  loading: false,
  error: false,
  subSeaInfraToggles: JSON.parse(
    localStorage.getItem('subSeaInfraToggles') ||
      JSON.stringify({
        toggleTelecomms: false,
        togglePower: false,
        togglePipes: false,
      })
  ),
};

export const SubSeaInfraSlice = createSlice({
  name: 'subSeaInfra',
  initialState: INITIAL_SUBSEA_INFRA_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setSubSeaInfraToggles: (
      state,
      action: PayloadAction<Partial<SubSeaInfraToggles>>
    ) => {
      state.subSeaInfraToggles = {
        ...state.subSeaInfraToggles,
        ...action.payload,
      };
      localStorage.setItem(
        'subSeaInfraToggles',
        JSON.stringify(state.subSeaInfraToggles)
      );
    },
  },
});

export const { setLoading, setError, setSubSeaInfraToggles } =
  SubSeaInfraSlice.actions;

export default SubSeaInfraSlice.reducer;
