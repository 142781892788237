import { UserPreferences } from '../../../user-settings/user-preferences/user-preferences.slice';
import { GENERIC_VESSEL } from '../../../utils/vessel-constants.utils';
import prepareVesselStylingData from '../../../utils/vessel-styling.utils';
import MapLayerIcon from '../map-layer-icon';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';

function addAlertingVesselLayerToMap(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata: object = {},
  userPreferences?: UserPreferences
) {
  const { label, size } = prepareVesselStylingData(userPreferences!);

  MapLayerManager.AddLayerinGroups(
    {
      ...GENERIC_VESSEL,
      id: layer,
      source: layer,
      metadata,
      layout: {
        ...GENERIC_VESSEL.layout,
        visibility,
        'text-field': label,
        'icon-image': MapLayerIcon.PLAIN_VESSEL.ALERT,
        'icon-size': size,
      },
    },
    [MapGroupLayer.ALERTS]
  );
}

export default addAlertingVesselLayerToMap;
