import { FailedUser, User } from '../../admin/admin-page/admin.slice';
import { wrapRequest } from '../base';

export const getUsers = async (companyId: string): Promise<User[]> =>
  wrapRequest('get', 'geonius', '/users', {
    queryStringParameters: {
      company_id: companyId,
    },
  });

export const deleteUsers = async (
  usernames: string[],
  company_id: string | undefined
): Promise<'Successfully deleted User(s).' | 'Failed to delete User(s).'> => {
  const body: any = {
    usernames: usernames.map((username) => ({ username })),
  };
  if (company_id) {
    body.company_id = company_id;
  }
  return wrapRequest('del', 'geonius', '/users', {
    body,
  });
};

export const resetUsersPassword = async (
  usernames: string[]
): Promise<
  { failed_users: FailedUser[] } | 'All Passwords reset successfully'
> => {
  const body = { usernames };
  return wrapRequest('patch', 'geonius', '/users/password', {
    body,
  });
};

export interface PostUsersBody {
  company_id: string;
  tenant_id: string;
  users: {
    first_name: string;
    last_name: string;
    email: string;
  }[];
}

export const postUsers = async (
  usersBody: PostUsersBody
): Promise<{ failed_users: FailedUser[] } | 'Successfully created User(s)'> =>
  wrapRequest('post', 'geonius', '/users', {
    body: { data: usersBody },
  });

export const updateUserGroup = async (username: string, newGroup: string) =>
  wrapRequest('patch', 'geonius', `/user/group`, {
    body: {
      username,
      group: newGroup,
    },
  });

export interface MessageBody {
  tenant_id: string;
  to: string[];
  subject: string;
  message_content: string;
  attachments?: File[];
}

export const messageUsers = async (values: MessageBody) =>
  wrapRequest('post', 'geonius', `/users/message`, {
    body: {
      ...values,
    },
  });
