/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import { BBox } from 'geojson';
import DateFilters from '../../common-components/date-filter/date-filter.model';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import { ESAItemData, EsaVisibility } from './esa-item/esa-item.model';
import { EsaFilters, ProcessApiAuthToken } from './esa.model';

interface ItemsImageState {
  [itemId: string]: {
    [key: string]: boolean;
  };
}

interface AcquiredImages {
  [itemId: string]: string[];
}

interface SelectedState {
  [itemId: string]: string;
}

interface EsaPanelState {
  filters: EsaFilters;
  loading: boolean;
  imageLoading: string | null;
  esaError: boolean;
  esaErrorMessage: string | null;
  esaPage: number;
  esaData: ESAItemData[] | null;
  esaVisibleImageLayerIds: string[];
  processApiAuthToken: ProcessApiAuthToken;
  itemsVisibility: ItemsImageState;
  itemsSelected: SelectedState;
  acquiredImages: AcquiredImages;
}

export const defaultEsaFilters: EsaFilters = {
  searchArea: null,
  startDate: DateTimeHelpers.dateToIsoDate(subMonths(new Date(), 1)),
  endDate: DateTimeHelpers.dateToIsoDate(new Date()),
  cloudCoveragePercentage: 50,
  platform: 'sentinel-2-l1c',
};

export const INITIAL_ESA_PANEL_STATE: EsaPanelState = {
  filters: defaultEsaFilters,
  loading: false,
  imageLoading: null,
  esaError: false,
  esaErrorMessage: null,
  esaPage: 1,
  esaData: null,
  processApiAuthToken: {
    token: null,
    timestamp: null,
  },
  esaVisibleImageLayerIds: [],
  itemsVisibility: {},
  itemsSelected: {},
  acquiredImages: {},
};

const EsaPanelSlice = createSlice({
  name: 'esaPanel',
  initialState: INITIAL_ESA_PANEL_STATE,
  reducers: {
    setEsaDates: (state, action: PayloadAction<DateFilters>) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
    }),
    setCloudCoveragePercentage: (state, action: PayloadAction<number>) => ({
      ...state,
      filters: {
        ...state.filters,
        cloudCoveragePercentage: action.payload,
      },
    }),
    setPlatform: (state, action: PayloadAction<string>) => ({
      ...state,
      filters: {
        ...state.filters,
        platform: action.payload,
      },
    }),
    setSearchArea: (state, action: PayloadAction<BBox | null>) => ({
      ...state,
      filters: {
        ...state.filters,
        searchArea: action.payload,
      },
      esaPage: 1,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setImageLoading: (state, action: PayloadAction<string | null>) => {
      state.imageLoading = action.payload;
    },
    setEsaError: (state, action: PayloadAction<boolean>) => {
      state.esaError = action.payload;
    },
    setEsaErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.esaErrorMessage = action.payload;
    },
    setEsaPage: (state, action: PayloadAction<number>) => {
      state.esaPage = action.payload;
    },
    setEsaData: (state, action: PayloadAction<ESAItemData[] | null>) => {
      state.esaData = action.payload;
    },
    setProcessApiAuthToken: (
      state,
      action: PayloadAction<ProcessApiAuthToken>
    ) => {
      state.processApiAuthToken = action.payload;
    },
    setEsaVisibleImageLayerIds: (state, action: PayloadAction<string>) => {
      const newLayerId = action.payload;

      const similarLayerIndex = state.esaVisibleImageLayerIds.findIndex(
        (layerId) => layerId.slice(0, -2) === newLayerId.slice(0, -2)
      );
      if (similarLayerIndex !== -1) {
        state.esaVisibleImageLayerIds[similarLayerIndex] = newLayerId;
      } else {
        state.esaVisibleImageLayerIds.push(newLayerId);
      }
    },
    removeEsaVisibleImageLayerId: (state, action: PayloadAction<string>) => {
      const newLayerId = action.payload;

      const similarLayerIndex = state.esaVisibleImageLayerIds.findIndex(
        (layerId) => layerId === newLayerId
      );
      if (similarLayerIndex !== -1) {
        state.esaVisibleImageLayerIds.splice(similarLayerIndex, 1);
      }
    },
    resetEsaImageLayerIds: (state) => {
      state.esaVisibleImageLayerIds = [];
    },
    resetEsaFilters: (state) => {
      state.filters = defaultEsaFilters;
    },
    setItemsVisibility(
      state,
      action: PayloadAction<{
        itemId: string;
        imageVisibility: EsaVisibility;
      }>
    ) {
      const { itemId, imageVisibility } = action.payload;
      state.itemsVisibility[itemId] = imageVisibility;
    },
    resetItemsVisibility(state) {
      state.itemsVisibility = {};
    },
    setItemsSelected(
      state,
      action: PayloadAction<{
        itemId: string;
        selected: string;
      }>
    ) {
      const { itemId, selected } = action.payload;
      state.itemsSelected[itemId] = selected;
    },
    resetItemsSelected(state) {
      state.itemsSelected = {};
    },
    setAcquiredImages(
      state,
      action: PayloadAction<{
        itemId: string;
        processKey: string;
      }>
    ) {
      const { itemId, processKey } = action.payload;

      if (!state.acquiredImages[itemId]) {
        state.acquiredImages[itemId] = [];
      }
      state.acquiredImages[itemId].push(processKey);
    },
    resetAcquiredImages(state) {
      state.acquiredImages = {};
    },
  },
});

export const {
  setEsaDates,
  setCloudCoveragePercentage,
  setLoading,
  setImageLoading,
  setEsaError,
  setEsaErrorMessage,
  setEsaPage,
  setEsaData,
  setProcessApiAuthToken,
  setEsaVisibleImageLayerIds,
  setPlatform,
  setSearchArea,
  resetEsaFilters,
  resetEsaImageLayerIds,
  setItemsVisibility,
  resetItemsVisibility,
  setItemsSelected,
  resetItemsSelected,
  removeEsaVisibleImageLayerId,
  setAcquiredImages,
  resetAcquiredImages,
} = EsaPanelSlice.actions;

export default EsaPanelSlice.reducer;
