/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_WEB_SOCKETS_STATE = {
  logoutEventReceived: false,
};

const WebSocketsSlice = createSlice({
  name: 'webSocket',
  initialState: INITIAL_WEB_SOCKETS_STATE,
  reducers: {
    setLogoutEventReceived: (state) => {
      state.logoutEventReceived = true;
    },
  },
});

export const { setLogoutEventReceived } = WebSocketsSlice.actions;

export default WebSocketsSlice.reducer;
