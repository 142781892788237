import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import { daasVesselToMyFleetVessel } from '../../../api';
import {
  setSelectedVessels,
  toggleSelectedVessel,
} from '../../../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { Vessel } from '../../../models/vessels/vessel.model';
import { addHistoryItem } from '../../../nav-history.slice';
import { setSelectedAlertId } from '../../../state/alerts/alerts.slice';
import store from '../../../store';
import TenantId from '../../../tenant';
import clearSelectedVessel from './clear-selected-vessel';
import { updateMyFleetVesselLocationInStore } from './panel-vessel-onClick';
import { MyFleetVessel } from '../../../maritime-menu-options/my-fleet-panel/myFleetVessels.model';

function onVesselClick(
  clickEvent: MapMouseEvent | MapTouchEvent,
  feature: mapboxgl.MapboxGeoJSONFeature
) {
  const { alertingVessels } = store.getState().alertingVessels;
  const { filters } = store.getState().alertingPanel;
  const { myFleet } = store.getState().myFleet;
  const { fleetsVessels } = store.getState().fleetsVessels;
  const { nearbyVessels } = store.getState().nearbyVessels;
  const { historicVessels } = store.getState().historicVessels;
  const { secondaryMenuOpen } = store.getState().menu;
  const { idToken } = store.getState().user;

  // can't use useAccessControl because we're not in a react component
  const canMultiSelect = idToken?.tenantId === TenantId.GEOLLECT;

  const clickedVessel = feature.properties as Vessel;

  // Is the vessel alerting?
  const filteredAlertingVessels = alertingVessels?.filter(
    (vessel) => !filters.hiddenAlerts.includes(vessel.alertType)
  );
  const alerting = filteredAlertingVessels?.find(
    (vessel) => vessel.vessel_id === clickedVessel.vessel_id
  );

  // Load the vessel from myfleet and fleets
  const myFleetVessel = myFleet?.byId[clickedVessel.vessel_id];
  const fleetsVessel = fleetsVessels?.byId[clickedVessel.vessel_id];
  const derivedVessel = myFleetVessel || fleetsVessel;

  // TODO code commented to fix bug for NGG-587. Part of multiple vessel selection feature but requires more work.
  // store.dispatch(setSelectedDossier(EDossiers.VESSEL));
  // Part of SUS-254 commented out to disable zooming if clicked on within map.
  // if (GeoHelper.assertIsPointFeature(feature)) {
  //   MapHelpers.zoomToPoint(feature.geometry.coordinates);
  //   // const vessel = {
  //   //   latitude: feature.geometry.coordinates[1],
  //   //   longitude: feature.geometry.coordinates[0],
  //   //   ...feature.properties,
  //   // };
  //   // store.dispatch(setSelectedVessels([vessel as Vessel]));
  // }

  if (derivedVessel) {
    // update the vessel location in the myfleet store so that the vessel is moved on the map when selected
    // (otherwise there may be a gap between the myfleet icon and the vessel history icon)
    updateMyFleetVesselLocationInStore(derivedVessel);
    const selectedVesselIds =
      store.getState().vesselDossier.selectedVessels?.allIds;
    const set = new Set(selectedVesselIds || []);
    store.dispatch(setSelectedDossier(EDossiers.VESSEL));
    if (!secondaryMenuOpen) {
      store.dispatch(addHistoryItem({ type: 'myFleet' }));
    }
    if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
      store.dispatch(toggleSelectedVessel(derivedVessel as MyFleetVessel));
      if (set.has(derivedVessel.vessel_id)) {
        set.delete(derivedVessel.vessel_id);
      } else {
        set.add(derivedVessel.vessel_id);
      }
    } else {
      clearSelectedVessel();
      set.clear();
      set.add(derivedVessel.vessel_id);
      store.dispatch(setSelectedVessels([derivedVessel as MyFleetVessel]));
    }

    if (!alerting) {
      window.history.pushState(
        null,
        '',
        `/fleet?ids=${Array.from(set).join(',')}`
      );
    } else {
      store.dispatch(setSelectedAlertId(derivedVessel.vessel_id));

      window.history.pushState(
        null,
        '',
        `/notifications/${derivedVessel.vessel_id}`
      );
      // Keep the icon the same
    }
  } else if (nearbyVessels) {
    // This might be a nearby vessel
    const nearbyVesselDaas = nearbyVessels?.data?.[clickedVessel.vessel_id];

    if (nearbyVesselDaas) {
      const nearbyVessel = daasVesselToMyFleetVessel(nearbyVesselDaas.vessel!);

      const selectedVesselIds =
        store.getState().vesselDossier.selectedVessels?.allIds;
      const set = new Set(selectedVesselIds || []);
      store.dispatch(setSelectedDossier(EDossiers.VESSEL));
      if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
        store.dispatch(toggleSelectedVessel(nearbyVessel));
        if (set.has(nearbyVessel.vessel_id)) {
          set.delete(nearbyVessel.vessel_id);
        } else {
          set.add(nearbyVessel.vessel_id);
        }
      } else {
        clearSelectedVessel();
        set.clear();
        set.add(nearbyVessel.vessel_id);
        store.dispatch(setSelectedVessels([nearbyVessel]));
      }
    }
  } else if (historicVessels) {
    const historicVesselDaas = historicVessels?.data?.[clickedVessel.vessel_id];
    if (historicVesselDaas) {
      const historicVessel = daasVesselToMyFleetVessel(
        historicVesselDaas.vessel!
      );

      const selectedVesselIds =
        store.getState().vesselDossier.selectedVessels?.allIds;
      const set = new Set(selectedVesselIds || []);
      store.dispatch(setSelectedDossier(EDossiers.VESSEL));
      if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
        store.dispatch(toggleSelectedVessel(historicVessel));
        if (set.has(historicVessel.vessel_id)) {
          set.delete(historicVessel.vessel_id);
        } else {
          set.add(historicVessel.vessel_id);
        }
      } else {
        clearSelectedVessel();
        set.clear();
        set.add(historicVessel.vessel_id);
        store.dispatch(setSelectedVessels([historicVessel]));
      }
    }
  }
}

export default onVesselClick;
