import TenantId from '../tenant';
import geollectTheme from './geollect';
import riskIntelligenceTheme from './risk-intelligence';

const ThemeConfig = {
  [TenantId.GEOLLECT]: geollectTheme,
  [TenantId.RISK_INTELLIGENCE]: riskIntelligenceTheme,
};

export default ThemeConfig;
