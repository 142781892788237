const MapLayerIcon = {
  PLAIN_VESSEL: {
    HIGHLIGHTED: 'plain-vessel-highlighted',
    NORMAL: 'plain-vessel',
    ALERT: 'alert',
    BORDER: 'border',
    NEARBY: 'nearby',
  },
  RI_VESSEL: {
    HIGHLIGHTED: 'ri-vessel-highlighted',
    NORMAL: 'ri-vessel',
  },
  INCIDENT: {
    DEFAULT: 'incident-default-marker',
    SELECTED: 'incident-default-marker-alert',
  },
  PORT: {
    DEFAULT: 'port-icon',
    DEFAULT_RI: 'port-icon-ri',
    SELECTED: 'port-icon-selected',
    SELECTED_RI: 'port-icon-selected-ri',
    INLAND_RI: 'port-inland-ri',
    CRUISE_RI: 'port-cruise-ri',
    SELECTED_CRUISE_RI: 'port-cruise-selected-ri',
  },
  HISTORIC_POSITION: 'historic-position',
  RADIO_POSITION: 'radio-position',
  WEATHER: {
    PIN: 'weather-pin',
  },
  VESSEL_SEARCH_AREA: {
    NORMAL: 'vessel-search-area-normal',
    AMBER: 'vessel-search-area-amber',
    ALERT: 'vessel-search-area-alert',
  },
  GLOBAL_AIS: {
    CARGO: 'global-ais-vessel-cargo',
    CARGO_STATIC: 'global-ais-vessel-cargo-static',
    FISHING: 'global-ais-vessel-fishing',
    FISHING_STATIC: 'global-ais-vessel-fishing-static',
    HIGH_SPEED_CRAFT: 'global-ais-vessel-high-speed-craft',
    HIGH_SPEED_CRAFT_STATIC: 'global-ais-vessel-high-speed-craft-static',
    PASSENGER: 'global-ais-vessel-passenger',
    PASSENGER_STATIC: 'global-ais-vessel-passenger-static',
    PLEASURE_CRAFT: 'global-ais-vessel-pleasure-craft',
    PLEASURE_CRAFT_STATIC: 'global-ais-vessel-pleasure-craft-static',
    TANKER: 'global-ais-vessel-tanker',
    TANKER_STATIC: 'global-ais-vessel-tanker-static',
    TUGS_AND_SPECIAL_CRAFT: 'global-ais-vessel-tug',
    TUGS_AND_SPECIAL_CRAFT_STATIC: 'global-ais-vessel-tug-static',
    DEFAULT: 'global-ais-vessel-default',
    DEFAULT_STATIC: 'global-ais-vessel-default-static',
  },
};

export default MapLayerIcon;
