import defaultTheme from './default-theme';

const riskIntelligenceTheme = {
  ...defaultTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#002d5c',
      icon: '#454a4f',
      dark: '#49454f',
      light: '#dbeaff',
      border: '#cac4d0',
      hover: '#f0f3f5',
      contrastText: '#f0f3f5',
    },
    secondary: {
      icon: '#BDD7FF',
      main: '#5b6771',
      dark: '#19222a',
      light: '#ddecf8',
      border: '#79747e',
      contrastText: '#f0f3f5',
    },
    success: {
      main: '#002d5c',
    },
    error: {
      main: '#b3261e',
      dark: '#410e0b',
      light: '#5b6771',
    },
    background: {
      default: '#fafdff',
      paper: '#f3f5f9',
      menu: '#fafdff',
      menuBorder: '#cdc8d3',
      light: '#fff',
      button: '#516ba4',
    },
    text: {
      primary: '#1c1b1f',
      secondary: '#1a1c1e',
      light: '#fff',
    },
    button: {
      submit: '#516BA4',
    },
    white: '#fff',
    black: '#000',
  },
};

export default riskIntelligenceTheme;
