/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapellaItemData } from '../../models/capella/capella-item.model';
import { CapellaToken } from '../../models/capella/capella-model';

interface CapellaState {
  loading: boolean;
  error: boolean;
  accessToken: CapellaToken | null;
  capellaData: CapellaItemData[];
  capellaPage: number;
}
export const INITIAL_CAPELLA_STATE: CapellaState = {
  loading: false,
  error: false,
  capellaData: [],
  accessToken: null,
  capellaPage: 1,
};

const CapellaSlice = createSlice({
  name: 'capella',
  initialState: INITIAL_CAPELLA_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setCapellaData: (state, action: PayloadAction<CapellaItemData[]>) => {
      state.capellaData = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<CapellaToken | null>) => {
      state.accessToken = action.payload;
    },
    setCapellaPage: (state, action: PayloadAction<number>) => {
      state.capellaPage = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setCapellaData,
  setAccessToken,
  setCapellaPage,
} = CapellaSlice.actions;

export default CapellaSlice.reducer;
