/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { GeoniusVesselData } from '../../api/vessels';
import { VesselList } from '../../common-components/manage-vessel-lists/vessel-lists.model';
import { AvcsHolding } from '../../maritime-menu-options/avcs-panel/avcs.model';

export enum EAdminMenuItems {
  USERS = 'Users',
  VESSELS = 'Vessels',
  VESSEL_LISTS = 'Vessel_Lists',
  ALERT_CONFIG = 'Alert_Config',
  AVCS = 'AVCS',
  PERMISSIONS = 'Permissions',
}

export enum EUserMenuItems {
  USER = 'User',
  USER_ACCESS = 'User Access',
  // DOCUMENTS = 'Documents',
}

export enum FeatureNames {
  fleet_license = 'Fleet',
  ports_license = 'Ports',
  cruise_license = 'Cruise',
  sanctions = 'Sanctions',
  ais_history = 'AIS History',
  voyager_intel_tool = 'Voyager Intel Tool',
  avcs = 'AVCS Online',
}

export enum GeollectFeatureNames {
  avcs = 'AVCS Online',
}

export interface License {
  total: number | 'default';
  available: number | 'default';
}

export interface FeatureLicenses {
  seats: License;
  licenses?: Partial<Record<keyof typeof FeatureNames, License>>;
}

export enum PermissionToggleNames {
  adtech_data = 'AdTech Data',
  ais_on_off = 'AIS On/Off Notifications',
  ais_spoofing = 'AIS Spoofing',
  aoi_enter_exit = 'AOI Enter/Exit Notifications',
  avcs = 'AVCS',
  bathymetry = 'Bathymetry',
  boundaries = 'TTW, EEZ, JWC',
  discuss = 'Discuss',
  drawings = 'Drawings',
  esa_maps = 'ESA Sentinel-2',
  fleets = 'Fleets',
  global_ais = 'Global AIS',
  history_all_periods = 'History (all periods)',
  history_specific_period = 'History (specific period)',
  incidents_on_route = 'Incidents on Route Notifications',
  incidents = 'Proximity to Incident Notifications',
  measuring_tool = 'Measuring Tool',
  nearby_vessel = 'Nearby Vessel',
  opensea_maps = 'OpenSea Maps',
  port_enter_exit = 'Port Enter/Exit Notifications',
  position_validation = 'Position Validation',
  radio_frequency_data = 'Radio Frequency Data',
  range_rings = 'Range Rings',
  ri_areas = 'RI Areas',
  ri_incidents = 'RI Incidents',
  ri_ports = 'RI Ports',
  ri_threat_assessments = 'RI Threat Assessments',
  routes = 'Routes',
  sar_imagery = 'Capella Space SAR Imagery',
  search_by_area = 'Search by Area',
  shipping_lanes = 'Shipping Lanes',
  static_changes = 'Static Changes Notifications',
  sts = 'STS Notifications',
  subsea_infrastructure = 'Subsea Infrastructure',
  weather = 'Weather',
  wpi_ports = 'WPI Ports',
  sanctions_countries_data = 'Country Sanctions',
  ri_cities_countries_data = 'RI Cities & Countries Data',
}

export type PermissionToggle = {
  enabled: boolean;
};

export type PermissionToggles = Record<
  keyof typeof PermissionToggleNames,
  PermissionToggle
>;

export interface Company {
  company_id: string;
  company_name: string;
  tenant_id: string;
  permission_toggles: PermissionToggles | null;
  feature_licenses?: FeatureLicenses;
}
export interface User {
  sub: string;
  username: string;
  name: string;
  email: string;
  groups: string[];
  company_id: string;
  tenant_id: string;
}

export type FailedUser = {
  first_name: string;
  last_name: string;
  username: string;
};

interface AdminState {
  companiesLoading: boolean;
  companiesError: boolean;
  companies: Company[] | null;
  allVesselsLoading: boolean;
  allVesselsError: boolean;
  allVessels: GeoniusVesselData[] | null;
  usersLoading: boolean;
  usersError: boolean;
  users: Record<Company['company_id'], User[] | undefined>;
  selectedOption: EAdminMenuItems;
  selectedCompany: Company | null;
  selectedList: VesselList | null;
  selectedUser: User | null;
  avcsHoldings: Record<Company['company_id'], AvcsHolding[]>;
}

export const INITIAL_ADMIN_STATE: AdminState = {
  companiesLoading: false,
  companiesError: false,
  companies: null,
  allVesselsLoading: false,
  allVesselsError: false,
  allVessels: null,
  usersLoading: false,
  usersError: false,
  users: {},
  selectedOption: EAdminMenuItems.USERS,
  selectedCompany: null,
  selectedList: null,
  selectedUser: null,
  avcsHoldings: {},
};

const AdminSlice = createSlice({
  name: 'admin',
  initialState: INITIAL_ADMIN_STATE,
  reducers: {
    setCompaniesLoading: (state) => {
      state.companiesLoading = true;
    },
    setCompaniesError: (state) => {
      state.companiesError = true;
      state.companiesLoading = false;
    },
    setCompanies: (state, action) => {
      state.companiesLoading = false;
      state.companiesError = false;
      state.companies = action.payload;
    },
    setAllVesselsLoading: (state, action) => {
      state.allVesselsLoading = action.payload;
    },
    setAllVesselsError: (state) => {
      state.allVesselsError = true;
      state.allVesselsLoading = false;
    },
    setAllVessels: (state, action) => {
      state.allVesselsLoading = false;
      state.allVesselsError = false;
      state.allVessels = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedCompanySeats: (state, action) => {
      state.selectedCompany!.feature_licenses!.seats.total = action.payload;
    },
    setSelectedList: (state, action) => {
      state.selectedList = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setCompanyUsers: (
      state,
      action: PayloadAction<{ companyId: Company['company_id']; users: User[] }>
    ) => {
      const { companyId, users } = action.payload;
      state.users[companyId] = users;
    },
    setCompanyAvcsHoldings: (
      state,
      action: PayloadAction<{
        companyId: Company['company_id'];
        holdings: AvcsHolding[];
      }>
    ) => {
      const { companyId, holdings } = action.payload;
      state.avcsHoldings[companyId] = holdings;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setCompaniesLoading,
  setCompaniesError,
  setCompanies,
  setAllVesselsLoading,
  setAllVesselsError,
  setAllVessels,
  setSelectedOption,
  setSelectedCompany,
  setSelectedList,
  setSelectedUser,
  setCompanyUsers,
  setCompanyAvcsHoldings,
  setSelectedCompanySeats,
} = AdminSlice.actions;

export default AdminSlice.reducer;
