import { wrapRequest } from './base';

interface DaasAuthToken {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const getDaasAuthToken = async (): Promise<DaasAuthToken> =>
  wrapRequest('get', 'geonius', '/daas-auth', {});

export default getDaasAuthToken;
