/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { VesselHistoryData } from '../../api/vessels';
import { HistoricVesselPoint } from '../../maritime-menu-options/history-panel/historic-vessel-point.model';
import { VesselData } from '../../models/vessels/maritime-ais-api';
import { Vessel } from '../../models/vessels/vessel.model';

export interface MergedHistoricVesselPoint
  extends Pick<Vessel, 'mmsi' | 'imo'> {
  unique_vessel_identifier: string;
  features: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[];
  startTimestamp: number;
  endTimestamp: number;
  index: number;
}

type IHistoryState = {
  loading: boolean | string;
  error: boolean;
  disabled: boolean;
  selectedAISDataGapFeature: GeoJSON.Feature | null;
  selectedMergedPointIndex: number | null;
  mergedHistoricVesselPoints: MergedHistoricVesselPoint[];
  historicVesselPoints: HistoricVesselPoint[];
  vesselHistoryData: VesselHistoryData | null;
  vesselData: VesselData | null;
};

export const INITIAL_HISTORY_STATE: IHistoryState = {
  loading: false,
  error: false,
  disabled: false,
  selectedAISDataGapFeature: null,
  selectedMergedPointIndex: null,
  mergedHistoricVesselPoints: [],
  historicVesselPoints: [],
  vesselHistoryData: null,
  vesselData: null,
};

const HistorySlice = createSlice({
  name: 'history',
  initialState: INITIAL_HISTORY_STATE,
  reducers: {
    setSelectedAISDataGapFeature: (
      state,
      action: PayloadAction<GeoJSON.Feature | null>
    ) => {
      state.selectedAISDataGapFeature = action.payload;
    },
    setHistoricVesselPoints: (
      state,
      action: PayloadAction<HistoricVesselPoint[]>
    ) => {
      state.historicVesselPoints = action.payload;
    },
    prependHistoricVesselPoints: (
      state,
      action: PayloadAction<HistoricVesselPoint[]>
    ) => {
      state.historicVesselPoints = [
        ...action.payload,
        ...state.historicVesselPoints,
      ];
    },
    setMergedHistoricVesselPoints: (
      state,
      action: PayloadAction<MergedHistoricVesselPoint[]>
    ) => {
      state.mergedHistoricVesselPoints = action.payload;
    },
    setSelectedMergedPointIndex: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.selectedMergedPointIndex = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean | string>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
      // if error is set to true, loading should be set to false
      if (action.payload) {
        state.loading = false;
      }
    },
    setVesselHistoryData: (
      state,
      action: PayloadAction<VesselHistoryData | null>
    ) => {
      state.vesselHistoryData = action.payload;
    },
    // this is a bit of a pain due to the data segment being
    // an array of vessels, which then contain messages.
    // redux requires immutability so we can't just array.push
    prependVesselHistoryData: (
      state,
      action: PayloadAction<VesselHistoryData>
    ) => {
      if (!state.vesselHistoryData) {
        state.vesselHistoryData = action.payload;
        return;
      }
      const newState: VesselHistoryData = {
        ...state.vesselHistoryData,
        data: [],
      };
      state.vesselHistoryData.data.forEach((vesselData) => {
        const newVesselData = action.payload.data.find(
          (newData) => newData.vessel.vessel_id === vesselData.vessel.vessel_id
        );
        // the playload contains messages for this vessel
        if (newVesselData) {
          newState.data.push({
            ...vesselData,
            messages: [...newVesselData.messages, ...vesselData.messages],
          });
        } else {
          // otherwise leave it unchanged
          newState.data.push(vesselData);
        }
      });

      state.vesselHistoryData = newState;
    },
    clearVesselHistoryData: (state) => {
      state.selectedAISDataGapFeature = null;
      state.selectedMergedPointIndex = null;
      state.vesselHistoryData = null;
      state.mergedHistoricVesselPoints = [];
      state.historicVesselPoints = [];
    },
    setVesselData: (state, action: PayloadAction<VesselData | null>) => {
      state.vesselData = action.payload;
    },
  },
});

export const {
  setSelectedAISDataGapFeature,
  setSelectedMergedPointIndex,
  setHistoricVesselPoints,
  prependHistoricVesselPoints,
  setMergedHistoricVesselPoints,
  setLoading,
  setError,
  setVesselHistoryData,
  prependVesselHistoryData,
  clearVesselHistoryData,
  setVesselData,
} = HistorySlice.actions;

export default HistorySlice.reducer;
