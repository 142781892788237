import { isEqual } from 'lodash';
import {
  City,
  ExpandedCity,
} from '../../models/risk-intelligence/risk_intelligence.model';
import { wrapRequest } from '../base';

export const getCities = async (): Promise<City[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/cities');

export const getCity = async (
  cityId: string | number
): Promise<ExpandedCity> => {
  const result = await wrapRequest(
    'get',
    'geonius',
    `/risk-intelligence/cities/${cityId}`
  );
  if (isEqual(result, { city_information: {} })) {
    throw Error('City not found');
  }
  return result;
};
