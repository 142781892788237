import { UserPreferences } from '../../../user-settings/user-preferences/user-preferences.slice';
import { VESSEL_COLOUR_NEARBY } from '../../../utils/vessel-constants.utils';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addAnyVesselLayer from './add-any-vessel-layer';

function addNearbyVesselLayerToMap(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata: object = {},
  userPreferences?: UserPreferences
) {
  addAnyVesselLayer(
    layer,
    metadata,
    visibility,
    MapLayerIcon.PLAIN_VESSEL.NEARBY,
    VESSEL_COLOUR_NEARBY,
    userPreferences
  );
}

export default addNearbyVesselLayerToMap;
