/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import update, { Spec } from 'immutability-helper';
import { GenericHistoryItem } from './models/history/history.model';

interface HistoryState {
  history: GenericHistoryItem[];
}

const INITIAL_HISTORY: HistoryState = {
  history: [],
};

const NavHistorySlice = createSlice({
  name: 'history',
  initialState: INITIAL_HISTORY,
  reducers: {
    addHistoryItem: (state, action: PayloadAction<GenericHistoryItem>) => {
      const updateOptions: Spec<typeof state.history> = {
        $push: [action.payload],
      };
      state.history = update(state.history, updateOptions);
    },
    popLastHistoryItem: (state) => {
      const len = state.history.length;
      if (len === 0) {
        // Nothing to pop
        return;
      }
      state.history = update(state.history, { $splice: [[len - 1, 1]] });
    },
    // user could click breadcrumb 2 or three up
    popLastNHistoryItems: (state, action: PayloadAction<number>) => {
      const numberToRemove = action.payload;
      const len = state.history.length;
      if (len === 0) {
        // Nothing to pop
        return;
      }
      if (numberToRemove >= len) {
        // trying to pop more items than exist. return []
        state.history = [];
        return;
      }
      state.history = update(state.history, {
        $splice: [[len - numberToRemove, numberToRemove]],
      });
    },
    removeAfterIndex: (state, action: PayloadAction<number>) => {
      const lastIndexToKeep = action.payload;
      const len = state.history.length;
      if (len === 0) {
        // Nothing to pop
        return;
      }
      if (lastIndexToKeep >= len - 1) {
        // trying to trim after an index which is too high.
        return;
      }
      state.history = update(state.history, {
        $splice: [[lastIndexToKeep + 1, len - lastIndexToKeep + 1]],
      });
    },
    startNewHistory: (state, action: PayloadAction<GenericHistoryItem>) => {
      state.history = [action.payload];
    },
    emptyHistory: (state) => {
      state.history = [];
    },
  },
});

export const {
  addHistoryItem,
  popLastHistoryItem,
  popLastNHistoryItems,
  removeAfterIndex,
  startNewHistory,
  emptyHistory,
} = NavHistorySlice.actions;

export default NavHistorySlice.reducer;
