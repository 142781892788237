import { Vessel } from '../../models/vessels/vessel.model';

export interface MyFleetVessel extends Vessel {
  name: string;
  flag?: string;
  timestamp: string;
  // vessel_id: string;
}

export function isFleetVessel(
  vessel: Vessel | MyFleetVessel
): vessel is MyFleetVessel {
  return (vessel as MyFleetVessel).vessel_id !== undefined;
}
