/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { BBox, CleanWFSFeature } from '../../models/geoserver/geoserver.model';

type GlobalAisState = {
  loading: boolean;
  rawData: CleanWFSFeature[];
  filteredData: CleanWFSFeature[];
  hideVesselsByType: string[];
  hideFlags: string[];
  drawLimitOverride: boolean;
  daasToken: string | null;
  daasTokenExpiry: number | null;
  error: boolean;
  lastBbox: BBox;
};

export const INITIAL_GLOBAL_AIS_STATE: GlobalAisState = {
  loading: false,
  rawData: [],
  filteredData: [],
  hideVesselsByType: [],
  hideFlags: [],
  drawLimitOverride: false,
  daasToken: null,
  daasTokenExpiry: null,
  error: false,
  lastBbox: [] as unknown as BBox,
};

const GlobalAisSlice = createSlice({
  name: 'globalAis',
  initialState: INITIAL_GLOBAL_AIS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingOff: (state) => {
      state.loading = false;
    },
    setRawData: (state, action) => {
      const uniqueDataMap = new Map();
      action.payload.forEach((subArray: CleanWFSFeature[]) => {
        subArray.forEach((item) => {
          if (!uniqueDataMap.has(item.id)) {
            uniqueDataMap.set(item.id, item);
          }
        });
      });
      state.rawData = Array.from(uniqueDataMap.values());
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setHideVesselByType: (state, action) => {
      const hideVesselsByType: string[] = action.payload;
      hideVesselsByType.forEach((hideVesselByType) => {
        if (!state.hideVesselsByType.includes(hideVesselByType)) {
          state.hideVesselsByType.push(hideVesselByType);
        } else {
          state.hideVesselsByType = state.hideVesselsByType.filter(
            (hideVesselByType_) => hideVesselByType_ !== hideVesselByType
          );
        }
      });
    },
    clearHideVesselsByType: (state) => {
      state.hideVesselsByType = [];
    },
    setHideFlags: (state, action) => {
      const flags: string[] = action.payload;
      flags.forEach((flag) => {
        if (!state.hideFlags.includes(flag)) {
          state.hideFlags.push(flag);
        } else {
          state.hideFlags = state.hideFlags.filter((flag_) => flag_ !== flag);
        }
      });
    },
    clearHideFlags: (state) => {
      state.hideFlags = [];
    },
    setDrawLimitOverride: (state, action) => {
      state.drawLimitOverride = action.payload;
    },
    setDaasToken: (state, action) => {
      state.daasToken = action.payload;
    },
    setDaasTokenExpiry: (state, action) => {
      state.daasTokenExpiry = action.payload;
    },
    setError(state) {
      state.error = true;
    },
    clearError(state) {
      state.error = false;
    },
    setBBox(state, action) {
      state.lastBbox = action.payload;
    },
  },
});

export const {
  setLoading,
  setLoadingOff,
  setRawData,
  setFilteredData,
  setHideVesselByType,
  clearHideVesselsByType,
  setHideFlags,
  clearHideFlags,
  setDrawLimitOverride,
  setDaasToken,
  setDaasTokenExpiry,
  setError,
  clearError,
  setBBox,
} = GlobalAisSlice.actions;

export default GlobalAisSlice.reducer;
