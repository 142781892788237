/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Boundary } from '../../models/boundaries/boundary.model';

type IBoundariesState = {
  boundaries: Boundary[] | null;
  loading: boolean;
  error: boolean;
  selectedBoundary: Boundary | null;
};

export const INITIAL_BOUNDARIES_STATE: IBoundariesState = {
  boundaries: null,
  loading: false,
  error: false,
  selectedBoundary: null,
};

const BoundariesPanelSlice = createSlice({
  name: 'boundariesPanel',
  initialState: INITIAL_BOUNDARIES_STATE,
  reducers: {
    setBoundaries: (state, action: PayloadAction<Boundary[] | null>) => {
      state.boundaries = action.payload;
      state.error = false;
      state.loading = false;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setSelectedBoundary: (state, action: PayloadAction<Boundary | null>) => {
      state.selectedBoundary = action.payload;
    },
  },
});

export const { setBoundaries, setLoading, setError, setSelectedBoundary } =
  BoundariesPanelSlice.actions;

export default BoundariesPanelSlice.reducer;
