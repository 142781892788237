import LoadingPanel from '../loading-panel/loading-panel';
import './loading-screen.scss';

function LoadingScreen() {
  return (
    <div data-testid="loading-screen" className="loading-screen">
      <LoadingPanel />
    </div>
  );
}

export default LoadingScreen;
