import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import { themedDraws } from '../../../map/map-controls.utils';
import addSimpleGeoJsonSource from '../../../map/map-layer-manager/layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../../../map/map-layer-manager/map-layer-icon';
import MapLayerManager from '../../../map/map-layer-manager/map-layer-manager.utils';
import MapLayerPaint from '../../../map/map-layer-manager/map-layer-paint';
import MapLayerVisibility from '../../../map/map-layer-manager/map-layer-visibility.enum';
import { MapGroupLayer } from '../../../map/map-layer-manager/map-layer.enum';
import addVesselsLayer from '../../../map/map-layer-manager/vessel-utils/add-vessels-layer';
import AISVesselsController from '../../../map/map-layer-manager/vessel-utils/ais-vessel-controller.utils';
import { getUniqueHistoricVessels } from '../../../map/map-layer-manager/vessel-utils/get-unique-historic-vessels';
import setVesselFeatures from '../../../map/map-layer-manager/vessel-utils/set-vessel-features';
import setVesselJourney from '../../../map/map-layer-manager/vessel-utils/set-vessel-journey';
import { setPaintProperty } from '../../../map/map.slice';
import MapHelpers from '../../../map/map.utils';
import store from '../../../store';
import TenantId from '../../../tenant';
import { Tenant } from '../../../theme';
import { UserPreferences } from '../../../user-settings/user-preferences/user-preferences.slice';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { HistoricVesselPoint } from '../../history-panel/historic-vessel-point.model';
import AISMergedPointsController from '../../history-panel/history-ais-merged-points/history-ais-merged-points-controller.utils';
import VesselHistoryController from '../../history-panel/vessel-history-controller.utils';

namespace HistoricAreaSearchVesselController {
  export const getHistoricVesselsSnapshot = (points: HistoricVesselPoint[]) => {
    const uniqueVessels = getUniqueHistoricVessels(points);
    const historicVessels: HistoricVesselPoint[] = uniqueVessels
      .map((vessel) =>
        // find last point in vessel journey
        points.reverse().find((point) => point.vessel_id === vessel.vessel_id)
      )
      .filter((item): item is HistoricVesselPoint => !!item);

    return historicVessels;
  };

  export const uniqueHistoryVesselIds: string[] = [];

  export const layerList = {
    JOURNEYS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_JOURNEYS}`;
        const { idToken } = store.getState().user;

        uniqueHistoryVesselIds.push(vesselId);

        if (!MapHelpers.getLayer(layerId)) {
          addSimpleGeoJsonSource(layerId);
          MapHelpers.addLayer({
            id: layerId,
            type: 'line',
            source: layerId,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
            },
            paint: MapLayerPaint.VESSEL_HISTORY_JOURNEY(
              uniqueHistoryVesselIds.indexOf(vesselId),
              idToken?.tenantId
            ),
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.HISTORIC_AREA_SEARCH,
              MapGroupLayer.HISTORIC_AREA_SEARCH_JOURNEYS,
            ]),
          });
        }

        setVesselJourney(data, layerId);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_JOURNEYS}`,
      groupLayer: MapGroupLayer.HISTORIC_AREA_SEARCH_JOURNEYS,
    },
    AIS_POSITIONS: {
      addLayer: (vesselId: string, data: HistoricVesselPoint[]) => {
        const layerId = `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_AIS_POSITIONS}`;

        const onClick = (e: MapTouchEvent | MapMouseEvent) => {
          const { idToken } = store.getState().user;
          // If no idToken, safer to proceed
          if (idToken) {
            const draw =
              themedDraws[(idToken.tenantId as Tenant) || TenantId.GEOLLECT];
            if (draw.getMode() !== 'simple_select') return;
          }
          AISVesselsController.layerEvents.onClick(e);
        };

        if (!MapHelpers.getLayer(layerId)) {
          addSimpleGeoJsonSource(layerId);
          MapHelpers.addLayer({
            id: layerId,
            type: 'symbol',
            source: layerId,
            minzoom: 7,
            layout: {
              visibility: MapLayerVisibility.VISIBLE,
              'icon-image': MapLayerIcon.HISTORIC_POSITION,
              'icon-rotation-alignment': 'map',
              'icon-rotate': ['number', ['get', 'course'], 360],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                // zoom is 5 (or less) -> icon will be half size
                5,
                0.5,
                // zoom is 10 (or greater) -> icon will be full size
                10,
                1,
              ],
            },
            metadata: MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.HISTORIC_AREA_SEARCH,
              MapGroupLayer.HISTORIC_AREA_SEARCH_AIS_POSITIONS,
            ]),
          });
        }

        setVesselFeatures(layerId, data);
        MapHelpers.onLayerVisibilityChange(layerId, () => {
          AISMergedPointsController.onAISPositionsLayerVisibilityChange(
            layerId
          );
        });

        MapHelpers.addMapEventListener(
          'mouseenter',
          layerId,
          AISVesselsController.layerEvents.onMouseEnter
        );

        MapHelpers.addMapEventListener(
          'mouseleave',
          layerId,
          AISVesselsController.layerEvents.onMouseLeave
        );

        MapHelpers.addMapEventListener('click', layerId, onClick);
        // prevent touch-then-drag being interpreted as a click
        // if start point is significantly different from end point, or 250ms elapses, then it's a drag
        let isClick = false;
        let point: { x: number; y: number } | null = null;

        MapHelpers.addMapEventListener('touchstart', layerId, (e) => {
          isClick = true;
          point = e.point;
          setTimeout(() => {
            isClick = false;
            point = null;
          }, 250);
        });

        MapHelpers.addMapEventListener('touchend', layerId, (e) => {
          if (isClick && GeoHelper.pointsNear(point, e.point)) onClick(e);
        });
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_AIS_POSITIONS}`,
      groupLayer: MapGroupLayer.HISTORIC_AREA_SEARCH_AIS_POSITIONS,
    },
    VESSELS: {
      addLayer: (
        vesselId: string,
        data: HistoricVesselPoint[],
        userPreferences: UserPreferences
      ) => {
        const layerId = `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_VESSELS}`;

        if (!MapHelpers.getLayer(layerId)) {
          addVesselsLayer(
            layerId,
            MapLayerVisibility.VISIBLE,
            MapLayerManager.groupLayerMetaData([
              vesselId,
              MapGroupLayer.HISTORIC_AREA_SEARCH,
              MapGroupLayer.HISTORIC_AREA_SEARCH_VESSELS,
            ]),
            userPreferences
          );
        }
        store.dispatch(
          setPaintProperty({
            layerId,
            name: 'icon-opacity',
            value: 0.5,
          })
        );

        const snapshot =
          VesselHistoryController.getHistoricVesselsSnapshot(data);

        setVesselFeatures(layerId, snapshot);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapGroupLayer.HISTORIC_AREA_SEARCH_VESSELS}`,
      groupLayer: MapGroupLayer.HISTORIC_AREA_SEARCH_VESSELS,
    },
  };

  export const uniqueHistoryAreaSearchVesselIds: string[] = [];

  // list of layers that need to be drawn and displayed dynamically for Vessel History
  export const getLayerListSources = (
    historicVesselPoints: HistoricVesselPoint[]
  ) => {
    const uniqueVessels = getUniqueHistoricVessels(historicVesselPoints);

    return {
      AIS_POSITIONS: uniqueVessels.map((vessel) =>
        layerList.AIS_POSITIONS.getLayerKey(vessel.vessel_id)
      ),
      JOURNEYS: uniqueVessels.map((vessel) =>
        layerList.JOURNEYS.getLayerKey(vessel.vessel_id)
      ),
      VESSELS: uniqueVessels.map((vessel) =>
        layerList.VESSELS.getLayerKey(vessel.vessel_id)
      ),
    };
  };

  export const clearAllHistoricAreaSearchLayers = () => {
    try {
      // delete all dynamic layers by their group layer id.
      Object.values(layerList).forEach((item) => {
        const existingLayers = MapLayerManager.findLayersByGroupLayerId(
          item.groupLayer
        );
        existingLayers.forEach((layer) => {
          MapLayerManager.destroyLayer(layer.id);
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-useless-return
      return;
    }
  };
}

export default HistoricAreaSearchVesselController;
