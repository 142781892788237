import './error-page.scss';

function ErrorPage() {
  return (
    <div>
      <h1 color="black">Oops!</h1>
      <p color="black">
        Sorry, an unexpected error has occurred. This page could not be found!
      </p>
    </div>
  );
}

export default ErrorPage;
