enum MapLayer {
  // ################# Ship Layers #################
  /** Layer for displaying vessels on the map */
  MY_FLEET_VESSELS = 'my-fleet-vessels',

  /** Layer for displaying vessel box outline on the map */
  VESSEL_FOCUS_RING = 'vessel-focus-ring',

  /** Layer for displaying AIS Journey Tracks */
  MY_FLEET_VESSELS_JOURNEYS = 'my-fleet-vessels-journeys',

  /** Layer for displaying AIS position points */
  MY_FLEET_VESSELS_AIS_POSITIONS = 'my-fleet-vessels-ais-positions',

  /** Layer for displaying selected AIS Positions */
  MY_FLEET_SELECTED_AIS_POSITION = 'my-fleet-selected-ais-position',

  /** Layer for showing global AIS vessels */
  GLOBAL_AIS_VESSELS = 'global-ais-vessels',

  // ################### Alerts/Notification Layers ###################
  /** Layer for displaying alerting vessels */
  CURRENTLY_ALERTING_VESSELS = 'currently-alerting-vessels',

  /** Layer for displaying alerting vessels notification regions */
  CURRENTLY_ALERTING_VESSELS_POLYGON = 'currently-alerting-vessels-polygon',
  CURRENTLY_ALERTING_ROUTES = 'currently-alerting-routes',
  CURRENTLY_ALERTING_ROUTES_RADIUS = 'currently-alerting-routes_radius',

  ALERT_INCIDENTS = 'alert_incidents',
  ALERT_INCIDENTS_CLUSTER = 'alert_incidents_cluster',
  ALERT_INCIDENTS_CLUSTER_COUNT = 'alert_incidents_cluster_count',

  /** Layer for displaying alerting port for Enter/Depart Port Alerts */
  ALERT_PORT = 'alert-port',
  ALERT_PORT_BUFFER = 'alert-port-buffer',

  // ################### Map Reference Layers ###################
  /** Open Sea Map Layer */
  OSM_SEA = 'open-sea-map',

  /** Bathymetry layer */
  BATHYMETRY = '10m-bathymetry-81bsvj',

  // ################### Boundaries Layers ###################
  BOUNDARIES_EEZ = 'eez_combined',
  BOUNDARIES_JWC = 'jwc_combined',
  BOUNDARIES_TTW = 'ttw_combined',

  // ################### Incidents Layers ###################
  INCIDENTS = 'incidents',
  INCIDENT_CLUSTERS = 'incident-clusters',
  INCIDENT_CLUSTER_COUNT = 'incident-cluster-count',

  // ################### Incidents Datum Rings Layer ###################
  DATUM_RINGS = 'datum-rings',

  // ################### Ports Layers ###################
  PORTS = 'ports',
  PORT_CLUSTERS = 'port-clusters',
  PORT_CLUSTER_COUNT = 'port-cluster-count',

  // ################### Regions Layers ###################
  RI_MARITIME_AREAS = 'ri-maritime-areas',

  // ################### Routes Layers ###################
  ROUTES_MARITIME_AREAS = 'routes-maritime-areas',

  ROUTES_INCIDENTS = 'routes-incidents',
  ROUTES_INCIDENT_CLUSTERS = 'routes-incident-clusters',
  ROUTES_INCIDENT_CLUSTER_COUNT = 'routes-incident-cluster-count',

  ROUTES_PORTS = 'routes-ports',
  ROUTES_PORT_CLUSTERS = 'routes-port-clusters',
  ROUTES_PORT_CLUSTER_COUNT = 'routes-port-cluster-count',

  // ################### Drawings Layers ###################
  DRAW_MEASUREMENT_TEXT = 'measurement-text',

  // ################### Tools Layers ###################
  NEARBY_VESSELS_AREA = 'nearby-vessels-area',
  NEARBY_VESSELS = 'nearby-vessels',
  HISTORIC_VESSELS_AREA = 'historic-vessels-area',
  HISTORIC_VESSELS = 'historic-vessels',

  // layer generated from mapbox style
  // layer used for displaying countries of the world
  LAND_STRUCTURE_POLYGON = 'land-structure-polygon',

  HISTORY = 'HISTORY',
  CAPELLA_SAR = 'capellaSar',

  MEASUREMENT_LINES = 'measurement-lines',
  MEASUREMENT_LINES_TEMP = 'measurement-lines-temp',
  MEASUREMENT_POINTS = 'measurement-points',
  MEASUREMENT_POINTS_TEMP = 'measurement-points-temp',

  WEATHER_PIN = 'weather-pin',
  WEATHER_VISIBILITY_RING = 'weather-visibility-ring',
  WEATHER_VISIBILITY_LABEL = 'weather-visibility-label',

  SHIPPING_LANE_OUTLINE = 'shipping-lane-outline',

  // ################### RF Layers ###################
  VESSEL_SEARCH_AREA_NORMAL = 'vessel-search-area-normal',
  VESSEL_SEARCH_AREA_AMBER = 'vessel-search-area-amber',
  VESSEL_SEARCH_AREA_RED = 'vessel-search-area-red',
  POSITION_VALIDATION = 'rf-data',
  // note: tile layers are created with this prefix, this is not a layer in and of itself
  SHIPPING_LANE_TILE = 'shipping-lane-tile',

  // ################### UKHO Layers ###################
  AVCS = 'avcs',

  // ################### Cable/Pipeline Layers ###################
  TELE_CABLES = 'cables-telecommunication',
  TELE_CABLES_META = 'cables-telecommunication-metadata',
  POWER_CABLES = 'cables-power',
  POWER_CABLES_META = 'cables-power-metadata',
  PIPELINES = 'pipelines',
  PIPELINES_META = 'pipelines-metadata',
  ESA_IMAGES = 'esa-images',
}

export enum MapGroupLayer {
  CAPELLA_SAR_IMAGES = 'capella-sar-images',
  BOUNDARIES_GROUP = 'boundaries_group_layer',
  DRAWINGS = 'drawings_group_layer',
  ROUTES = 'routes_group_layer',
  RI_MARITIME_AREAS = 'ri_maritime_areas_group_layer',
  HISTORY = 'history_group_layer',
  RF = 'rf_group_layer',
  ALERTS = 'alerts_group_layer',
  VESSEL_HISTORY_AIS_POSITIONS = 'vessel-history-ais-positions',
  VESSEL_HISTORY_SELECTED_AIS_POSITION = 'vessel-history-selected-ais-position',
  VESSEL_HISTORY_JOURNEYS = 'vessel-history-journeys',
  VESSEL_HISTORY_HEATMAPS = 'vessel-history-heatmaps',
  VESSEL_HISTORY_VESSELS = 'vessel-history-vessels',
  VESSEL_HISTORY_AIS_DATA_GAPS = 'vessel-history-ais-data-gaps',
  MYFLEET = 'my-fleet',
  RANGE_RINGS = 'range-rings',
  RANGE_RING_LABELS = 'range-ring-labels',
  RANGE_RING_POINTS = 'range-ring-points',
  UNSEENLABS_RF_DATA = 'unseenlabs-data',
  MEASUREMENTS = 'measurements',
  WEATHER = 'weather',
  RF_TARGETS = 'rf-targets',
  RF_TRACKS = 'rf-tracks',
  RF_REPORTED_POSITIONS = 'rf-reported-positions',
  RF_ESTIMATED_POSITIONS = 'rf-estimated-positions',
  ADTECH_DATA = 'adtech-data',
  ADTECH_ENTITY_DATA = 'adtech-entity-data',
  ADTECH_ENTITY_TRACKS_DATA = 'adtech-entity-tracks-data',
  SUBSEA_INFRASTRUCTURE = 'subsea-infrastructure',
  SHIPPING_LANES = 'shipping-lanes',
  AVCS = 'AVCS',
  HISTORIC_AREA_SEARCH = 'historic-area-search',
  HISTORIC_AREA_SEARCH_JOURNEYS = 'historic-area-search-journeys',
  HISTORIC_AREA_SEARCH_AIS_POSITIONS = 'historic-area-search-ais-positions',
  HISTORIC_AREA_SEARCH_VESSELS = 'historic-area-search-vessels',
}

export const IncidentsLayers = {
  INCIDENTS: [
    MapLayer.INCIDENTS,
    MapLayer.INCIDENT_CLUSTERS,
    MapLayer.INCIDENT_CLUSTER_COUNT,
  ],
};

export const RfLayers = [
  MapLayer.VESSEL_SEARCH_AREA_NORMAL,
  MapLayer.VESSEL_SEARCH_AREA_AMBER,
  MapLayer.VESSEL_SEARCH_AREA_RED,
  MapLayer.POSITION_VALIDATION,
];

export const RangeRingLayers = {
  LABEL: (layerId: string) => `${MapGroupLayer.RANGE_RING_LABELS}.${layerId}`,
};

export default MapLayer;
