import mapboxgl from 'mapbox-gl';
import {
  ColourScheme,
  Tenant,
  colourSchemes,
  getFromTheme,
} from '../../../theme';
import MapHelpers from '../../map.utils';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

function addPolygonLayer(
  layer: MapLayer | string,
  paint: mapboxgl.FillPaint = {},
  tenant: Tenant = 'GEOLLECT',
  groupLayerid?: string,
  drawingColour?: string,
  defaultOpacity: number = 0.3,
  drawingOpacity?: number
) {
  const colourScheme: ColourScheme | undefined = colourSchemes.get(
    getFromTheme(tenant, 'colourScheme')
  );

  return new Promise((resolve) => {
    if (groupLayerid) {
      MapLayerManager.AddLayerinGroups(
        {
          id: layer,
          type: 'fill',
          source: layer,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
          paint: {
            ...paint,
            'fill-color': drawingColour || colourScheme?.areaTint,
            'fill-opacity': drawingOpacity || defaultOpacity,
          },
        },
        [groupLayerid]
      );
    } else {
      MapHelpers.addLayer({
        id: layer,
        type: 'fill',
        source: layer,
        layout: {
          visibility: MapLayerVisibility.NOT_VISIBLE,
        },
        paint: {
          ...paint,
          'fill-color': drawingColour || colourScheme?.areaTint,
          'fill-opacity': drawingOpacity || defaultOpacity,
        },
      });
    }
    resolve(MapHelpers);
  });
}

export default addPolygonLayer;
