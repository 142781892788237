import { Vessel } from '../../models/vessels/vessel.model';

export interface AlertDataAdditionalDetails extends Record<string, any> {
  // geojson as string
  polygon?: string;
  radius?: number | string;
  // risk intelligence ids
  incidents?: number[];
}

export interface AlertingVessel extends Vessel {
  name: string;
  timestamp: string;
  positionDelta: string;
  alertType: string;
  alertTime: string;
  imo: string;
  additionalDetails?: string;
}

export interface AlertingVessels extends Vessel, ParsedAlertDataResponse {
  mmsi: string;
  vessel_id: string;
}

export enum ROIType {
  ROUTE = 'route',
  DRAWING = 'drawing',
  BOUNDARY = 'boundary',
  MARITIME_AREA = 'maritime_area',
}

export interface ParsedAlertDataResponse {
  alertType: string;
  alertTime: string;
  alertConfigId: string;
  expiryTime: string;
  name: string;
  type: string;
  additionalDetails: AlertDataAdditionalDetails;
  mmsi: string;
  roiType?: ROIType;
  roiId?: string;
  imo?: number | string;
}

export interface AlertDataResponse {
  additional_details: AlertDataAdditionalDetails;
  alert_config_id: string;
  alert_modifiers: {
    [key: string]: any;
  };
  alert_time: string;
  alert_type: string;
  expiry_time: string;
  imo: string;
  route_id: string | null;
  boundary_id: string | null;
  region_id: string | null;
  current_mmsi: string;
  vessel_name: string;
  vessel_type: string;
}

export interface STSAlertDetails {
  distance_between_vessel: string;
  event_duration_mins: number;
  event_start_time: string;
  nearest_neighbour_flag: string;
  nearest_neighbour_imo: string;
  nearest_neighbour_mmsi: string;
  nearest_neighbour_name: string;
  nearest_neighbour_ship_type: string;
  vessel_imo: number;
  vessel_mmsi: number;
  vessel_name: string;
  vessel_ship_type: string;
}

export interface STSAlert {
  additionalDetails: STSAlertDetails;
  alertConfigId: string;
  alertTime: string;
  alertType: string;
  course: number;
  expiryTime: string;
  heading: number;
  imo: string;
  latitude: number;
  longitude: number;
  mmsi: string;
  name: string;
  type: string;
  roiType: undefined;
  roiId: undefined;
  vessel_id: string;
  source: string;
}

export interface AISPositionData {
  accuracy: number;
  collection_type: string;
  course: number;
  created_at: string;
  flag: string;
  heading: number;
  latitude: number;
  longitude: number;
  maneuver: number;
  mmsi: number;
  msg_type: number;
  position: string;
  rot: number;
  source: string;
  speed: number;
  status: number;
  timestamp: string;
  unique_vessel_identifier: string;
}

export interface AISOnAlertDetails {
  after: AISPositionData;
  before: AISPositionData;
  vessel: {
    company_id: string;
    current_mmsi: string;
    imo: string;
    vessel_id: string;
    vessel_name: string;
    vessel_type: string;
  };
}

export interface AISOnAlert
  extends Omit<AlertingVessel, 'additionalDetails' | 'positionDelta'> {
  additionalDetails: AISOnAlertDetails;
  alertConfigId: string;
  course: number;
  expiryTime: string;
  heading: number;
  mmsi: string;
  speed: number;
  type: string;
  vessel_id: string;
}
