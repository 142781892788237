import { I18n } from '@aws-amplify/core';
import { withAuthenticator as withAuthenticatorAmplify } from '@aws-amplify/ui-react';
import {
  WithAuthenticatorOptions,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react/dist/types/components/Authenticator/withAuthenticator';
import React from 'react';

I18n.putVocabularies({
  en: {
    'Custom auth lambda trigger is not configured for the user pool.':
      'Password cannot be empty',
  },
});

const AWS_MOCK_COGNITO = process.env.REACT_APP_AWS_MOCK_COGNITO;

function withMockedAuthenticator<Props extends object>(
  Comp: React.ComponentType<Props>
) {
  return Comp;
}
function withAuthenticator<Props = {}>(
  Component: React.ComponentType<Props & WithAuthenticatorProps>,
  options?: WithAuthenticatorOptions
) {
  return AWS_MOCK_COGNITO
    ? withMockedAuthenticator(Component)
    : withAuthenticatorAmplify(Component, options);
}
export default withAuthenticator;
